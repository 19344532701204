<template>
  <b-row>
    <b-col cols="12">
      <b-form-group>
       
        <v-select v-if="categories != null" v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title" :options="categories" class="select-size-lg" single>
          <template #option="{ title }">
            <span> {{ title }}</span>
          </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import axios from '@axios'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },

  props: {
    initialCategories: null
  },


  methods: {
    updateSelections(a) {

      //console.log(a)
    },
    fetchCerts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/lms/admin/course/certificates')
          .then((response) => {
            var data = response.data;
            var responseData = JSON.parse(JSON.stringify(data));
           
            this.categories = responseData.data;

          }
          )
          .catch(error => reject(error))
      })
    },
  },
  watch: {
    selected: function (newVal, coldVal) {
      this.$emit("selectionChanged", newVal);
    }
  },
  created() {
    this.fetchCerts();
  
  },
  data() {
    return {
      categories: null,
      selected: this.initialCategories,
      option:[{ title: 'Square' }, { title: 'Rectangle' }, { title: 'Rombo' }, { title: 'Romboid' }],
    }
  }
}
</script>