<template>
  <div>
    <video-list-add-new
        :is-add-new-video-sidebar-active.sync="isAddNewVideoSidebarActive"
        @refetch-data="refetchData"
    />


    <b-card-code title="">
      <!-- input search -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center justify-content-end">
            <label class="mr-1">Search</label>
            <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block mr-1"
            />
            <b-button
                variant="primary"
                @click="isAddNewVideoSidebarActive = true"
            >
              <span class="text-nowrap">Add Video</span>
            </b-button>
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
          :columns="columns"
          :rows="rows"
          :line-numbers="true"
          :search-options="{
               enabled: true,
        externalQuery: searchTerm }"
          :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      >
        <template
            slot="table-row"
            slot-scope="props"
        >

          <!-- Column: Name -->
          <div
              v-if="props.column.field === 'fullName'"
              class="text-nowrap"
          >
            <b-avatar
                :src="props.row.avatar"
                class="mx-1"
            />
            <span class="text-nowrap">{{ props.row.fullName }}</span>
          </div>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'isActive'">
          <b-badge pill  :variant="`light-${resolveUserStatusVariant(props.row.isActive)}`">
            {{ props.row.isActive }}
          </b-badge>
        </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item :to="{name: 'video-edit', params:{ id: props.row.id }  }">
                <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                />
                <span>{{ $t("Edit") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteData(props.row.id)">
                <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

          <!-- Column: Common -->
          <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
              <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>


    </b-card-code>
  </div>
</template>
<script>

 
  import {
    VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
import VideoListAddNew from '@/views/hr/orientation/video/VideoListAddNew'
import store from '@/store'
import orientationStoreModule from '@/views/hr/orientation/orientationStoreModule'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { useToast } from 'vue-toastification/composition'
export default {
  name: 'videos-page',
  components: {
    BCardCode,
    VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar,
    VueGoodTable,
    VideoListAddNew
  },
  data(){
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Description',
          },
        },
        {
          label: 'Order',
          field: 'order',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Order',
          },
        },
        {
          label: 'File Name',
          field: 'fileName',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        // {
        //   label: 'Upload Date',
        //   field: 'uploadDate',
        //   type: 'date',
        //   dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
        //   dateOutputFormat: 'dd.MM.yyyy HH:mm:ss',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search Date',
        //   },
        // },
        {
          label: 'IsActive',
          field: 'isActive',
          filterOptions: {
            enabled: true,
            placeholder: 'Search IsActive?',
          },
        },
        {
          label: 'File Path',
          field: 'filePath',
          filterOptions: {
            enabled: true,
            placeholder: 'Search FilePath',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },

      ],
      searchTerm: '',
    }
  },
  // beforeMount() {
  //   this.$store.dispatch('fetchVideos')
  //       .then(response => {
  //         this.rows = response.data
  //       })
  //       .catch(reject => {
  //         this.rows = []
  //         toast({
  //           component: ToastificationContent,
  //           props: {
  //             title: 'Error fetching video list',
  //             icon: 'AlertTriangleIcon',
  //             variant: 'danger',
  //           },
  //         })
  //       })
  // },
  setup(){
    const VIDEO_APP_STORE_MODULE = 'hr-video'
    const toast = useToast()
    const rows = ref([])
    // Register module
    if (!store.hasModule(VIDEO_APP_STORE_MODULE)) store.registerModule(VIDEO_APP_STORE_MODULE, orientationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VIDEO_APP_STORE_MODULE)) store.unregisterModule(VIDEO_APP_STORE_MODULE)
    })
    onMounted(() =>{
      refetchData()
    })

    const isAddNewVideoSidebarActive = ref(false)



    const refetchData = function (){
      store.dispatch('fetchVideos')
          .then(response => {

            rows.value = response.data
          })
          .catch(reject=> {

            rows.value = []
          })
    };

    const deleteData = function(id){

      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('deleteVideo', {id})
              .then(response => {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  timer: 2000,
                  text: 'Your file has been deleted.',
                  // customClass: {
                  //   confirmButton: 'btn btn-success',
                  // },
                })
                refetchData()
              })
              .catch(error => {
                this.$swal({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'Video could not been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
              })

        }
      })


    }

    const resolveUserStatusVariant = status => {
      if (status === 'pending') return 'warning'
      if (status === true) return 'success'
      if (status === false) return 'danger'
      return 'primary'
    }

    return {
      isAddNewVideoSidebarActive,
      refetchData,
      deleteData,
      resolveUserStatusVariant,
      rows
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.flatpickr-calendar{
  overflow: hidden;
  display: none;
}
</style>
