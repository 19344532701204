<template>
  <b-card>
    <!-- form -->
    <b-row>
      <b-col cols="10">
        <b-form>
          <h2 class="text-primary mt-1">Leadership Survey</h2>

          <b-row>
            <!--/ birth date -->
            <b-col md="12">
              <div class="demo-inline-spacing">
                <h5>Select positions to be rated</h5>
                <position-picker class="col-12 " :multiple="Boolean(true)" :initialCategories="positionsSubject"
                  @selectionChanged="positionselectionChanged">
                </position-picker>
              </div>
            </b-col>
          </b-row>


          <b-row v-if="localOptions.rateSingleEmployee">
            <!--/ birth date -->
            <b-col md="12">
              <div class="demo-inline-spacing">
                <h5>Select Employee to Rate</h5>
                <member-picker class="col-12 " :initialCategories="rateSubject" @selectionChanged="selectionChanged">
                </member-picker>
              </div>
            </b-col>
          </b-row>



          <b-row>
            <!--/ birth date -->
            <b-col md="8">
              <div>
                <b-list-group>


                  <b-list-group-item v-for="member in rateallSubjects" :key="member.id"
                    class="d-flex justify-content-between align-items-center">
                    <div> <b-row> <b-col md="5"> <b-avatar :src="photo" size="64" badge="4"
                            badge-classes="badge-info"></b-avatar> </b-col>
                        <!--/ birth date -->
                        <b-col md="7"> {{ member.fullname }} <br /> <small> {{ member.platformpositionname }} </small>
                        </b-col> </b-row>
                      <!--/ birth date -->


                    </div> <b-button variant="flat-danger" @click="deleteMember(member)">

                      <feather-icon icon="Trash2Icon" />
                      <span class="align-middle ml-50">{{ $t("Delete") }}</span>
                    </b-button>

                  </b-list-group-item>


                </b-list-group>
              </div>
            </b-col>

            <b-col md="4">
              <div>
                <b-list-group>


                  <b-list-group-item class="d-flex justify-content-between align-items-center">
                    <div> <b-form-checkbox v-model="selected" value="C" class="custom-control-success">
                        <span> Rate by Higher Positions</span>
                      </b-form-checkbox>

                    </div>

                  </b-list-group-item>
                  <b-list-group-item class="d-flex justify-content-between align-items-center">
                    <div> <b-form-checkbox v-model="selected" value="C" class="custom-control-success">
                        <span> Rate by Lower Positions</span>
                      </b-form-checkbox>

                    </div>

                  </b-list-group-item>
                  <b-list-group-item class="d-flex justify-content-between align-items-center">
                    <div> <b-form-checkbox v-model="selected" value="C" class="custom-control-success">
                        <span> Rate by Same Positions</span>
                      </b-form-checkbox>

                    </div>

                  </b-list-group-item>

                </b-list-group>
              </div>

            </b-col>

          </b-row>






          <!--/ website -->

          <b-row>
            <!-- phone -->

            <b-col class="mt-3" cols="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1"
                @click.prevent="sendInformation">
                {{ $t("Save Changes") }}
              </b-button>

            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BButton, BAvatar, BPagination,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BFormRadio,
  BCardText,
  BTable, BProgress, BBadge, BFormSelect, BListGroup, BListGroupItem,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import axios from "@axios";
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MemberPicker from "@/views/members/component/MemberPicker.vue"
import BranchPicker from "@/views/branch/component/BranchPicker.vue"
import GroupPicker from "@/views/clientgroups/components/GroupSelector.vue"
import PositionPicker from "@/views/position/component/PositionPicker.vue"
import MemberMultiPicker from "@/views/members/component/MemberMultiPicker.vue"

// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    BCardText,
    BFormRadio,
    BFormCheckbox,
    MemberPicker,
    BranchPicker,
    GroupPicker,
    PositionPicker,
    MemberMultiPicker, BTable, BProgress, BBadge, VueGoodTable, BFormSelect, BAvatar, BListGroup, BListGroupItem,
    BPagination,

  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {},
    courseId: {
      type: Number,
      required: true,
    },
  },
  created() {

    this.localOptions = JSON.parse(this.informationData.additionalInfo);
    //console.log("Create settings");
    //console.log( this.localOptions);
    if (this.localOptions.rateSingleEmployee) {
      //console.log("rate object initalizing");
      this.rateSubject = this.localOptions.rateSubject;
      //console.log( this.rateSubject);
    }

    if (this.localOptions.rateallSubjects) {
      //console.log("rate all object initalizing");
      this.rateallSubjects = this.localOptions.rateallSubjects;
      //console.log( this.rateSubject);
    }

    if (this.localOptions.rateallSubjects) {
      //console.log("rate all object initalizing");
      this.positionsSubject = this.localOptions.positionsSubject;
      //console.log( this.rateSubject);
    }








  },
  data() {
    return {

      localOptions: {

        rateSingleEmployee: true,

        rateMultipleEmployeMode: ""

      },
      searchOptions: {
        key: null,
        keyValue: null

      },

      rateSubject: {},
      groupSubject: [],
      branchSubject: [],
      departmentSubject: [],
      rateallSubjects: [],
      positionsSubject: [],
      isPaid: "0",
      hasCertainStartDate: "0",
      hasCertainEndDate: "0",
      allowPublicAccess: "0",
      clevePhone: {
        numeral: true

      },
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'fullname',
        },

        {
          label: 'Position',
          field: 'platformpositionname',
        },

      ],
      rows: [],
      searchTerm: '',
    }
  },




  methods: {
    deleteMember(val) {

    },
    advanceSearch(val) {
      this.searchTerm = val
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
    selectionChanged(payload) {
      //console.log(payload);
      this.localOptions.rateSubject = payload;


    },
    multipleEmplooyesChanged(payload) {
      //console.log(payload);
      this.localOptions.rateallSubjects = payload;


    },

    positionselectionChanged(payload) {
      //console.log(payload);

      this.localOptions.positionsSubject = payload;


    },
    sendInformation() {
      //console.log(this.localOptions);
      //console.log("update course  is launching with id : " + this.courseId);

      this.isLoading = true;



      axios({
        method: "put",
        url: `/survey/admin/surveydetail/${this.courseId}`,
        data: {
          info: "updatesurveyconfiguration",
          file: null,
          content: this.localOptions,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData.data)).additionalInfo;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
