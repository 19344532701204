<template>
  <b-sidebar id="add-new-user-sidebar" :visible="isAddNewUserSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t('Create New Subject') }}</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(handleSingleMember)" @reset.prevent="resetForm">
          <b-row>
            <!--/ birth date -->
            <b-col md="12">
              <div class="demo-inline-spacing mb-1">
                <b-form-radio v-model="localOptions.rateSingleEmployee" name="some-radios" v-bind:value="true">
                  {{ $t('Single Employee') }}
                </b-form-radio>
                <b-form-radio v-model="localOptions.rateSingleEmployee" name="some-radios" v-bind:value="false">
                  {{ $t('Multiple Employees') }}
                </b-form-radio>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="!localOptions.rateSingleEmployee">
            <!--/ birth date -->
            <b-col md="12">
              <div class="demo-inline-spacing mb-1">
                <b-form-radio v-model="searchType" name="multiple-employees" value="MODE_GROUP">
                  {{ $t('Employee by Group') }}
                </b-form-radio>
                <b-form-radio v-model="searchType" name="multiple-employees" value="MODE_DEPARTMENT">
                  {{ $t('Employee by Department') }}
                </b-form-radio>
                <b-form-radio v-model="searchType" name="multiple-employees" value="MODE_POSITION">
                  {{ $t('Employee by Position') }}
                </b-form-radio>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="
            !localOptions.rateSingleEmployee && searchType == 'MODE_GROUP'">
            <b-col md="12">
              <div class="demo-inline-spacing">
                <h5>{{ $t('Select Group') }}</h5>
                <group-picker class="col-12" :initialCategories="groupSubject" @selectionChanged="valueChanged">
                </group-picker>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="
            !localOptions.rateSingleEmployee && searchType == 'MODE_BRANCH'">
            <b-col md="12">
              <div class="demo-inline-spacing">
                <h5>{{ $t('Select Branch') }}</h5>
                <branch-picker class="col-12" :initialCategories="branchSubject" @selectionChanged="valueChanged">
                </branch-picker>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="
            !localOptions.rateSingleEmployee &&
            searchType == 'MODE_DEPARTMENT'">
            <b-col md="12">
              <div class="demo-inline-spacing">
                <h5>{{ $t('Select Department') }}</h5>
                <department-picker class="col-12" :initialCategories="departmentSubject"
                  @selectionChanged="valueChanged">
                </department-picker>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="
            !localOptions.rateSingleEmployee && searchType == 'MODE_POSITION'">
            <b-col md="12">
              <div class="demo-inline-spacing">
                <h5>{{ $t('Select Position') }}</h5>
                <position-picker class="col-12" :multiple="Boolean(Boolean)" :initialCategories="positionSubject"
                  @selectionChanged="valueChanged">
                </position-picker>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="localOptions.rateSingleEmployee">
            <b-col class="mt-2" cols="12">
              <h5>{{ $t('Select Employee') }}</h5>
              <member-picker :searchOptions="searchOptions" @selectionChanged="selectionChanged">
              </member-picker>
            </b-col>
          </b-row>

          <b-row v-if="isMultiSelectEmployeeEnabled">
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="selectAll">
                {{ !isSelectedAll ? "Select All" : "Remove All" }}
              </b-button>
            </div>
            <b-row class="col-12">
              <div class="d-flex col-12 p-2">
                <b-list-group class="col-12">
                  <b-list-group-item v-for="member in members" :key="member.id"
                    class="d-flex justify-content-between align-items-center ">
                    <b-form-checkbox v-model="member.selected" plain class="vs-checkbox-con col-12"
                      @change="selectMember(member)">

                      <span>{{ member.fullname }}</span>
                    </b-form-checkbox>
                  </b-list-group-item>
                </b-list-group>

              </div>
            </b-row>
          </b-row>

          <b-row> </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="submit" class="mr-2" v-if="
              !loading &&
              rateallSubjects != null &&
              rateallSubjects.length > 0
            ">
              {{ $t("Add") }}
            </b-button>
            <b-spinner class="mr-1" v-if="loading" variant="warning" />

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              v-if="!loading" @click="hide">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BFormRadio,
  BCardText,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BTable,
  BBadge,
  BAvatar,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import router from "@/router";

import MemberPicker from "@/views/members/component/MemberPicker.vue";
import DepartmentPicker from "@/views/department/components/DepartmentPicker.vue";
import BranchPicker from "@/views/branch/component/BranchPicker.vue";
import GroupPicker from "@/views/clientgroups/components/GroupSelector.vue";
import PositionPicker from "@/views/position/component/PositionPicker.vue";
import MemberMultiPicker from "@/views/members/component/MemberMultiPicker.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BFormRadio,
    BCardText,
    BFormCheckbox,
    vSelect,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    MemberPicker,
    DepartmentPicker,
    BranchPicker,
    GroupPicker,
    PositionPicker,
    MemberMultiPicker,
    BListGroup,
    BListGroupItem,
    BTable,
    BBadge,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  watch: {
    searchType: function (newVal, coldVal) {
      this.members = []
      this.searchOptions = null
    }
  },
  created() {
    this.fetchMembers();
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    courseId: {
      type: String,
      required: true
    },
    courseName: {
      type: String,
      required: true
    },
  },
  computed: {
    isMultiSelectEmployeeEnabled: function () {
      return (
        (this.searchType != null) && (this.searchOptions != null) &&
        !this.localOptions.rateSingleEmployee
      );
    },
    isSingleEmployee: function () {
      return this.localOptions.rateSingleEmployee;
    },
    isSingleEmployeeCanAdd: function () {
      var t =
        this.localOptions.rateSingleEmployee &&
        this.rateallSubjects != null &&
        this.rateallSubjects.length > 0;
      //console.log("RATE All Subject => ", this.rateallSubjects.length);
      //console.log(this.rateallSubjects);

      //console.log(t);
      return t;
    },
    isMultipleEmployee: function () {
      return !this.localOptions.rateSingleEmployee;
    },
    showButtons: function () {
      return this.isSingleEmployeeCanAdd || this.isMultipleEmployee;
    },
  },
  data() {
    return {
      members: [],
      fields: ['show_details', { key: 'avatar', label: 'Avatar' }, 'fullname', 'email',],
      course: null,
      required,
      alphaNum,
      loading: false,
      email,
      isSelectedAll: false,
      countries,
      rateHigherPositions: false,
      rateLowerPositions: true,
      rateSamePositions: true,
      searchOptions: null,
      searchType: null,
      groupSubject: null,
      branchSubject: null,
      departmentSubject: null,
      positionSubject: null,
      rateallSubjects: [],
      localOptions: {
        rateSingleEmployee: true,
        searchOptions: null,
        isMember: true,
        surveyitemtitle: null,
        surveyitemphoto: null,
        surveyitemmail: null,
        surveyitemmobile: null,
      },
    };
  },
  methods: {
    resefForm() {

    },
    selectMember(member) {
      if (member.selected) {
        if (!this.rateallSubjects.some((item) => item.id === member.id)) {
          this.rateallSubjects.push(member);
        }
      } else {
        this.rateallSubjects = this.rateallSubjects.filter((item) => item.id !== member.id);
      }
      console.log(this.rateallSubjects)
    },

    async fetchMembers() {
      if (this.searchType == "MODE_GROUP") {
        await this.fetchGroupMembers(this.searchOptions[0].id)
      }
      else if (this.searchType == "MODE_DEPARTMENT") {
        console.log('Department ID=> ', this.searchOptions.departmentplatformid)
        await this.fetchGroupMembersByDepartment(this.searchOptions.departmentplatformid)
      }
      else if (this.searchType == "MODE_POSITION") {
        console.log('Position Name => ', this.searchOptions.title)
        await this.fetchGroupMembersByPosition(this.searchOptions.title)
      }

    },
    // addAllMembers() {
    //   {
    //     //console.log("add all triggered");
    //     //console.log("searchType", this.searchType);
    //     //console.log("search Options", this.searchOptions);
    //     this.loading = true;
    //     return;
    //     this.localOptions.groupid = router.currentRoute.query.gid;
    //     var updateData = {
    //       group: router.currentRoute.query.gid,
    //       searchType: this.searchType,
    //       searchOptions: this.searchOptions,
    //     };

    //     axios({
    //       method: "post",
    //       url: `/admin/f/cm/addAllMemberstoGroupByOption`,
    //       data: updateData,
    //     })
    //       .then((r) => {
    //         this.$toast({
    //           component: ToastificationContent,
    //           position: "top-right",
    //           props: {
    //             title: `Success`,
    //             icon: "CheckCircleIcon",
    //             variant: "success",
    //           },
    //         });

    //         this.loading = false;
    //         this.isUpdated = false;
    //         this.$emit("new-record-created");
    //         this.$emit("update:is-add-new-user-sidebar-active", false);
    //         this.$emit("refetch-data");
    //       })
    //       .catch((r) => {
    //         this.$toast({
    //           component: ToastificationContent,
    //           position: "top-right",
    //           props: {
    //             title: `Error`,
    //             icon: "AlertOctagonIcon",
    //             variant: "warning",
    //             text: r.message,
    //           },
    //         });
    //         this.loading = false;
    //       });
    //     return;
    //     store
    //       .dispatch("app-surveys/addSubject", this.localOptions)
    //       .then((response) => {
    //         var result = response.data.success;

    //         if (result != null && result != undefined && result == 100) {
    //           //console.log(response);

    //           this.$emit("new-record-created");
    //           this.$emit("update:is-add-new-user-sidebar-active", false);
    //           this.$emit("refetch-data");
    //         } else {
    //           //console.log(response);

    //           this.$toast({
    //             component: ToastificationContent,
    //             position: "top-right",
    //             props: {
    //               title: `Error`,
    //               text: response.data.message,
    //               icon: "AlertTriangleIcon",
    //               variant: "danger",
    //             },
    //           });
    //           this.loading = false;
    //         }
    //       })
    //       .catch(() => {
    //         this.loading = false;
    //       });
    //   }
    // },
    async fetchGroupMembers(id) {
      new Promise((resolve, reject) => {
        axios
          .get("/admin/f/cm/groupmembers/" + id)
          .then((response) => {
            this.members = response.data.users;
            this.members.forEach((member) => {
              member.selected = false;
            });
            resolve(response.data.users);
          })
          .catch((error) => reject(error));
      });
    },
    async fetchGroupMembersByDepartment(id) {
      new Promise((resolve, reject) => {
        axios
          .get("/admin/f/cm/getmembersbydepartment?id=" + id)
          .then((response) => {
            this.members = response.data.data;
            this.members.forEach((member) => {
              member.selected = false;
            });
            resolve(response.data.users);
          })
          .catch((error) => reject(error));
      });
    },
    async fetchGroupMembersByPosition(name) {
      new Promise((resolve, reject) => {
        axios
          .get("/admin/f/cm/getmembersbyposition?name=" + name)
          .then((response) => {
            this.members = response.data.data;
            this.members.forEach((member) => {
              member.selected = false;
            });
            resolve(response.data.users);
          })
          .catch((error) => reject(error));
      });
    },
    handleSingleMember() {
      {
        this.loading = true;
        this.localOptions.groupid = router.currentRoute.query.gid;
        var vMembers = [];

        for (let index = 0; index < this.rateallSubjects.length; index++) {
          const element = this.rateallSubjects[index];
          vMembers.push(element);
        }

        var updateData = {
          members: vMembers,
          groupId: [router.currentRoute.query.gid],
          courseGuid: this.courseId,
          courseName: this.courseName,
        };

        //console.log("sending : ", updateData);
            return axios({
              method: "post",
              url: `lms/admin/course/addparticipants`,
              data: updateData,
            }).then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });

            this.loading = false;
            this.isUpdated = false;
            this.$emit("new-record-created");
            this.$emit("update:is-add-new-user-sidebar-active", false);
            this.$emit("refetch-data");
          }).catch((r) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertOctagonIcon",
                variant: "warning",
                text: r.message,
              },
            });
            this.loading = false;
          });
        this.loading = false;

      }
    },
    // Select all or remove all 
    selectAll() {
      if (!this.isSelectedAll) {
        this.members.forEach((item, index, arr) => {
          item.selected = true;
          this.$set(this.members, this.members.indexOf(index), item);
        })
        this.rateallSubjects = this.members
        this.isSelectedAll = !this.isSelectedAll
      }
      else {
        this.members.forEach((item, index, arr) => {
          item.selected = false;
          this.$set(this.members, this.members.indexOf(index), item);
        })
        this.rateallSubjects = []
        this.isSelectedAll = !this.isSelectedAll
      }

    },
    // Change dropwond value for single employee
    selectionChanged(payload) {

      if (payload == null) {
        this.rateallSubjects = [];
      } else {
        if (this.localOptions.rateSingleEmployee) {
          this.rateallSubjects = [];
        }
        console.log('SelectionChanged', payload)
        this.rateallSubjects.push(payload);
      }
    },
    //it will run after Dropdown value changed 
    valueChanged(payload) {
      this.rateallSubjects = [];
      if (payload == null) {
        this.searchOptions = null;
      } else {
        this.searchOptions = payload;
      };
      this.fetchMembers();
    },

  },
  setup(props, { emit }) {
    return;
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
