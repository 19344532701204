<template>
  <b-card>
    <!-- form -->
    <b-row>
      <b-col cols="10">

        <h2 class="text-primary">{{ title }}</h2>
      </b-col>
      <b-col cols="2">
        <b-badge variant="warning" class="badge-glow" v-if="isUpdated">
          Not Saved
        </b-badge>
      </b-col>
    </b-row>
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-media no-body>
            <b-media-aside>
              <b-link>
                <b-img ref="previewEl" rounded :src="fileUrl" height="240" alt="" width="480" />
              </b-link>
              <!--/ avatar -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
                :state="Boolean(file)" v-model="file" @click="$refs.refInputEl.$el.click()">
                Upload
              </b-button>
              <b-form-file ref="refInputEl" v-model="profileFile" accept=".jpg, .png, .gif" :hidden="true" plain
                @input="fileSelected" />
              <!--/ upload button -->

              <!-- reset -->
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary" size="sm"
                class="mb-75 mr-75" @click="resetFile()">
                Reset
              </b-button>
              <!--/ reset -->
              <b-card-text>{{ $t("Allowed JPG, GIF or PNG") }}</b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <!-- bio -->
        <b-col cols="12">
          <b-form-group label="" label-for="bio-area">
            <quill-editor v-model="localOptions" height="300" :options="snowOption" :content="localOptions.courseGoals"
              @change="onEditorChange($event)" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)" />
          </b-form-group>
        </b-col>
        <!--/ bio -->

        <!-- phone -->

        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1"
            :disabled="isLoading ? true : false" @click.prevent="sendInformation">
            {{ $t("Save Changes") }}
          </b-button>

        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormFile, BFormInput, BRow, BCol, BCard, BFormTextarea, BBadge,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import { quillEditor } from 'vue-quill-editor'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";


// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { integer } from '@/@core/utils/validations/validations'

export default {
  components: {
    BCardText,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    quillEditor,
    BBadge
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: () => '',
    },
    id: {
      type: integer,
      required: true,
      default: () => '',
    },
    informationData: {
      type: String,
      required: false,
      default: () => '',

    },
    fieldInformation: {
      type: String,
      required: true,
      default: () => '',
    },
    image: {
      type: String,

      default: () => null,
    },
  },
  data() {
    return {
      countryOption: ['USA', 'India', 'Canada'],
      snowOption: {
        theme: 'snow',
      },
      isLoading: false,
      isUpdated: false,
      file: null,
      fileUrl: null,
      localOptions: JSON.parse(JSON.stringify(this.informationData ?? "")),
      profileFile: null,
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
    }
  },
  methods: {

    sendInformation() {

      //console.log("update course  is launching with id : "+this.id );

      this.isLoading = true;
      axios({
        method: 'put',
        url: `/survey/admin/surveydetail/${this.id}`,
        data: {
          info: this.fieldInformation,
          content: this.localOptions,
          fileUrl: this.fileUrl,
        }
      }).then((r) => {

        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
        this.isLoading = false;
        this.isUpdated = false;

      }

      )
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        }
        );
      return;


    },

    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData ?? ""))
    },
    onEditorBlur(quill) {
      //    //console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      //   //console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      //     //console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      //     //console.log('editor change!', quill, html, text)
      this.isUpdated = true;
      this.content = html
    },
    resetFile() {
      this.file = null;
      this.fileUrl = null;
      this.profileFile = null;
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },

    fileSelected(payload) {
      const formData = new FormData();
      formData.append("file", payload);
      formData.append("courseId", this.courseId);
      formData.append("acc", 3);
      formData.append("platform", "SURVEYWELCOME");
      this.isLoading = true;

      axios
        .post("/upload/admin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {

          this.fileUrl = response.data.url;

          this.isUpdated = true;
          this.isLoading = false;


        })
        .catch((error) => {
          //console.log(error);
          this.isLoading = false;
          this.$bvToast.toast(`${error.response.data.message}`, {
            title: "Error",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "danger",
            solid: true,
          });
        });
    },

  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
  computed: {
    editor() {
      //   return this.$refs.myQuillEditor.quill
    }
  },
  created() {
    this.fileUrl = this.image;
  },
  mounted() {

    //  var data= JSON.parse(JSON.stringify(this.informationData??""));

    //   //console.log(data);

    //   //console.log('this is current quill instance object', this.editor)
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

@import '@core/scss/vue/libs/quill.scss';

.ql-editor {
  min-height: 400px;
}
</style>