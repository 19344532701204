<template>
  <b-sidebar id="add-new-user-sidebar" :visible="isAddNewUserSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Department
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(submitForm)" @reset.prevent="resetForm">

          <!-- Full Name -->
          <validation-provider #default="validationContext" name="Department Name" rules="required">
            <b-form-group label="Department Name" label-for="full-name">
              <b-form-input id="full-name" v-model="currentCategory.name" autofocus
                :state="getValidationState(validationContext)" trim placeholder="Department Name" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>








          <!-- Username -->
          <validation-provider #default="validationContext" name="Description" rules="required|alpha-num">
            <b-form-group label="Description" label-for="username">
              <b-form-input id="username" v-model="currentCategory.description"
                :state="getValidationState(validationContext)" placeholder="Department description" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <b-card-text class="small text-muted">
            If you want to create a department as a sub department please choose a parent department.
            Otherwise leave empty.
          </b-card-text>

          <b-form-group label="Upper Department" label-for="department-id">
            <department-picker :initialCategories="currentCategory.Parent"
              @selectionChanged="onDepartmentSelectionChanged">
            </department-picker>

          </b-form-group>




          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              {{ currentCategory == null || currentCategory.id == null ? 'Add' : 'Update' }}
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" class="mr-2"
              v-if="currentCategory != null && currentCategory.id != null" variant="outline-danger" @click="deletecategory">
              Delete
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              {{ $t("Cancel") }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCardText
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import DepartmentPicker from '../components/DepartmentPicker.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BCardText,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    DepartmentPicker
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onDepartmentSelectionChanged(payload) {
      //console.log("Department selected");
      //console.log(payload);
      this.currentCategory.upperCategory = payload.id;
      this.currentCategory.Parent = payload;
    },
    selectionChanged(selectedGroup) {
      // //console.log(selectedGroup);

      if (selectedGroup == null || selectedGroup == undefined) {
        this.currentCategory.upperCategory = null;
      } else {
        this.currentCategory.upperCategory = selectedGroup.id
      }

    },
    deletecategory() {
      this.currentCategory.deleted = true;

      this.$store.dispatch('apps-departments/createDepartment', this.currentCategory)
        .then((result) => {
          //console.log(result);

          this.$emit('refetch-data')
          this.$emit('update:is-add-new-user-sidebar-active', false)
        });
    },

    submitForm() {

      //console.log(this.currentCategory);

      this.$store.dispatch('apps-departments/createDepartment', this.currentCategory)
        .then((result) => {
          //console.log(result);

          this.$emit('refetch-data')
          this.$emit('update:is-add-new-user-sidebar-active', false)
        });
    }
  },

  watch: {
    categorydata: {
      handler: function (n, o) {
        //console.log('new client: ', n);

        if (n == null || n == undefined) {
          this.currentCategory = {
            id: null,
            name: null,
            description: null,
            upperCategory: null,
            Parent: null
          }
        } else {
          //console.log('new client: ', n.selectedCategory);
          this.currentCategory = n.selectedCategory;
        }


      },
      deep: true
    }

  },

  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      currentCategory
    }
  },
  inject: ['categorydata'],
  setup(props, { emit }) {
    const blankUserData = {
      name: '',
      upperCategory: '',
      description: '',
      parent: null

    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('apps-departments/createDepartment', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
