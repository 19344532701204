<template>

    <div class="uploading-video">
        <b-row>
            <b-col cols="12">  
            <span :class="{
            'status-canceled': status == 'canceled'
        }"> {{ file.fileName }} </span>
        </b-col>
        <b-col cols="12"> <small v-if="status == 'success'">{{$t('Success')}}</small>
        <small v-else-if="status == 'retrying'">{{$t('Error')}}!</small>
        <small v-else-if="status == 'error'">{{errorMessage}}</small>
        <small v-else-if="status == 'canceled'">{{$t('Cancelled')}}</small>
        <small v-else> {{ $t('Uploading') }} {{ uploadedAmount }}%</small></b-col>
    </b-row>
<!--
        <span v-if="isUploading">
            <button @click="cancel()">cancel</button>
        </span>
        -->

    </div>
</template>

<script>

import { BRow, BCol } from "bootstrap-vue"

export default {
    components: {
        BRow, BCol,
    },
    props: [
        'errorMessage',
        'file',
        'status',
        'progress',
    ],
    data() {
        return {
            isPaused: false // we upload straight away by default
        }
    },
    computed: {
        isUploading() {
            return (this.status !== 'success' && this.status !== 'canceled')
        },
        uploadedAmount() {
            return (this.progress * 100).toFixed(2)
        },
    },
    methods: {
        upload() {
            this.file.resumableObj.upload()
            this.isPaused = false
        },
        pause() {
            this.file.pause()
            this.isPaused = true
        },
        resume() {
            this.pause() // not sure why, but we have to call pause again before upload will resume
            this.upload()
        },
        cancel() {
            this.$emit('cancel', this.file)
        }
    }
}
</script>

<style lang="scss">
.uploading-video {
    .status-canceled {
        text-decoration: line-through;
    }
}
</style>
