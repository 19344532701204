<template>

  <div>

    <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
      :plan-options="planOptions" @new-record-created="newRecordCreated" :key="currentId" @refetch-data="refetchData" />

    <h2>{{ surveytitle }}</h2>


    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Show') }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search...')" />
              <b-button v-if="surveyInfo != null && surveyInfo != undefined && surveyInfo.status == 0" variant="primary"
                @click="isAddNewUserSidebarActive = true">
                <span class="text-nowrap"> {{$t('Create New Subject')}}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refUserListTable" class="position-relative" :items="fetchCourses" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc">

                    <template #head()="data">
              <span class="text">{{ $t(data.label.toUpperCase()) }}</span>
            </template>
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.surveyitemphoto" :text="avatarText(data.item.surveyitemtitle)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`" />
            </template>

            {{ data.item.surveyitemtitle }}

            <small class="text-muted">{{
              data.item.additionalInfo ? JSON.parse(data.item.additionalInfo).user.platformpositionname:''
              }}</small>
          </b-media>
        </template>

        <template #cell(surveyStatus)="data">





          <b-badge pill :variant="`${resolveSurveyStatusVariant(data.item.surveyStatus)}`" class="text-capitalize">
            {{ resolveSurveyStatus(data.item.surveyStatus) }}

          </b-badge>
        </template>


        <template #cell(attendees)="data">


          <b-link
            :to="{ name: 'SurveyAttendees', params: { suritemid: data.item.id }, query: { s: data.item.id, survey: data.item.SurveyId, t: data.item.surveyitemtitle } }"
            class="font-weight-bold d-block text-nowrap" v-if="!Boolean(data.item.deleted)">
            <feather-icon :icon="resolveUserRoleIcon(data.item.role)" size="18" class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`" /> {{ data.item.total_pos }} Attendees <small
              class="text-muted">
              {{ data.item.higher_pos }} HIGHER | {{ data.item.lower_pos }} LOWER | {{ data.item.same_pos }} SAME ||
              {{ data.item.selected_pos }} SELECTION
            </small>
          </b-link>

          <b-badge variant="light-secondary" v-if="Boolean(data.item.deleted)">


            Deleted
          </b-badge>

        </template>

        <template #cell(id)="data">

          <b-link
            :to="{ name: 'SurveyQuestions', params: { id: data.item.id }, query: { s: data.item.id, t: data.item.surveyitemtitle } }"
            class="font-weight-bold d-block text-nowrap">

            Questions
          </b-link>

        </template>

        <!-- Column: Status -->
        <template #cell(actions)="data" v-if="surveyInfo != null && surveyInfo != undefined && surveyInfo.status == 0">


          <b-badge variant="light-secondary" v-if="Boolean(data.item.deleted) && !isWorking(data.item)">


            Deleted
          </b-badge>

          <b-spinner class="mr-1" v-if="isWorking(data.item)" variant="warning" />
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger"
            v-if="!Boolean(data.item.deleted) && !isWorking(data.item)" @click="deleteSubhect(data.item)">

            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Remove</span>
          </b-button>
        </template>




      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{
              $t('of') }}
              {{ dataMeta.of }}
              {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './SurveysubjectListFilters.vue'
import useUsersList from './useSurveysubjectList'
import userStoreModule from '../surveysubjectStoreModule'
import UserListAddNew from './SurveysubjectListAddNew.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {

  computed: {

  },
  created() {
    this.surveytitle = this.$route.query.t;
  },
  data() {
    return {
      surveytitle: "",
      operations: [],
      currentId: 0,
    }
  },
  methods: {
    newRecordCreated(coursemode) {
      //console.log("new record triggered");
      this.currentId++;


      this.refetchData();
      this.rateallSubjects = [];
      this.loading = false;
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Success`,
          icon: "CheckIcon",
          variant: "success",
        },
      });



    },
    isWorking(coursedata) {

      //   //console.log("computing:");
      // //console.log(this.operations);
      var str = `deleting_${coursedata.id}`;

      return this.operations.indexOf(str) > -1;


    },
    getRoute(coursemode) {

      return "page-coursemode";
    }
    ,

    publishCourse(coursedata) {
      //console.log(coursedata);
      store
        .dispatch('app-surveys/publishCourse', {
          id: coursedata.courseguid,

        })
        .then(response => {

          var result = response.data.success;
          if (result != null && result != undefined && result == 100) {
            coursedata.courseStatus = 100;

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;

          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          }


        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
    },
    draftCourse(coursedata) {

      store
        .dispatch('app-surveys/draftCourse', {
          id: coursedata.courseguid,
        })
        .then(response => {

          var result = response.data.success;
          if (result != null && result != undefined && result == 100) {
            coursedata.courseStatus = 0;

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;

          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          }


        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating course status',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    deleteSubhect(coursedata) {
      //console.log("deleting");
      var str = `deleting_${coursedata.id}`;
      //console.log(str);
      this.operations.push(str);
      //console.log(this.operations);


      store.dispatch('app-surveys/deleteSubject', coursedata)
        .then((response) => {

          var result = response.data.success;

          var str = `deleting_${coursedata.id}`;

          for (var i = this.operations.length - 1; i >= 0; i--) {
            if (this.operations[i] === str) {
              this.operations.splice(i, 1);
              // break;       //<-- Uncomment  if only the first term has to be removed
            }
          }


          coursedata.deleted = true;



        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
    }

  },

  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    ToastificationContent
  }, directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-surveys'
    //console.log("surveys");

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })


    var activeId = null;
    if (router.currentRoute.query.s) {
      //console.log("using from route");
      activeId = router.currentRoute.query.s;
    }
    else {
      //console.log("using from getter");
      var unit = store.getters['app/currentSurveyItem'];
      activeId = unit;

    }

    store.commit('app/SETACTIVESURVEYITEM', activeId)



    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const getPageroute = (surveyid) => {
      //console.log("route ",router.query.t );




      var result = { name: 'survey-questions', params: { id: surveyid }, query: { id: surveyid } };

      return result;


    }
    const getStatus = status => {



      // DRAFT 
      if (status == '0') return 'secondary'
      // published
      if (status == '100') return 'success'

      // ended by time limit
      if (status == '200') return 'warning'
      // stopped 
      if (status == '500') return 'warning'

      return 'secondary';


    }
    const getStatusText = status => {



      // DRAFT 
      if (status == '0') return 'Draft'
      // published
      if (status == '100') return 'Published'

      // ended by time limit
      if (status == '200') return 'Ended'
      // stopped 
      if (status == '500') return 'Stopped'

      return 'Draft';


    }


    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      surveyInfo,
      fetchSurvey,
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      resolveSurveyStatusVariant,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
      resolveSurveyStatus
    } = useUsersList();







    return {
      surveyInfo,
      fetchSurvey,
      // Sidebar
      isAddNewUserSidebarActive,

      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      resolveSurveyStatus,
      resolveSurveyStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
      getPageroute,
      getStatus,
      getStatusText,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
