<template>
  <b-sidebar :visible="isVisible" right no-header @change="(val) => $emit('update:isVisible', val)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Product Type
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>
      <b-form class="p-2">
        <b-form-group label="Category Title" label-for="category-name">
          <b-input-group label="Category Title" label-for="category-title">
            <type-selection @handleSelection="handleSelection" :selected="currentCategory"></type-selection>

          </b-input-group>
        </b-form-group>
        <b-form-group label="Category Description" label-for="type-description">
          <b-input-group label="Category Description" label-for="type-description">
            <b-form-input v-model="currentCategory.description" placeholder="Enter description" autofocus />
          </b-input-group>
        </b-form-group>
        <b-button type="button" variant="outline-secondary" @click="hide">
          {{ $t("Cancel") }}
        </b-button>
        <b-button type="button" class="ml-2" variant="primary" @click="addNew">
          Save
        </b-button>
      </b-form>

    </template>
  </b-sidebar>
</template>

<script>

import { BSidebar, BForm, BFormInput, BInputGroup, BFormGroup, BButton } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { inject, toRefs, ref, watch } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import TypeSelection from './ProductTypeSelect.vue'


export default {

  components: {
    BSidebar,
    BFormGroup,
    TypeSelection,
    BForm, BFormInput,
    BButton,
    BInputGroup
  },

  model: {
    prop: 'isVisible',
    event: 'handle'
    // event:'update:isVisible',
  },
  methods: {
    addNew() {
      this.$emit('handle', {
        item: this.currentCategory
      })
    },
    handleSelection(e) {

      //console.log(selected,e);
    }
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    selectedCategory: {
      required: true
    }
  },
  computed: {
    console: () => console
  },
  created() {

  },
  updated() {
    //console.log('sss',this.selectedCategory);
    if (this.selectedCategory) {
      //console.log('ss');
      this.currentCategory = this.selectedCategory
    }
  },
  //         watch: {
  //         categorydata:   {
  //         handler:function (n, o)   {
  //         //console.log('new client: ', n);
  //         if (n==null||n==undefined)
  //         {
  //         this.currentCategory={
  //             id:null,
  //             product:null,
  //             description:null
  //         }
  //         } 
  //         else{ 
  //         //console.log('new client: ', n.selectedCategory);
  //         // this.currentCategory = n.selectedCategory;
  //         }
  //         },
  //         deep: true
  //     }
  // },
  data() {
    return {
      currentCategory: {
        id: null,
        productType: null,
        description: null
      }
    }
  },
  //   inject: ['categorydata'],
  setup(props, { emit }) {
    //console.log('props',props);
  },
}
</script>