<template>
  <div>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <label>entries</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search...')" />
              <b-button variant="primary">
                <span class="text-nowrap">Add Invoice</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <Loader v-if="loading" />
    <b-card style="padding:0rem" v-else>
      <b-card-body style="padding:0rem">
        <b-table class="position-relative" style="text-align: center;padding: 0rem;"
          :items="state.invoices ? state.invoices : []" responsive :fields="invoiceListFields" primary-key="id"
          :busy="state.loading" show-empty :empty-text="$t('No matching records found')">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <loader />
            </div>
          </template>
          <template #cell(invoiceNumber)="data">
            <b-link :to="{
              name: 'invoiceDetails',
              params: {
                id: data.item.invoiceGuid
              }
            }">
              <span>{{ data.item.invoiceNumber }}</span>
            </b-link>
          </template>
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <template #aside>
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.title }}
              </span>
            </b-media>
          </template>
          <template #cell(actions)="data">
            <b-button type="submit" variant=".btn-flat-dark" :to="{
              name: 'invoice',
              params: {
                id: data.item.invoiceNumber
              }
            }">
              <edit-icon size="1.5x" class="custom-class text-primary"></edit-icon>
            </b-button>
            <b-button variant=".btn-flat-dark">
              <trash-2-icon size="1.5x" class="custom-class text-danger"></trash-2-icon>
            </b-button>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </div>
  <!-- <div style="background-image: url('');">
    <div>
    <div style="display: flex;align-items: center;justify-content:space-between;" >
      <img 
    style="height: 9rem;width: 13rem;border-radius: 8px;"
    src="https://cdn.niso.dev/8c1fb068-5aaf-4f37-9a88-a4debe1509ca/734a3c12-728c-41b2-96b6-c181e129effb.png"/>
      <h2 style="text-align: center;align-self: end;">Invoice</h2>
      <h2 style="text-align: center;align-self: end;">Invoice#E3325</h2>
    </div>
    <div style="display: flex;align-items: center;justify-content:space-between;" >
    
    <div style="font-size: 1.2rem;margin-top: 2rem;flex-wrap: wrap;max-width: 14rem;">
      
      <p>Store Name</p>
      <p>Office Location</p>
    </div>
    <div style="font-size: 1.2rem;margin-top: 2rem;align-self: flex-end;">
      <p>Date Issued: <b>19-10-2022</b> </p>
      <p>Due Issued: <b> 19-10-2022 </b> </p>
    </div>
  </div>
      <div style="height: 3px; background-color: grey" />

    <div
    style="
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: 5rem 0px;
      border-radius: 8px;
    "
  >
    
  <div
        style="
          flex-direction: row;
          margin-top: 1rem;
          justify-content: space-between;
          display: flex;
        "
      >
        <div>
          <label style="font-weight: 500; font-size: 16px; width: 15vw"
            >Customer Name</label
          >
          <span style="font-weight: 500; font-size: 16px">Alec Benjamin </span>
        </div>
        <div style="flex-direction: row-reverse">
          <span style="font-weight: 500; font-size: 16px; margin-right: 7vw"
            >Alec Benjamin</span
          >
          <label style="font-weight: 500; font-size: 16px">اسم الزبون</label>
        </div>
        </div>
      <div
        style="
          flex-direction: row;
          margin-top: 2vw;
          justify-content: space-between;
          display: flex;
        "
      >
        <div>
          <label style="font-weight: 500; font-size: 16px; width: 15vw"
            >Invoice Number</label
          >
          <span style="font-weight: 500; font-size: 16px">100 </span>
        </div>

        <div style="flex-direction: row-reverse">
          <span style="font-weight: 500; font-size: 16px; margin-right: 7vw"
            >100</span
          >
          <label style="font-weight: 500; font-size: 16px">رقم الفاتورة</label>
        </div>
      </div>
      <div
        style="
          flex-direction: row;
          margin-top: 2vw;
          justify-content: space-between;
          display: flex;
        "
      >
        <div>
          <label style="font-weight: 500; font-size: 16px; width: 15vw"
            >Invoice Issue Date</label
          >
          <span style="font-weight: 500; font-size: 16px">25/04/2022 </span>
        </div>

        <div>
          <span style="font-weight: 500; font-size: 16px; margin-right: 3vw"
            >25/04/2022
          </span>
          <label style="font-weight: 500; font-size: 16px"
            >تاريخ إصدار الفاتورة</label
          >
        </div>
      </div>

      <div
        style="
          flex-direction: column;
          margin-top: 2vw;
          text-align: end;
          display: flex;
        "
      >
        <div>
          <label style="font-weight: 500; font-size: 16px; width: 15vw"
            > :اسم الرشكة </label
          >
          
        </div>
          <div>
          <label style="font-weight: 500; font-size: 16px; width: 15vw"
            > :العنوان</label
          >
        </div>

      </div>
      <div
        style="
          flex-direction: row;
          margin-top: 2vw;
          justify-content: space-between;
          display: flex;
        "
      >
        <div>
          <label style="font-weight: 500; font-size: 16px; width: 15vw"
            >VAT Number:
          </label>
          <span style="font-weight: 500; font-size: 16px">25/04/2022 </span>
        </div>

        <div>
          <span style="font-weight: 500; font-size: 16px; margin-right: 5vw"
            >

          </span>
          <label style="font-weight: 500; font-size: 16px"
            >:رقم تعريف ضريبة القيمة المضافة</label
          >
        </div>
       
      </div>

      <table style="margin-top: 3rem;">
        <tr>
          <th
            style="
              padding: 18px;
              background-color: #d2d2d2;
              width: 23vw;
              border-right: 1px solid #ddd;
            "
          >
            Nature of goods or services  -  تفاصیل السلع أو الخدمات
          </th>
          <th
            style="
              padding: 15px;
              background-color: #d2d2d2;
              width: 10vw;
              border-right: 1px solid #ddd;"
          >
            Unit price - سعر الوحدة
          </th>
          <th
            style="
              padding: 15px;
              background-color: #d2d2d2;
              width: 10vw;
              border-right: 1px solid #ddd;
            "
          >
            Quantity - الكمیة
          </th>
          <th style="padding: 15px; background-color: #d2d2d2; width: 22vw">
            Item Subtotal (Including VAT) - المجموع (شامل ضریبة
القیمة المضافة)
          </th>
        </tr>
        <tr>
          <td style="padding: 18px; width: 23vw">Item A</td>
          <td style="padding: 15px; width: 10vw">200.00 SAR</td>
          <td style="padding: 15px; width: 10vw">1</td>
          <td style="padding: 15px; width: 22vw; text-align: right">
            230.00 SAR
          </td>
        </tr>
      </table>
      <div
        style="
          display: flex;
          flex-direction: column;
          padding: 15px;
          margin-top: 1vw;
          font-size: 16px;">
        <div style="height: 1px; margin-top: 3vw;margin-bottom: 1vw; background-color: grey" />
        <div
          style="
            flex-direction: row;
            display: flex;
            justify-content: space-between;">
          <div>
            <span>Total Taxable Amount (Excluding VAT)</span>
          </div>
          <div>
            <span>900.00 SAR</span>
          </div>
        </div>
        <div
          style="
            flex-direction: row;
            display: flex;
            justify-content: space-between;
          "
        >
          <div>
            <span>Total VAT</span>
          </div>
          <div>
            <span>مضافة القیمة ضریبة م</span>
          </div>
          
          <div>
            <span>135.00 SAR</span>
          </div>
        </div>
        <div
          style="
            flex-direction: row;
            display: flex;
            justify-content: space-between;
          "
        >
          <div>
            <span>Total Amount Due</span>
          </div>
          <div>
            <span>
              لمستحق المبلغ إج
            </span>
          </div>
          <div>
            <span>1,035.00 SAR</span>
          </div>
        </div>
        <div style="height: 1px; margin-top: 1rem; background-color: grey" />
        <div style="margin-top: 4rem;">
          <b>Note: </b><span>Thank you for doing business with us</span>
        </div>
      </div>
    </div>
  </div>
</div> -->
</template>


<script>
import {
  BTable,
  BCol,
  BRow,
  BFormInput,
  BButton,
  BCard,
  BCardBody,
  BMedia,
  BAvatar,
  BFormSelect,
  BLink,
  BFormFile,
  BCardText,
  BMediaBody,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import Loader from "../e-commerce/components/Loader.vue";
import Swal from "sweetalert2";
import axios from "axios";
import {
  Trash2Icon,
  EditIcon,
  EyeOffIcon,
  XCircleIcon,
  DownloadIcon,
  EyeIcon,
  ExternalLinkIcon,
} from "vue-feather-icons";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import useInvoice from "./useInvoice";

export default {
  components: {
    BTable,
    BCol,
    BLink,
    XCircleIcon,
    DownloadIcon,
    BAvatar,
    BMedia,
    BRow,
    vSelect,
    BFormInput,
    BButton,
    BFormSelect,
    BCardBody,
    BCard,
    BFormFile,
    BCardText,
    BMediaBody,
    BForm,
    Loader,
    Trash2Icon,
    EditIcon,
    EyeOffIcon,
    EyeIcon,
    ExternalLinkIcon,
  },
  data() {
    return {
      searchQuery: "",
    };
  },
  created() { },
  async mounted() { },

  computed: {},
  methods: {},

  setup() {
    const { invoiceListFields, listItem, state } = useInvoice();
    const isVisible = ref(false);

    return {
      invoiceListFields,
      listItem,
      state,
      isVisible,
    };
  },
};
</script>
<style lang="scss">
.swal2-confirm {
  margin-right: 10px !important;
}
</style>