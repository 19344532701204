<template>
  <b-card no-body class="mb-0">

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <!-- <label>Show</label> -->
          <!-- <v-select
                 v-model="perPage"
                 :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                 :options="perPageOptions"
                 :clearable="false"
                 class="per-page-selector d-inline-block mx-50"
               /> -->
          <!-- <label>entries</label> -->
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search...')" />
            <b-button @click="newCategory" variant="primary">
              <span class="text-nowrap">Add Type</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table class="position-relative" :items="results" responsive :fields="columns" primary-key="id" show-empty
      :empty-text="$t('No matching records found')">
      <template #cell(title)="data">
        <b-media vertical-align="center">
          <template #aside>
            <!-- <b-avatar
                   size="32"
                   text="adeel"
                   :to="{ name: 'apps-users-view', params: { id: 1 } }"
                 /> -->
          </template>
          <b-link :to="{ name: 'course-edit', params: { id: 1 } }" class="font-weight-bold d-block text-nowrap">
            {{ data.item.productType }}
          </b-link>
          <small class="text-muted">123</small>
        </b-media>
      </template>
      <template #cell(action)="data">
        <b-button variant=".btn-flat-dark" @click="changeCategory(data.item)">
          Edit
        </b-button>
        <b-button variant=".btn-flat-dark" @click="deleteCategory(data.item)">
          Delete
        </b-button>
      </template>
    </b-table>
    <add-new @handle="handle" :isVisible.sync="isVisible" :selectedCategory="selectedCategory" />
  </b-card>
</template>

<script>
import { BTable, BCol, BRow, BFormInput, BButton, BCard, BMedia, BAvatar, BLink } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useProductTypeList from './useProductList';
import { ref } from '@vue/composition-api';
import AddNew from './AddNew.vue';

export default {
  components: {
    BTable,
    BCol,
    BLink,
    BAvatar,
    BMedia,
    BRow,
    AddNew,
    vSelect,
    BFormInput,
    BButton,
    BCard
  },
  data() {
    return {
      searchQuery: '',
      selectedCategory: null,
      addedData: {
        id: null,
        product: null,
        description: null
      },
      items: [
        //  { id: 40, productType: 'Course 1 ', description: 'Related to section 2' },
        //  { id: 21, productType: 'Course 2 ', description: 'Related to section 1' },
        //  { id: 89, productType: 'Coruse 3', description: 'Related to section 3' },
        //  { id: 38, productType: 'Course 4', description: 'Related to section 5' }
      ]
    }
  },
  created() {

  },
  //     provide() {
  //     const categorydata = {}

  //     Object.defineProperty(categorydata, "selectedCategory", {
  //         enumerable: true,
  //         get: () => this.selectedCategory,
  //     })

  //     return {
  //         categorydata,
  //     }
  // },
  updated() {
    //console.log('iscc',this.isVisible);
    if (this.searchQuery) {
      const temp = [...this.items]
      //console.log('temps',temp);
    }
  },
  mounted() {

  },
  computed: {
    results() {
      if (this.searchQuery) {
        const temp = this.items.filter(i =>
          i.productType.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          i.description.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
        //console.log('hello',temp);
        return temp
      } else {
        // if(this.addedData){
        //   this.items.push(this.addedData)
        //   return this.items
        // }
        return this.items
      }
    },


  },
  methods: {
    handleSearch: (e) => {
      this.searchQuery = e
      // const res = temp.filter(i=>i.first_name.toLowerCase().includes(s.toLowerCase()))
      // //console.log(res);

    },
    handle(e) {
      //console.log('handled',e.item);

      this.addedData = {
        id: 5,
        productType: 'Books',
        description: e.item.description
      }
      if (!this.items.find(i => i.id == this.addedData.id) || this.items.length == 0) {
        this.items.push(this.addedData)
      }
      this.isVisible = false

    },


    changeCategory(e) {
      this.selectedCategory = e;
      this.isVisible = true;
      //console.log('heeloo',e);
    },
    newCategory(e) {
      this.selectedCategory = {
        id: null,
        product: null,
        description: null
      }
      this.isVisible = true
    },
    deleteCategory(e) {
      //console.log(e);
      this.items = this.items.filter(i => i.id != e.id)
    }
  },

  setup() {
    const { columns } = useProductTypeList()
    const isVisible = ref(false)


    return {
      columns,
      isVisible,

    }
  }
}
</script>