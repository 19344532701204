import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    activeUnit: 0,
    activeLecture: 0,
    activeSurveyItem: 0,
    redirectionRoute: null,
    isRedirectionRouteActive: false,
  },
  getters: {
    getRedirectionRoute: state => state.redirectionRoute,
    getIsRedirectionRouteActive: state => state.isRedirectionRouteActive,

    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    currentUnit: state => {


      return state.activeUnit;
    },
    currentLecture: state => {


      return state.activeLecture;
    },
    currentSurveyItem: state => {


      return state.activeSurveyItem;
    },
  },

  mutations: {
    SAVE_REDIRECTION_ROUTE(state, val) {
      state.redirectionRoute = val
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SETACTIVEUNIT(state, val) {
      state.activeUnit = val
    },
    SETACTIVELECTURE(state, val) {
      state.activeLecture = val
    },
    SETACTIVESURVEYITEM(state, val) {
      state.activeSurveyItem = val
    },
    SET_IS_REDIRECTION_ROUTE_ACTIVE(state, val) {
      state.isRedirectionRouteActive = val
    },
  },
  actions: {
    setIsRedirectionRouteActive(ctx, params) {
      return new Promise(resolve => {
        ctx.commit("SET_IS_REDIRECTION_ROUTE_ACTIVE", params)
        resolve()
      })
    },
    SaveRedirectionRoute(ctx, params) {

      ctx.commit("SAVE_REDIRECTION_ROUTE", params)
      if (params) {
        ctx.commit("SET_IS_REDIRECTION_ROUTE_ACTIVE", true)
      }
    },
  },
}
