export default [
  
  { header: 'Configuration' },
  {
    title: 'Admin Users',
    route: 'admin-users-page',
    icon: 'FileIcon',
  },
  {
    title: 'Settings',
    route: 'settings-page',
    icon: 'SettingsIcon',
  },
]
