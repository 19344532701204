<template>
  <div class="mb-5">
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">{{ title }}</h5>
        <small class="text-muted">
          {{ subtitle }}
        </small>
      </b-col>

    </b-row>
    <b-row>
      <b-col md="12">
        <b-list-group flush>
          <b-list-group-item class="d-flex b-none">
            <span class="mr-1">
              <b-badge variant="danger" class="badge-round">
                !
              </b-badge>
            </span>
            <span><small class="text-danger "> This will disable users to register platform over signup.</small> </span>
          </b-list-group-item>

          <b-list-group-item class="d-flex">
            <span class="mr-1">
              <b-badge variant="danger" class="badge-round">
                !
              </b-badge>
            </span>
            <span><small class="text-danger "> End point should support SSL.</small> </span>
          </b-list-group-item>
          <b-list-group-item class="d-flex">
            <span class="mr-1">
              <b-badge variant="danger" class="badge-round">
                !
              </b-badge>
            </span>
            <span><small class="text-danger"> End point should support Content-Type application Json </small> </span>
          </b-list-group-item>

          <b-list-group-item class="d-flex">
            <span class="mr-1">
              <b-badge variant="danger" class="badge-round">
                !
              </b-badge>
            </span>
            <span><small class="text-danger"> End point should return a Json object including token , fullname, photo ,
                username , userid ,email </small> </span>
          </b-list-group-item>
        </b-list-group>


      </b-col>
    </b-row> <b-row>
      <!--/ birth date -->
      <b-col md="12">
        <div class="demo-inline-spacing mb-1">
          <b-form-radio v-model="localLoginInfo.useTokenInHeader" name="some-radios" v-bind:value="true">
            Endpoints requires token in Authorisation Header
          </b-form-radio>
          <b-form-radio v-model="localLoginInfo.useTokenInHeader" name="some-radios" v-bind:value="false">
            Endpoints requires token in url query string.
          </b-form-radio>
        </div>
      </b-col>


      <b-col md="10">
        <b-form-group label="Endpoint that does login process" label-for="v-first-name">
          <b-form-input id="v-country-api-url" v-model="localLoginInfo.authLoginUrl"
            placeholder="https://api.example.com/countries" />
        </b-form-group>
      </b-col>
      <b-col v-if="2 > 3" md="2">
        <b-form-group label="METHOD " label-for="v-first-name">
          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="requestMethods" />
        </b-form-group>
      </b-col>

    </b-row>
    <b-row>


      <b-col md="4">
        <b-form-group label="Username" label-for="v-first-name">
          <b-form-input id="v-first-name" v-model="localLoginInfo.authenticatedAccount"
            placeholder="something@something.com" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Password" label-for="v-first-name">
          <b-form-input id="v-first-name" v-model="localLoginInfo.authenticatedAccountPass" placeholder="123456" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Click & Fetch " label-for="v-first-name">
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="fecthCountries">
            Fetch
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>



    <!--- Headers Area -->

    <div class="d-none">
      <div>
        <b-col md="12">
          <b-form ref="form" :style="{ height: trHeight }" class="repeater-form" @submit.prevent="repeateAgain">
            <!-- Row Loop -->
            <b-row v-for="(item, index) in items" :id="item.id" :key="item.id" ref="row">
              <!-- Item Name -->
              <b-col md="4">
                <b-form-group label="Header Name" label-for="item-name">
                  <b-form-input :id="item.id" type="text" placeholder="Authorisation" />
                </b-form-group>
              </b-col>

              <!-- Cost -->
              <b-col md="4">
                <b-form-group label="Value" label-for="val">
                  <b-form-input id="val" type="text" placeholder="Bearer eft222ddsd....." />
                </b-form-group>
              </b-col>

              <!-- Quantity -->



              <!-- Remove Button -->
              <b-col md="3" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2"
                  @click="removeItem(index)">
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Delete</span>
                </b-button>
              </b-col>

            </b-row>
          </b-form>
        </b-col>
        <b-col md="3">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">

            <span>Add Header</span>
          </b-button> </b-col>
      </div>
    </div>

    <!-- HEaders End -->
    <b-row>


      <b-col md="6" v-if="!!importedCountries.token">

        <b-card-code :code_visible="true" title="Response">

          <b-card-text>
            <span>You can check the result from API </span>

          </b-card-text>

          <template #code>
            {{ demoRequestResponse }}
          </template>
        </b-card-code>

      </b-col>
      <b-col md="3">

        <b-card-code v-if="!!importedCountries.token" :code_visible="false" title="Result">

          <b-card-text>
            <b-col md="12">
              <b-alert v-if="!!importedCountries.token" variant="success" show>
                <div class="alert-body">
                  <span>
                    if you continue
                    <strong> {{ importedCountries.length }}</strong> New Login endpoint will be set.</span>
                </div>
              </b-alert>
            </b-col>
            <b-col md="6" v-if="false">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="importCountries">
                Apply
              </b-button>
            </b-col>
          </b-card-text>


        </b-card-code>

      </b-col>

    </b-row>


    <b-row>
      <b-col cols="12">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1"
          @click.prevent="sendInformation">
          {{ $t("Save Changes") }}
        </b-button>

      </b-col>
    </b-row>
  </div>
</template>


<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BAlert,
  BForm,
  BCardText,
  BBadge, BListGroup, BFormRadio, BListGroupItem
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CountryPicker from "../component/CountryPicker.vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "@axios";
import { heightTransition } from "@core/mixins/ui/transition";
import BCardCode from '@core/components/b-card-code'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BAlert,
    BForm,
    BCardText,
    BCardCode,
    vSelect,
    BFormRadio,
    CountryPicker, BBadge, BListGroup, BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mixins: [heightTransition],
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedLanguage: "nothing_selected",
      selectedContry: "Turkey",
      requestMethod: "POST",
      useTokeninHeader: true,
      demoUser: "",
      demoPass: "",
      demoToken: null,
      localLoginInfo: {},

      //  {
      //     url:"",
      //     fetched:false,
      //     useCustomLogin:false,
      //    requestMethod:"POST",
      //     loginusername:"",
      //     loginpassword:"",
      //     useTokeninHeader:true,
      //     loginToken:"",
      //     authenticatedAccount:null,
      //     authenticatedAccountPass:null,
      //     additionalAuthAccountInfo:null,
      //     loginUrl:null,
      //     authLoginUrl:null, 
      //     useTokenInHeader:true,
      //     useTokenInQuery:false,
      //     tokenHeaderKey:null,

      //   },
      demoRequestResponse: "",
      requestMethods: ["GET", "POST", "PUT"],
      headers: {},
      importedCountries: {},
      customCountryEndpoint: null,
      customCountryBase: "",
      selectedCountries: [],
      items:
      {

        "Content-Type": "application/json",

      },

      nextTodoId: 2,

    };
  },
  props: {
    title: "",
    subtitle: "",
    description: "",
    sampleresponse: "",
    initialLoginInfo: {
      type: Object,
      default: () => { },
    },
  },
  methods: {

    sendInformation() {
      //console.log(this.localLoginInfo);


      this.isLoading = true;
      axios({
        method: "put",
        url: `/admin/f/migrationsettings`,
        data: {
          info: "authlogin",
          file: null,
          content: this.localLoginInfo,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        //console.log("validationForm");
        //console.log(this.selectedCountries.length);

        if (this.selectedCountries.length > 0) {
          resolve(true);
        } else {
          reject();
        }
      });
    },
    importCountries() {
      this.$emit("importdataclicked", this.importedCountries);
    },

    fecthCountries() {

      //console.log(this.localLoginInfo);


      return new Promise((resolve, reject) => {
        var uri = this.localLoginInfo.authLoginUrl;

        var options = {
          method: 'POST',//this.localLoginInfo.requestMethod,
          body: JSON.stringify({
            "username": this.localLoginInfo.authenticatedAccount,
            "password": this.localLoginInfo.authenticatedAccountPass
          }),
          headers: this.items,
        };

        const response = fetch(uri, options)
          .then((response) => {
            var data = response.json();

            //console.log(data );

            return data;
          })
          .then((data) => {
            //console.log(data);
            this.importedCountries = data;
            this.demoRequestResponse = JSON.stringify(data);
            var respObject = JSON.parse(this.demoRequestResponse);
            this.demoToken = respObject.token;
            this.localLoginInfo.tokenExpireDate = respObject.tokenExpiration;
            this.localLoginInfo.activeToken = respObject.token;
            ;
            this.$emit("datafetched", this.demoToken);
            resolve(data);
          })
          .catch((error) => {
            //console.log(error);
            reject(error);
          });
      });
    },

    countrySelected(value) {
      this.selectedCountries = value;
    },

    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Form Submitted",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
  mounted() {
    //console.log("mounted");

    //  this.initTrHeight();
  },
  watch:
  {
    initialLoginInfo(newvalue, oldvalue) {
      //console.log("updated");
      //console.log( this.initialLoginInfo);
      //console.log(newvalue);

      this.localLoginInfo = this.initialLoginInfo == null ? {} : JSON.parse(JSON.stringify(this.initialLoginInfo.data));//this.generalData;


    }
  },
  created() {
    //console.log("Created");
    //console.log(this.initialLoginInfo);

    //  this.localLoginInfo = JSON.parse(JSON.stringify(this.initialLoginInfo));//this.generalData;
    this.localLoginInfo = this.initialLoginInfo == null ? {} : JSON.parse(JSON.stringify(this.initialLoginInfo));//this.generalData;

    window.addEventListener("resize", this.initTrHeight);

  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
};
</script>
