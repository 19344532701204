<template>
  <b-sidebar id="add-new-video-sidebar" :visible="isAddNewVideoSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-video-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Video
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <!-- Name -->
          <validation-provider #default="validationContext" name="Name" rules="required">
            <b-form-group label="Name" label-for="name">
              <b-form-input id="name" v-model="videoData.name" autofocus :state="getValidationState(validationContext)"
                trim placeholder="" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider #default="validationContext" name="Description" rules="">
            <b-form-group label="Description" label-for="description">
              <b-form-input id="description" v-model="videoData.description"
                :state="getValidationState(validationContext)" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- File Name -->
          <validation-provider #default="validationContext" name="FileName" rules="">
            <b-form-group label="File Name" label-for="fileName">
              <b-form-input id="fileName" v-model="videoData.fileName" :state="getValidationState(validationContext)"
                trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- File Path -->
          <validation-provider #default="validationContext" name="FilePath" rules="required">
            <b-form-group label="File Path" label-for="filePath">
              <b-form-input id="fileName" v-model="videoData.filePath" :state="getValidationState(validationContext)"
                trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="FilePath" rules="required">
            <b-form-group>
              <h5>Order</h5>

              <b-form-spinbutton id="sb-vertical" v-model="videoData.order" inline />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- File Upload -->
          <!--          <validation-provider-->
          <!--              #default="validationContext"-->
          <!--              name="FileName"-->
          <!--              rules="required"-->
          <!--          >-->
          <!--            <b-form-group-->
          <!--                label="File Name"-->
          <!--                label-for="fileName"-->
          <!--            >-->
          <!--          <b-form-file-->
          <!--              v-model="videoData.file"-->
          <!--              :state="Boolean(videoData.file)"-->
          <!--              placeholder="Choose a file or drop it here..."-->
          <!--              drop-placeholder="Drop file here..."-->
          <!--          />-->
          <!--              <b-form-invalid-feedback>-->
          <!--                {{ validationContext.errors[0] }}-->
          <!--              </b-form-invalid-feedback>-->
          <!--            </b-form-group>-->
          <!--          </validation-provider>-->
          <!--          <b-card-text class="my-1">-->
          <!--            Selected file: <strong>{{ videoData.file ? videoData.file.name : '' }}</strong>-->
          <!--          </b-card-text>-->
          <!--          <div v-if="videoData.file">-->
          <!--            <b-button-->
          <!--                v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
          <!--                variant="success"-->
          <!--                class="btn-icon mr-1"-->
          <!--            >-->
          <!--              <feather-icon icon="UploadIcon" />-->
          <!--            </b-button>-->
          <!--            <b-button-->
          <!--                @click="videoData.file  = null"-->
          <!--                v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
          <!--                variant="danger"-->
          <!--                class="btn-icon"-->
          <!--            >-->
          <!--              <feather-icon icon="Trash2Icon" />-->
          <!--            </b-button>-->

          <!--          </div>-->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              {{ $t("Add") }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              {{ $t("Cancel") }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  VBModal, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BFormSelect, BSidebar, BForm, BFormInvalidFeedback, BFormSpinbutton
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'


import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'VideoListAddNew',
  components: {
    vSelect,
    flatPickr,
    VBModal, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BFormSelect, BSidebar, BForm, BFormInvalidFeedback, BFormSpinbutton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewVideoSidebarActive',
    event: 'update:is-add-new-video-sidebar-active',
  },
  props: {
    isAddNewVideoSidebarActive: {
      type: Boolean,
      required: true,
    }

  },
  data() {
    return {
      required,
      alphaNum
    }
  },
  setup(props, { emit }) {

    const toast = useToast()
    const blankVideoData = {
      name: '',
      description: '',
      file: null,
      fileName: '',
      filePath: '',
      order: 1

    }

    const videoData = ref(JSON.parse(JSON.stringify(blankVideoData)))
    const resetvideoData = () => {
      videoData.value = JSON.parse(JSON.stringify(blankVideoData))
    }



    const onSubmit = () => {

      store.dispatch('createVideo', videoData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-video-sidebar-active', false)
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding videos list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })

    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetvideoData)

    return {
      videoData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-video-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
