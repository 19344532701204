<template>
  <b-card>
    <!-- form -->
    <h6 class="section-label mb-3  mt-1">Certification</h6>
    <b-form>

      <b-row>

        <b-col md="12">
          <b-card-text class="text-info ">
            {{ $t("Will The Course be certified by the instructor after the course is completed?") }}
          </b-card-text>
          <div class="demo-inline-spacing mb-1">
            <b-form-radio v-model="localOptions.courseCertified" name="cert-radios" v-bind:value="false">
              {{ $t("Not Certificated") }}
            </b-form-radio>
            <b-form-radio v-model="localOptions.courseCertified" name="cert-radios" v-bind:value="true">
              {{ $t("Yes Certified") }}
            </b-form-radio>

          </div>
        </b-col>
      </b-row>
      <b-row v-if="Boolean(localOptions.courseCertified)">
        <b-col md="8">
          <b-form-group label-for="countryList" :label="$t('Select Certificate')">
            <certificate-picker :initialCategories="localOptions.certificationInfo"
              @selectionChanged="certifiateUpdated">
            </certificate-picker>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-button variant="outline-primary" @click="HandleRouteRedirection('certificate')"
            class="font-weight-bold d-block text-nowrap mt-2">
            {{ $t("Add New Certificate") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>

        <b-col md="12" class="text-info">
          <b-card-text>
            {{ $t("Will the certificate be issued after the exams are completed?") }}
          </b-card-text>
          <div class="demo-inline-spacing mb-1">
            <b-form-radio v-model="localOptions.hasCertificationExam" name="exam-radios" v-bind:value="false">
              {{ $t("The certificate will be issued after the course is completed") }}
            </b-form-radio>
            <b-form-radio v-model="localOptions.hasCertificationExam" name="exam-radios" v-bind:value="true">
              {{ $t("The certificate will be issued after the exams are completed") }}
            </b-form-radio>

          </div>
        </b-col>
      </b-row>

      <b-row v-if="localOptions.hasCertificationExam == 1">

        <b-col md="8">
          <b-form-group label-for="countryList" :label="$t('Select exam for certification')">
            <exam-picker
              :initialCategories="localOptions.certificationexamInfo != null && localOptions.certificationexamInfo != undefined && localOptions.certificationexamInfo != '' ? JSON.parse(localOptions.certificationexamInfo) : localOptions.certificationexamInfo"
              @selectionChanged="examUpdated">
            </exam-picker>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-button variant="outline-primary" @click="HandleRouteRedirection('exam')"
            class="font-weight-bold d-block text-nowrap mt-2">
            {{ $t("Add New Exam") }}
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1 mt-1"
            :disabled="isLoading ? true : false" @click.prevent="sendInformation">
            {{ $t("Save Changes") }}
          </b-button>
        </b-col>

      </b-row>

    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BFormRadio,
  BCardText,
  BLink,
  BFormCheckbox,
} from "bootstrap-vue";
import router from '@/router'

import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import CertificatePicker from '../../certificate/components/CertificatePicker.vue'
import ExamPicker from '../../exam/components/ExamSelector.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BLink,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    flatPickr,
    Cleave,
    BCardText,
    BFormRadio,
    BFormCheckbox,
    CertificatePicker, ExamPicker
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => { },
    },
    id: {
      type: Number,
      required: true,
      default: () => '',
    },

    fieldInformation: {
      type: String,
      required: true,
      default: () => '',
    },
  },
  created() {
    this.localOptions = JSON.parse(JSON.stringify(this.informationData.data));
    this.localOptions.certificationInfo = this.localOptions.certificationInfo;
    this.localOptions.certificationexamInfo = this.localOptions.certificationexamInfo;

  },
  data() {
    return {
      isLoading: false,
      isUpdated: false,
      examOptions: ["Exam 1", "Exam 2", "Exam 3"],
      countryOption: ["USA", "India", "Canada"],
      localOptions: null,
      isCertified: "0",
      isExamDepended: "0",
      hasCertainStartDate: "0",
      hasCertainEndDate: "0",
      allowPublicAccess: "0",
      clevePhone: {
        phone: true,
        phoneRegionCode: "US",
      },
    };
  },
  methods: {
    HandleRouteRedirection(type) {
      if (type === "certificate") {

        this.$store.dispatch("app/SaveRedirectionRoute", { name: router.currentRoute.name, params: router.currentRoute.params, active: true })
        router.push({ name: "certifications-page" })
      }
      else if (type === "exam") {

        this.$store.dispatch("app/SaveRedirectionRoute", { name: router.currentRoute.name, params: router.currentRoute.params, active: true })
        router.push({ name: "exam-page" })
      }
      return ""
    },

    sendInformation() {

      this.$store.dispatch("app/SaveRedirectionRoute", null)

      this.isLoading = true;
      axios({
        method: 'put',
        url: `/lms/admin/course/updatecoursedetail/${this.id}`,
        data: {
          info: "courseCertificationSettings",
          content: this.localOptions
        }
      }).then((r) => {

        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
        this.isLoading = false;
        this.isUpdated = false;

      }

      )
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        }
        );
      return;


    },

    certifiateUpdated(payload) {
      if (payload == null) {
        this.localOptions.certificationInfo = null;
      } else
        this.localOptions.certificationInfo = JSON.stringify(payload);
    },
    examUpdated(payload) {
      if (payload == null) {
        this.localOptions.certificationexamInfo = null;
      } else
        this.localOptions.certificationexamInfo = JSON.stringify(payload);
    },

    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
