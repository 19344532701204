import { render, staticRenderFns } from "./PositionsList.vue?vue&type=template&id=09aa7260&scoped=true&"
import script from "./PositionsList.vue?vue&type=script&lang=js&"
export * from "./PositionsList.vue?vue&type=script&lang=js&"
import style0 from "./PositionsList.vue?vue&type=style&index=0&id=09aa7260&lang=scss&scoped=true&"
import style1 from "./PositionsList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09aa7260",
  null
  
)

export default component.exports