<template>
  <div>
    <!-- <Loader v-if="loader" /> -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <label>entries</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search...')" />
              <b-button :to="{
                name: 'create-product',
              }" variant="primary">
                <span class="text-nowrap">Add Product</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <template>
        <b-table :busy="loader" style="text-align: center" v-if="products != null" :per-page="perPage" show-empty
          :items="products" :fields="fields">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <loader />
            </div>
          </template>
          <template #cell(productStatus)="data">
            <b-badge pill class="text-capitalize" style="min-width: 90px; max-width: 90px" :variant="`light-${data.item.productStatus == 1 ? 'success' : 'danger'
              }`">
              {{ data.item.productStatus == 1 ? "Active" : "Inactive" }}
            </b-badge>
          </template>
          <template #cell(brand)="data">
            <span v-if="data.item.brands != null">{{
              data.item.brands.title
            }}</span>
          </template>
          <template #cell(actions)="data">
            <!-- <b-button
            variant=".btn-flat-dark"
            :to="{
              name: 'ProductDetails',
              params: {
                id: data.item.id,
              },
            }"
          >
            <eye-icon size="1.5x" class="custom-class text-success"></eye-icon>
          </b-button> -->
            <b-button style="padding: 0.5rem" variant=".btn-flat-dark" :to="{
              name: 'ProductEdit',
              params: {
                id: data.item.id,
              },
            }">
              <edit-icon size="1.5x" class="custom-class text-primary"></edit-icon>
            </b-button>
            <b-button style="padding: 0.5rem" variant=".btn-flat-dark" @click="() => {
                if (data.item.productStatus == 1) {
                  handleProductDisable(data.item.id);
                }
              }
              ">
              <x-circle-icon size="1.5x" class="custom-class text-warning"></x-circle-icon>
            </b-button>
            <b-button style="padding: 0.5rem" variant=".btn-flat-dark" @click="handleDelete(data.item.id)">
              <trash-2-icon size="1.5x" class="custom-class text-danger"></trash-2-icon>
            </b-button>
          </template>
        </b-table>
      </template>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">Showing {{ skip + 1 }} to {{ skip + 10 }} of
              {{ rows }} entries</span>
          </b-col>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BCol,
  BRow,
  BBadge,
  BFormInput,
  BPagination,
  BButton,
  BCard,
  BMedia,
  BAvatar,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Loader from "../components/Loader.vue";
import useProductList from "./useProductList";
import {
  Trash2Icon,
  EditIcon,
  XCircleIcon,
  EyeOffIcon,
  EyeIcon,
  ExternalLinkIcon,
} from "vue-feather-icons";
import { deleteCall, putCall, getCall } from "../Service";
import Swal from "sweetalert2";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { confirmationPop } from "../lib/helpers";

export default {
  components: {
    BTable,
    BCol,
    BLink,
    BAvatar,
    BMedia,
    BBadge,
    Loader,
    BPagination,
    BRow,
    vSelect,
    BFormInput,
    XCircleIcon,
    BButton,
    BCard,
    Trash2Icon,
    EditIcon,
    EyeOffIcon,
    EyeIcon,
    ExternalLinkIcon,
  },
  data() {
    return {
      searchQuery: "",
      skip: 0,
      rows: 0,
      pageSeries: [],
      perPage: 10,
      currentPage: 1,
      loader: false,
      selectedCategory: null,
      products: [],
    };
  },
  async mounted() {
    await this.fetchAllProducts(0, 10, "");
  },
  methods: {
    async fetchAllProducts(skip, take, search) {
      try {
        this.loader = true;
        const res = await getCall(
          `admin/store/products/all?skip=${skip}&take=${take}&keyword=${search}`
        );
        this.products = res.data.products;
        this.rows = res.data.count;
        this.loader = false;
      } catch (error) {
        this.loader = false;
      }
    },
    async handleProductDisable(id) {
      confirmationPop(
        "Do you want to disable the product?",
        true,
        "Disable",
        async () => {
          try {
            await putCall(`admin/store/product/disable/${id}`);
            await this.fetchAllProducts(0, 10, "");
          } catch (error) {
            if (error.response) {
              Swal.fire("Error", error.response.data.message, "error");
            } else {
              Swal.fire("Error", error, "error");
            }
          }
        }
      );
    },
    async handleDelete(id) {
      confirmationPop(
        "Do you want to delete this product?",
        true,
        "Delete",
        async () => {
          try {
            await deleteCall(`admin/store/product/${id}`);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Product Deleted Successfully`,
                icon: "CoffeeIcon",
                variant: "danger",
              },
            });
            await this.fetchAllProducts(0, 10, "");
          } catch (error) {
            if (error.response) {
              Swal.fire("Error", error.response.data.message, "error");
            } else {
              Swal.fire("Error", "Error deleting product", "error");
            }
          }
        }
      );
    },
  },
  watch: {
    currentPage: function (newVal, old) {
      const series = this.pageSeries.find((i) => i.p == newVal);
      this.fetchAllProducts(series.s, this.perPage, "");
    },
    rows: function () {
      let pages = this.rows / 10;
      //console.log(pages % 10);
      if (pages != 0) {
        if (this.rows > 10) {
          pages = pages + 1;
        }
      }
      this.pageSeries = [];
      this.skip = 0;
      for (let i = 1; i <= pages; i++) {
        this.pageSeries.push({ s: this.skip, p: i });
        this.skip = this.skip + 10;
      }
    },
    searchQuery: function (newVal) {
      this.fetchAllProducts(0, this.perPage, newVal ? newVal : "");
    },
  },
  setup() {
    const { fields } = useProductList();
    return {
      fields,
    };
  },
};
</script>

<style>
.swal2-confirm {
  margin-right: 10px !important;
}

.noProducts {
  text-align: center;
}

.table th {
  padding: 0px;
}
</style>
