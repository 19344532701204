<template>
  <b-sidebar id="add-new-user-sidebar" :visible="isAddNewUserSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Add New User') }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>


      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <!-- Full Name -->
          <validation-provider #default="validationContext" name="First Name" rules="required">
            <b-form-group :label="$t('First Name')" label-for="full-name">
              <b-form-input id="full-name" v-model="userData.firstname" autofocus
                :state="getValidationState(validationContext)" trim placeholder="John" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Last Name" rules="required">
            <b-form-group :label="$t('Last Name')" label-for="full-name">
              <b-form-input id="full-name" v-model="userData.lastname" autofocus
                :state="getValidationState(validationContext)" trim placeholder="Doe" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Email -->
          <validation-provider #default="validationContext" name="Account" rules="required">
            <b-form-group :label="$t('User Name')" label-for="email">
              <b-form-input id="email" v-model="userData.email" :state="getValidationState(validationContext)" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Password -->
          <validation-provider #default="validationContext" name="Password" rules="required">
            <b-form-group :label="$t('Password')" label-for="password">
              <b-form-input id="pasword" v-model="userData.password" :state="getValidationState(validationContext)"
                trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-row>

            <b-col md="12">
              <validation-provider #default="validationContext" name="Mobile" rules="">
                <b-form-group :label="$t('Mobile')" label-for="full-name">
                  <b-form-input id="full-name" v-model="userData.mobile" autofocus type="number"
                    :state="getValidationState(validationContext)" trim placeholder="5554443322" />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-form-group label="Department" label-for="department-id">
            <department-picker :initialCategories="onDepartmentSelectionChanged.department"
              @selectionChanged="onDepartmentSelectionChanged">
            </department-picker>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              {{ $t("Add") }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              {{ $t("Cancel") }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import CountryPicker from "../../settings/component/PhonePicker.vue";
import store from '@/store'
import DepartmentPicker from "@/views/clinic/clinicdepartments/components/DepartmentPicker.vue";
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow, BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    CountryPicker,
    DepartmentPicker,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      phonecode: ''
    }
  },
  watch: {
    'userData.email': function (newEmail) {
      if (newEmail.includes('@')) {
        this.userData.password = newEmail.split('@')[0];
      }
      else {
        this.userData.password = ''
      }
    },
  },
  methods: {
    onDepartmentSelectionChanged(payload) {
      this.userData.department = payload;
    },

    countrySelected(payload) {
      //console.log(payload);
      this.userData.countryPhoneCode = payload.phonecode;

    },
  },

  setup(props, { emit }) {
    const blankUserData = {
      fullname: '',
      firstname: '',
      lastname: '',
      username: '',
      email: '',
      password: '',
      company: '',
      country: '90',
      department: null,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      userData.value.fullname = userData.value.firstname + ' ' + userData.value.lastname;
      userData.value.password = userData.value.password;

      //console.log(userData.value);


      store.dispatch('apps-members/createNewMember', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
