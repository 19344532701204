<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        Course Settings
      </h6>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox id="accountSwitch1" :checked="localOptions.everyAttendeeRequirestoFill" name="check-button"
          v-model="localOptions.everyAttendeeRequirestoFill" switch inline>
          <span>Every attendee requires to complete survey</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox id="accountSwitch2" :checked="localOptions.sendReminderMailstoAttendees"
          v-model="localOptions.sendReminderMailstoAttendees" name="check-button" switch inline>
          <span>Send reminder mails to attendees</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox id="accountSwitch3" :checked="localOptions.showResultswhenEnded"
          v-model="localOptions.showResultswhenEnded" name="check-button" switch inline>
          <span>The results will be shown at the end of survey.</span>
          <b-badge pill variant="light-info">The current results won't be shown until survey is completed</b-badge>
        </b-form-checkbox>
      </b-col>

      <!-- application switch -->

      <b-col cols="12" class="mt-1 mb-2">
        <b-form-checkbox id="accountSwitch4" :checked="localOptions.anonymousAttendees"
          v-model="localOptions.anonymousAttendees" name="check-button" switch inline>
          <span>The attendees will be anonymous </span>
          <b-badge pill variant="light-danger">The Attendees will be notified about this selection</b-badge>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox id="accountSwitch5" :checked="localOptions.allowAccessAfterExpireforUnattended"
          v-model="localOptions.allowAccessAfterExpireforUnattended" name="check-button" switch inline>
          <span>Allow access for attendees who hasn't completed survey, even survey expired </span>
        </b-form-checkbox>
      </b-col>

      <!--/ application switch -->

      <!-- buttons -->
      <b-col cols="12">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1 mt-1"
          :disabled="isLoading ? true : false" @click.prevent="sendInformation">
          {{ $t("Save Changes") }}
        </b-button>

      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox, BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BBadge
  },
  directives: {
    Ripple,
  },
  props: {
    notificationData: {
      required: false,
    },
    courseId: {
      type: Number,
      required: true,
    },
  },
  methods: {

    sendInformation() {

      //console.log("update course  is launching with id : "+this.courseId );
      //console.log(this.localOptions);

      this.isLoading = true;
      axios({
        method: 'put',
        url: `/survey/admin/surveydetail/${this.courseId}`,
        data: {
          info: "surveyPreferences",
          content: this.localOptions
        }
      }).then((r) => {

        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
        this.isLoading = false;
        this.isUpdated = false;

      }

      )
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        }
        );
      return;


    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.notificationData.data)),
      isLoading: false,
      isUpdated: false,
    }
  },
  beforeDestroy() {

  },

  beforeMount() {
    //console.log(`At this point, vm.$el has not been created yet.`)
  },
  mounted() {



    //   //console.log('this is current quill instance object', this.editor)
  },
}
</script>
