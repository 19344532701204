<template>
  <b-row v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="content-header">

    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $t($route.meta.pageTitle) }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
              </b-breadcrumb-item>
              <b-breadcrumb-item v-for="item in $route.meta.breadcrumb" :key="item.text" :active="item.active"
                :to="item.to">
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col class="content-header-right text-md-right d-md-block d-none mb-1" md="3" cols="12">
      <b-dropdown variant="link" no-caret toggle-class="p-0" right>
        <!--
        <template  #button-content>
          <span class="align-middle mr-50">Current Content Language </span>
   
          <span class="align-middle ml-50 mr-50"  >{{ currentLanguage }}</span>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
          >
            <feather-icon icon="FlagIcon" />
          </b-button>
        </template>
-->
        <b-dropdown-item v-for="items in languages" :value="items.id" :key="items.id">
          <b-button variant="btn-flat-primary" class="btn-icon" @click="changeLanguage(items.id)">
            <feather-icon icon="FlagIcon" size="20" class="d-block d-xl-none" />
            <span class="align-middle ml-50">{{ items.name }}</span>
          </b-button>

        </b-dropdown-item>


      </b-dropdown>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'

import { ref, onMounted, } from "@vue/composition-api";
import { getLanguage } from 'highlight.js'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  data: () => ({
    currentIndex: 0,

    languages: [
      {
        id: 'EN',
        name: 'English',
        icon: 'FlagIcon',
      },
      {
        id: 'TR',
        name: 'Turkish',
        icon: 'FlagIcon',
      },
      {
        id: 'AR',
        name: 'Arabic',
        icon: 'FlagIcon',
      },
      {
        id: 'CH',
        name: 'Chinese',
        icon: 'FlagIcon',
      },
    ],
  }),
  setup(props, context) {
    const router = context.root.$router;
    const route = context.root.$route;
    const currentLanguage = ref(null);

    currentLanguage.value = localStorage.getItem("CURRENT_LANGUAGE") ?? "EN"
    onMounted(() => {
      currentLanguage.value = localStorage.getItem("CURRENT_LANGUAGE") ?? "EN"
    })
    const changeLanguage = (lang) => {
      ////console.log(lang);
      localStorage.setItem("CURRENT_LANGUAGE", lang);
      currentLanguage.value = lang;
      router.go(0);



    }

    return {
      currentLanguage,
      changeLanguage
    }
  }
  ,




  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
}
</script>
