import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllDepartments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/f/clientdepartments')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

     
    createDepartment(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('clinic/admin/departments',  userData  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
      
    updateblogcategory(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('clinic/admin/departments',  userData  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


  },
}
