import { render, staticRenderFns } from "./TrainingAssignEmployeeTab.vue?vue&type=template&id=8d905516&scoped=true&"
import script from "./TrainingAssignEmployeeTab.vue?vue&type=script&lang=js&"
export * from "./TrainingAssignEmployeeTab.vue?vue&type=script&lang=js&"
import style0 from "./TrainingAssignEmployeeTab.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./TrainingAssignEmployeeTab.vue?vue&type=style&index=1&id=8d905516&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d905516",
  null
  
)

export default component.exports