<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <label>entries</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search...')" />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- <pagination 
    v-if="orders && orders.length" 
    :getRecords="refetchData"
    :numberOfPages="orders"/> -->
    <!-- <Loader v-if="loading" /> -->
    <b-card no-body class="mb-0">
      <template>
        <b-table style="text-align: center" :per-page="perPage" ref="refUserListTable" show-empty :busy="loading" small
          responsive striped hover :items="orders" :fields="fields">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <loader />
            </div>
          </template>
          <template #cell(orderNumber)="data">
            <b-link :to="{
              name: 'orderDetails',
              params: {
                id: data.item.id,
              },
            }">
              {{ data.item.orderNumber }}
            </b-link>
          </template>
          <template #cell(orderStatus)="data">
            <!-- :variant="[data.item.orderStatus == 0 ? 'danger' : 'primary']" -->
            <b-badge class="text-capitalize" pill style="min-width: 140px; max-width: 180px" :variant="`light-${resolveUserStatusVariant(
              data.item.orderStatus
            )}`">
              {{ orderStatusDict[data.item.orderStatus] }}
            </b-badge>
          </template>
          <template #cell(paymentStatus)="data">
            <b-button variant=".btn-flat-dark">
              {{
                data.item.paymentStatus == 0
                  ? "INCOMPLETE"
                  : data.item.paymentStatus == 100
                    ? "PAID"
                    : data.item.paymentStatus == 300
                      ? "REFUNDED"
                      : data.item.paymentStatus == 90 ? "PARTIALLY_REFUNDED" : data.item.paymentStatus == 50 ?
                        "AWAITING_PAYMENT" : "CANCELLED"
              }}
            </b-button>
          </template>
          <template #cell(trackingNumber)="data">
            <b-button style="margin-left: 10px" variant=".btn-flat-dark">
              {{ data.item.trackingNumber }}
            </b-button>
          </template>
          <template #cell(orderTotal)="data">
            <b-button style="margin-left: 10px" variant=".btn-flat-dark">
              {{ data.item.cartTotal }}
            </b-button>
          </template>
          <template #cell(actions)="data">
            <b-button variant=".btn-flat-dark" :to="{
              name: 'orderDetails',
              params: {
                id: data.item.id,
              },
            }">
              <external-link-icon size="1.5x" class="custom-class text-primary"></external-link-icon>
            </b-button>
          </template>
        </b-table>
      </template>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">Showing {{ selectedRecords }} to {{ skip + 10 }} of
              {{ rows }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination :disabled="loading" v-model="currentPage" :total-rows="rows" :per-page="perPage" first-number
              last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- <b-card v-else class="noProducts">
      <h3>No Order Found</h3>
    </b-card> -->
  </div>
</template>

<script>
import {
  BTable,
  BCol,
  BRow,
  BFormInput,
  BPagination,
  BBadge,
  BButton,
  BCard,
  BMedia,
  BAvatar,
  BLink,
  BFormSelect,
} from "bootstrap-vue";
import { ExternalLinkIcon } from "vue-feather-icons";
import { getCall } from "../Service";
import Loader from "../components/Loader.vue";
import { ref, watch, computed } from "@vue/composition-api";

export default {
  components: {
    BTable,
    BCol,
    BPagination,
    BLink,
    BBadge,
    BAvatar,
    BMedia,
    BRow,
    BFormInput,
    BButton,
    BCard,
    BFormSelect,
    ExternalLinkIcon,
    Loader,
  },

  data() {
    return {
      selectedCategory: null,
      skip: 0,
      rows: 0,
      pageSeries: [],
      selectedRecords: 1,
      searchQuery: "",
      perPage: 10,
      currentPage: 1,
      orders: [],
      fields: [
        { key: "id", sortable: true },
        { key: "orderNumber", sortable: true },
        "orderStatus",
        "trackingNumber",
        // 'trackingStatus',
        "orderTotal",
        "paymentStatus",
        { key: "customerName", sortable: true },
        "update/editShipment",
        "actions",
      ],
      orderStatusDict: {
        0: "AWAITING_PROCESSING",
        1: "PROCESSING",
        10: "READY_TO_SHIP",
        20: "SHIPPED",
        100: "DELIVERED",
        200: "WILL_NOT_DELIVER",
        400: "RETURNED",
        2: "OUT_FOR_DELIVERY",
      },
      loading: false,
    };
  },
  updated() {
    //console.log("tss", this.currentPage, this.rows);
  },
  watch: {
    currentPage: function (newVal, old) {
      const series = this.pageSeries.find((i) => i.p == newVal);
      this.selectedRecords = series.s;
      this.fetchAllOrders(series.s, this.perPage, "");
    },
    rows: function () {
      //console.log('rowsUpdated',this.rows);
      let pages = this.rows / 10;
      if (pages != 0) {
        if (this.rows > 10) {
          pages = pages + 1;
        }
      }
      this.pageSeries = [];
      this.skip = 0;
      for (let i = 1; i <= pages; i++) {
        this.pageSeries.push({ s: this.skip, p: i });
        this.skip = this.skip + 10;
      }
    },
    searchQuery: async function (newVal) {
      await this.fetchAllOrders(0, this.perPage, newVal ? newVal : "");
    },
  },

  methods: {
    resolveUserStatusVariant(status) {
      if (status === 200) return "warning";
      if (status === 100) return "success";
      if (status === 1) return "secondary";
      return "primary";
    },
    async fetchAllOrders(skip, take, search) {
      this.loading = true;
      try {
        const { data } = await getCall(
          `admin/store/orders/all?skip=${skip}&take=${take}&keyword=${search}`
        );
        const allOrders = data.allOrdersitems;
        this.orders = allOrders;
        this.rows = data.total;
        totalUsers.value = data.allOrdersitems.length;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
  setup() {
    const refUserListTable = ref(null);
    const perPagex = ref(10);
    const totalUsersx = ref(0);
    const currentPagex = ref(1);
    const dataMeta = computed(function () {
      const localItemsCount = refUserListTable.value
        ? refUserListTable.value.localItems.length
        : 0;
      return {
        from:
          perPagex.value * (currentPagex.value - 1) + (localItemsCount ? 1 : 0),
        to: perPagex.value * (currentPagex.value - 1) + localItemsCount,
        of: totalUsersx.value,
      };
    });

    return {
      refUserListTable,
      dataMeta,
      perPagex,
      currentPagex,
    };
  },

  async mounted() {
    await this.fetchAllOrders(0, this.perPage, "");
  },
};
</script>

<style>
.noProducts {
  text-align: center;
}
</style>
