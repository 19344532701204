import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList(courseid) {
  //console.log("initial course Id " + courseid);
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'createdAt', class: "cols-1", label: "Registration Date", sortable: true },
    { key: 'photo', class: "col-6", label: "Participant", sortable: false },
    { key: 'detail', class: "col-3", label: "Course", sortable: false },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const courseName= ref('')
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const courseId = ref(courseid)
  const items = ref(null)
  
  const dataMeta = computed(() => {
    const localItemsCount = items.value ? items.value.length : 0
    var from = perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0)
    return {
      from: from,
      to: (from + perPage.value) > totalUsers.value  ? totalUsers.value + 1: from + perPage.value,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
  
     refUserListTable.value.refresh()
  }

  watch([], () => {
    refetchData()
  })


  const fetchCourseSubscribers = (ctx, callback) => {
    store
      .dispatch('apps-participants/fetchParticipantsByCourse',
        courseId.value
      )
      .then(response => {
        const { data, total } = response.data
        //console.log("some from course subscribers resposne",data);
        callback(data)
        if (data.length > 0) {
          courseName.value = data[0].coursename
          totalUsers.value = data.length
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
 
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 0) return 'warning'
    if (status === 1) return 'success'
    if (status === 2) return 'secondary'
    return 'primary'
  }
  const resolveUserStatusVariantText = status => {
    if (status === 0) return 'deleted'
    if (status === 1) return 'active'
    if (status === 2) return 'disabled'
    return 'primary'
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    courseName,
    items,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserStatusVariantText,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    fetchCourseSubscribers,

    courseId,
  }
}
