<template>
  <div>
    <training-list-add-new :is-add-new-training-sidebar-active.sync="isAddNewTrainingSidebarActive"
      @refetch-data="refetchData" />


    <b-card title="">
      <!-- input search -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center justify-content-end">
            <label class="mr-1">Search</label>
            <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block mr-1" />
            <b-button variant="primary" @click="isAddNewTrainingSidebarActive = true">
              <span class="text-nowrap">Add Training</span>
            </b-button>
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table :columns="columns" :rows="rows" :line-numbers="true" :sort-options="{
        enabled: true,
        initialSortBy: { field: 'id', type: 'desc' }
      }" :search-options="{
                      enabled: true,
                      externalQuery: searchTerm
                    }" :pagination-options="{
          enabled: true,
          perPage: pageLength
        }">
        <template slot="table-row" slot-scope="props">

          <!-- Column: Name -->
          <div v-if="props.column.field === 'fullName'" class="text-nowrap">
            <b-avatar :src="props.row.avatar" class="mx-1" />
            <span class="text-nowrap">{{ props.row.fullName }}</span>
          </div>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                </template>
                <b-dropdown-item :to="{ name: 'training-edit', params: { id: props.row.id } }">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>{{ $t("Edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item id="toggle-btn" @click="cloneRow(props.row)" v-b-modal.modal-login>
                  <feather-icon icon="CopyIcon" class="mr-50" />
                  <span>Clone</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteData(props.row.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select v-model="pageLength" :options="['3', '5', '10']" class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
              <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

      <!--clone modal-->
      <b-modal id="modal-login" ref="modal-login" cancel-variant="outline-secondary" ok-title="Clone"
        cancel-title="Close" centered title="Clone Training" @ok="handleOk">
        <b-form>
          <div v-if="selectedRow" class="mt-1">
            <h6 class="mb-25">
              {{ selectedRow.name }}
            </h6>
            <b-card-text class="mb-25">
              Trainer: {{ selectedRow.trainer }}
            </b-card-text>
            <b-card-text class="mb-25">
              Trainer: {{ selectedRow.description }}
            </b-card-text>
            <b-card-text class="mb-25">
              Training Type: {{ selectedRow.trainingType.name }}
            </b-card-text>
            <b-card-text class="mb-25">
              Room: {{ selectedRow.room }}
            </b-card-text>


          </div>

          <hr>
          <b-form-group label="Start Date" label-for="startDate">

            <flat-pickr id="startDate" v-model="cloneData.startDate" class="form-control"
              :config="{ enableTime: true, minuteIncrement: 1 }" />
          </b-form-group>
          <b-form-group label="End Date" label-for="endDate">

            <flat-pickr id="endDate" v-model="cloneData.endDate" class="form-control"
              :config="{ enableTime: true, minuteIncrement: 1 }" />
          </b-form-group>
        </b-form>
      </b-modal>
    </b-card>

  </div>


</template>

<script>
import {
  VBModal, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BSidebar, BFormSelect
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import TrainingListAddNew from '@/views/hr/orientation/training/TrainingListAddNew'
import store from '@/store'
import orientationStoreModule from '@/views/hr/orientation/orientationStoreModule'
import { onUnmounted, onMounted, ref } from '@vue/composition-api'
import useTrainingsList from '@/views/hr/orientation/training/useTrainingsList'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'trainings-page',
  components: {
    flatPickr,
    VBModal, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BSidebar, BFormSelect,
    VueGoodTable,
    TrainingListAddNew
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Id',
          field: 'id',
          type: 'number',
          hidden: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Search Id',
          },
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Desc.',
          },
        },
        {
          label: 'Trainer',
          field: 'trainer',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Trainer',
          },
        },
        {
          label: 'Start Date',
          field: 'startDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'dd.MM.yyyy HH:mm:ss',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date',
          },
        },
        {
          label: 'End Date',
          field: 'endDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss',
          dateOutputFormat: 'dd.MM.yyyy HH:mm:ss',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date',
          },
        },
        {
          label: 'Training Type',
          field: 'trainingType.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Type',
          },

        },
        {
          label: 'Room',
          field: 'room',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Room',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      selectedRow: null,
      cloneData: {
        startDate: new Date(),
        endDate: new Date()
      }
    }
  },
  methods: {
    cloneRow(row) {
      this.selectedRow = row
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()

      // Trigger submit handler
      this.$store.dispatch('cloneTraining', {
        id: this.selectedRow.id,
        startDate: this.cloneData.startDate,
        endDate: this.cloneData.endDate
      })
        .then(response => {
          this.refetchData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success clone training.',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(reject => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error clone training.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.$refs['modal-login'].hide()
        })

    },

  },
  setup() {
    const TRAINING_APP_STORE_MODULE = 'hr-training'
    const toast = useToast()
    // var selectedRow = ref(null)
    const test = function () {
      //console.log('testfunc')
    }
    // const cloneRow = function(row) {
    //   selectedRow = row
    //   //console.log(selectedRow)
    // }

    // Register module
    if (!store.hasModule(TRAINING_APP_STORE_MODULE)) store.registerModule(TRAINING_APP_STORE_MODULE, orientationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRAINING_APP_STORE_MODULE)) store.unregisterModule(TRAINING_APP_STORE_MODULE)
    })

    onMounted(() => {
      refetchData()
    })
    const isAddNewTrainingSidebarActive = ref(false)

    const rows = ref([])
    const refetchData = function () {

      store.dispatch('fetchTrainings')
        .then(response => {
          rows.value = response.data
        })
        .catch(reject => {
          //console.log('error fetcing')
          rows.value = []
        })
    }

    const deleteData = function (id) {

      this.$swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('deleteTraining', { id })
              .then(response => {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  timer: 2000,
                  text: 'Your file has been deleted.',
                  // customClass: {
                  //   confirmButton: 'btn btn-success',
                  // },
                })
                refetchData()
              })
              .catch(error => {
                this.$swal({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'Training could not been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
              })

          }
        })

    }

    return {
      isAddNewTrainingSidebarActive,
      rows,
      refetchData,
      deleteData,
      test,
      // selectedRow,
      // cloneRow

    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.flatpickr-calendar {
  overflow: hidden;
  display: none;
}
</style>
