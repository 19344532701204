import { ref, watch, computed, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
export const useRedirectionRoute = () => {
    const getRedirectionRoute = computed(() => store.getters["app/getRedirectionRoute"])
    const redirectionRoute = ref(store.getters["app/getRedirectionRoute"])
    watch(getRedirectionRoute, newRoute => {
        redirectionRoute.value = newRoute
    }, { deep: true })
    const isRedirectionRouteActive = computed(() => store.getters["app/getIsRedirectionRouteActive"])

    function goToRedirectionPath() {
        store.dispatch("app/setIsRedirectionRouteActive", false).then(e => {
            router.replace(redirectionRoute.value)
        })
    }
    onUnmounted(()=>{
        store.dispatch("app/setIsRedirectionRouteActive", false)
    })
    return {
        isRedirectionRouteActive,
        redirectionRoute,
        goToRedirectionPath,
    }
}

export const _ = null
