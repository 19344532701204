<template>
  <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left">
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{$t('General')}}</span>
      </template>

      <course-setting-general v-if="courseData" :courseId="courseData.data.id" :field-information="'general'"
        :general-data="courseData" />
    </b-tab>

    <b-tab v-if="lessontype == 'VIDEO_LESSON'">
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{$t('Video Information')}}</span>
      </template>

      <course-setting-video v-if="courseData" :courseId="courseData.data.id" :field-information="'general'"
        :general-data="courseData" />
    </b-tab>
    <b-tab v-if="lessontype == 'PDF_CONTENT'">
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{$t('Document Information')}}</span>
      </template>

      <course-setting-pdf v-if="courseData" title="Video Information" :id="courseData.data.id"
        :field-information="'pdfdocument'" :information-data="courseData.data.courseDescription"
        :courseId="courseData.data.id" :general-data="courseData" />
    </b-tab>

    <b-tab v-if="lessontype == 'TEXT_CONTENT'">
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{$t('Lesson Content')}}</span>
      </template>

      <course-info-content-info v-if="courseData" title="Lesson Content" :id="courseData.data.id"
        :courseId="courseData.data.id" :field-information="'courseDescription'" :general-data="courseData"
        :information-data="courseData.data.lessonContent" />
    </b-tab>

    <b-tab v-if="lessontype == 'IMAGE_CONTENT'">
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{$t('Image Content')}}</span>
      </template>

      <course-setting-image v-if="courseData" title="Image  Information" :id="courseData.data.id"
        :field-information="'imagecontent'" :information-data="courseData.data.assetUrl" :courseId="courseData.data.id"
        :general-data="courseData" />
    </b-tab>


    <b-tab>
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{$t('Resources')}}</span>
      </template>

      <course-setting-resources v-if="courseData" :courseId="courseData.data.id" :field-information="'general'"
        :general-data="courseData" />
    </b-tab>
  </b-tabs>


</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import courseStoreModule from '../lecturelessonsStoreModule'
import CourseSettingGeneral from './LectureLessonInfoGeneral.vue'
import CourseSettingVideo from './LectureLessonInfoVideo.vue'
import CourseSettingPdf from './LectureLessonPDFDocument.vue'
import CourseSettingImage from './LectureLessonImageDocument.vue'
import CourseSettingInformation from './LectureLessonInfoInformation.vue'
import CourseSettingNotification from './LectureLessonInfoNotification.vue'
import CourseInfoContentInfo from './LectureLessonInfoContentInfo.vue'
import CourseInfoCoursePreference from './LectureLessonInfoCoursePreference.vue'
import CourseInfoPricing from './LectureLessonInfoPricing.vue'
import CourseInfoCertification from './LectureLessonInfoCertification.vue'
import CourseSettingResources from './LectureLessonResources.vue'


export default {
  components: {
    BTabs,
    BTab,
    CourseSettingGeneral,
    CourseSettingPdf,
    CourseSettingImage,
    CourseSettingInformation,

    CourseSettingNotification,
    CourseInfoCoursePreference,
    CourseInfoPricing,
    CourseInfoCertification,
    CourseInfoContentInfo,
    CourseSettingResources,
    CourseSettingVideo

  },

  setup() {
    const courseData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-lesson-edit'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, courseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })


    const lessontype = computed(() => {
      return router.currentRoute.params.type
    });

    store.dispatch('app-lesson-edit/fetchLesson', { id: router.currentRoute.params.id })
      .then(response => {
        //    var course=JSON.parse(JSON.stringify(response.data));
        //  const { course  } = response.data
        courseData.value = response.data;
        //console.log("courses assigned to course data");
        //console.log(response.data);


      })
      .catch(error => {
        if (error.response.status === 404) {
          courseData.value = undefined
        }
      })

    return {
      courseData,
      lessontype
    }
  },
  data() {
    return {
      courseInfo: JSON.parse(JSON.stringify(this.courseData)),
    }
  },

  beforeCreate() {

    // this.courseInfo=this.courseData;





    return;
  },
}
</script>
