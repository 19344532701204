<template>
    <b-sidebar id="is-updated-sidebar-active" :visible="isUpdatedSidebarActive" bg-variant="white"
        sidebar-class="sidebar-lg" shadow backdrop no-header right
        @change="(val) => $emit('update:is-updated-sidebar-active', val)">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    {{ $t('Update Exam') }}
                </h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

            </div>

            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(handleQuestionSubmit)">

                    <!-- Full Name -->
                    <validation-provider #default="validationContext" name="Exam Title" rules="required">
                        <b-form-group :label="$t('Exam Title')" label-for="full-name">
                            <b-form-input id="full-name" v-model="localExam.title" autofocus trim placeholder="Quiz"
                                :state="localExam.title != ''" />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Username -->

                    <validation-provider #default="validationContext" name="examType" rules="required">

                        <b-form-group :label="$t('Exam Type')" label-for="username">
                            <v-select v-model="localExam.examType" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="title" :options="typeoptions" />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <!-- Username -->
                    <validation-provider #default="validationContext" name="Description" rules="required">
                        <b-form-group :label="$t('Description')" label-for="username">
                            <b-form-textarea id="username" v-model="localExam.description" trim />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <b-row>
                        <b-col md="12">

                            <validation-provider #default="validationContext" name="Duration" rules="required">
                                <b-form-group :label="$t('Duration')" label-for="username">

                                    <b-form-checkbox v-model="localExam.hasExactOccurDate" value="A"
                                        class="custom-control-primary">
                                        {{ $t('Exam has exact occur date') }}
                                    </b-form-checkbox>

                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>

                    <b-row v-if="localExam.hasExactOccurDate">
                        <b-col md="7">

                            <validation-provider #default="validationContext" name="Duration">
                                <b-form-group :label="$t('Start Date')" label-for="username">

                                    <b-form-datepicker id="datepicker-invalid" v-model="localExam.examStartDate"
                                        :state="localExam.hasExactOccurDate && (localExam.examStartDate != null )"
                                        class="mb-2" />

                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="5">

                            <validation-provider #default="validationContext" name="Duration">
                                <b-form-group :label="$t('Time')" label-for="username">

                                    <b-form-timepicker id="datepicker-time" v-model="localExam.examStartTime"
                                        :state="localExam.hasExactOccurDate && (localExam.examStartTime != null)"
                                        class="mb-2" />

                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                  
                    <b-row>
                        <b-col md="4">

                            <validation-provider #default="validationContext" name="Duration" rules="required">
                                <b-form-group :label="$t('Duration')" label-for="username">

                                    <b-form-input id="email" v-model="localExam.examDuration"
                                        placeholder="Duration in minutes" trim type="number"
                                        :state="localExam.examDuration != ''" />

                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="4">
                            <!-- Email -->
                            <validation-provider #default="validationContext" name="passLevel" rules="required">
                                <b-form-group :label="$t('Exam Pass Level')" label-for="email">
                                    <b-form-input id="email" v-model="localExam.passLevel" trim type="number"
                                        :state="localExam.passLevel != ''" />

                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="4">
                            <validation-provider #default="validationContext" name="Max Attempt Count" rules="required">
                                <b-form-group :label="$t('Max Attempt')" label-for="maxAttemptCount">
                                    <b-form-input id="maxAttemptCount" v-model="localExam.maxAttemptCount" trim
                                        type="number" :state="localExam.maxAttemptCount != ''" />

                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <!-- Company -->
                    <div v-if="localExam.examType === 'INPERSON'">


                        <validation-provider #default="validationContext" name="examLocation" rules="required">
                            <b-form-group :label="$t('Exam Location')" label-for="examLocation">
                                <b-form-input id="examLocation" v-model="localExam.examLocation" trim />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <b-row>
                            <b-col md="6">

                                <validation-provider #default="validationContext" name="examLocationRoom"
                                    rules="required">
                                    <b-form-group :label="$t('Room')" label-for="examLocationRoom">
                                        <b-form-input id="examLocationRoom" v-model="localExam.examLocationRoom" trim />

                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>

                            </b-col>
                            <b-col md="6">
                                <validation-provider #default="validationContext" name="examLocationSeat"
                                    rules="required">
                                    <b-form-group :label="$t('Seat')" label-for="examLocationSeat">
                                        <b-form-input id="examLocationSeat" v-model="localExam.examLocationSeat" trim />

                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>

                            </b-col>
                        </b-row>

                    </div>
                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
                            type="submit">
                            {{ $t("Add") }}
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                            @click="hide">
                            {{ $t("Cancel") }}
                        </b-button>
                    </div>

                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
    BButton, BFormTextarea, BRow, BCol, BFormCheckbox, BFormTimepicker, BFormDatepicker
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
    components: {
        BRow, BCol, BFormCheckbox, BFormTimepicker, BFormDatepicker,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        vSelect,
        BFormTextarea,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: 'isUpdatedSidebarActive',
        event: 'update:is-updated-sidebar-active',
    },
    methods: {
        handleQuestionSubmit() {
            store.dispatch('apps-exam/createExam', this.localExam)
                .then(() => {
                    this.selectedCategory = null;
                    this.localExam = {
                        id: null,
                        hasExactOccurDate: false,
                        title: '',
                        examType: "ONLINE",
                        description: '',
                        examDuration: 120,
                        passLevel: 70,
                        maxAttemptCount: 0,
                        examLocation: '',
                        examLocationRoom: '',
                        examLocationSeat: '',
                    };
                    this.$emit('refetch-data')
                    this.$emit('update:is-updated-sidebar-active', false)
                })
        },

    },
    props: {
        isUpdatedSidebarActive: {
            type: Boolean,
            required: true,
        },
        roleOptions: {
            type: Array,
            required: true,
        },
        planOptions: {
            type: Array,
            required: true,
        },
        activeExam: null
    },
    watch: {
        activeExam(newVal, oldVal) {
            if (newVal) {
                this.localExam = newVal;
            }
        }
    },
    data() {
        return {
            required,
            alphaNum,
            email,
            countries,
            localExam: {
                hasExactOccurDate: false,
                title: '',
                examType: "ONLINE",
                description: '',
                examDuration: 120,
                passLevel: 70,
                maxAttemptCount: 0,
                examLocation: '',
                examLocationRoom: '',
                examLocationSeat: '',
                examStartDate: null,
                examStartTime: null,
            },
            typeoptions: [
                "ONLINE",
                "INPERSON"
            ]
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>