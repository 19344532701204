import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchGroups(ctx, queryParams) {
      //console.log("fetchCategories");
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/f/cm/getgroups', { params: queryParams })
          .then((response) => {
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addGroup(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/f/cm/creategroup', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroupMembers(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios.get("admin/f/cm/groupmembers/" + id)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    }
  },
}
