<template>
  <b-card>
    <h2 class="text-primary mb-2">Lesson Resources</h2>
    <!-- media -->

    <h6 class="section-label mt-2   mb-2">Add Resource</h6>
    <b-media no-body>
      <b-media-aside>
        <b-col sm="12">
          <b-form-group label="Resource Title" label-for="course-title">
            <b-form-input v-model="assetInfo.title" placeholder="Resource Title" name="title" />
          </b-form-group>
        </b-col>
        <!--/ avatar -->
      </b-media-aside>
      <div v-if="Boolean(assetInfo.title)">
        <b-media-body class="mt-50 ml-50">
          <!-- upload button -->
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
            :state="Boolean(file)" v-model="file" @click="$refs.refInputEl.$el.click()">
            Upload
          </b-button>
          <b-form-file ref="refInputEl" v-model="profileFile"
            accept=".jpg, .png, .gif,.zip , .doc , .ppt, .pptx , .docx , .rar, .xls , .xlsx" :hidden="true" plain
            @input="fileSelected" />
          <!--/ upload button -->

          <!-- reset -->

          <!--/ reset -->
          <b-card-text>{{ $t("Allowed JPG, GIF or PNG") }}</b-card-text>
        </b-media-body>


      </div>

    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>





        <b-col cols="12">
          <b-table responsive :items="optionsLocal.assets" :fields="fields" class="mb-0">


            <template #cell(assetUrl)="data">
              <b-link :href="data.item.assetUrl" class="font-weight-bold d-block text-nowrap" target="_blank">
                Download
              </b-link>
            </template>

            <template #cell(createdAt)="data">
              <small>
                {{ moment(data.item.createdAt).format('Do MMMM YYYY, hh:MM ')
                }}
              </small>
            </template>
            <template #cell(actions)="data">



              <b-button variant="primary" @click="deleteFile(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t("Delete") }}</span>

              </b-button>

            </template>


          </b-table>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BBadge,
  BTable
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import CategorySelector from "../../course-category/components/CategorySelector.vue";
import AuthorSelector from "../../instructor/AuthorSelector.vue";
import moment from 'moment'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    CategorySelector,
    AuthorSelector,
    BBadge,
    BTable
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => { },
    },
    courseId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localOptions: {},
      isLoading: false,
      file: null,
      isUpdated: false,
      assetInfo: {
        title: null,
        assetUrl: null,
        order: 0,
        lessonid: 0

      },
      optionsLocal: JSON.parse(JSON.stringify(this.generalData.data)),
      profileFile: null,
      fields: [
        'id',
        'title',

        'assetUrl',

        'createdAt',
        'actions'
      ],

    };
  },
  methods: {
    moment: function () {
      return moment();
    },
    resetFile() {
      this.file = null;
      this.profileFile = null;
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },
    sendInformation() {
      //console.log(this.assetInfo);
      //console.log("update lesson  is launching with id : " + this.courseId);
      //console.log(this.courseId);
      this.assetInfo.lessonid = this.optionsLocal.id;


      this.isLoading = true;
      axios({
        method: "post",
        url: `/lms/admin/course/createlessonasset`,
        data: {
          info: "general",
          file: null,
          content: this.assetInfo,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          //console.log(r);

          this.optionsLocal.assets.push(r.data.data);
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
    deleteFile(item) {

      var id = item.id;

      //console.log(id);


      //console.log("update lesson  is launching with id : " + this.courseId);
      //console.log(this.courseId);
      this.assetInfo.lessonid = this.optionsLocal.id;


      this.isLoading = true;
      axios({
        method: "post",
        url: `/lms/admin/course/deletelessonasset`,
        data: {
          info: "deleteasset",

          content: id,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          //console.log(r);

          var index = this.optionsLocal.assets.map(function (e) { return e.id; }).indexOf(id);

          this.optionsLocal.assets.splice(index, 1);

          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },

    fileSelected(payload) {
      const formData = new FormData();
      formData.append("file", payload);
      formData.append("courseId", this.courseId);
      formData.append("lessonId", this.courseId);
      formData.append("acc", 3);
      formData.append("platform", "webadmin");
      this.isLoading = true;

      axios
        .post("/upload/admin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response);



          this.assetInfo.assetUrl = response.data.url;
          this.isUpdated = true;
          this.isLoading = false;
          this.sendInformation();
          this.$bvToast.toast(`${response.data.message}`, {
            title: "Success",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          //console.log(error);
          this.isLoading = false;
          this.$bvToast.toast(`${error.response.data.message}`, {
            title: "Error",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "danger",
            solid: true,
          });
        });
    },





    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
    },

    uploadFile(paylod) {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
    },
  },
  mounted() {
    //console.log(this.generalData.data);

  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
