<template>
  <div>
    <b-form @submit.prevent="onSubmit">
      <b-tabs
        v-model="tabIndex"
        vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
      >
        <b-tab active>
          <!-- title -->
          <template #title>
            <feather-icon icon="UserIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">General Info</span>
          </template>
          <b-card>
            <h3 class="text-black mb-2">General Info</h3>

            <b-row>
              <b-col cols="4">
                <b-form-group label="Title *" label-for="title" class="mb-2">
                  <b-form-input
                    name="title"
                    id="title"
                    v-model="product.title"
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col cols="4">
          <b-form-group
            label="Additional Info"
            label-for="additionalInfo"
            class="mb-2"
          >
            <b-form-input
              name="additionalInfo"
              id="additionalInfo"
              v-model="product.additionalInfo"
            />
          </b-form-group>
        </b-col> -->
              <b-col cols="4">
                <b-form-group
                  label="Sub Title"
                  label-for="subtitle"
                  class="mb-2"
                >
                  <b-form-input
                    name="subtitle"
                    id="subtitle"
                    v-model="product.subtitle"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <b-form-group
                  label="Name Translated"
                  label-for="nameTranslated"
                  class="mb-2"
                >
                  <b-form-input
                    name="nameTranslated"
                    id="nameTranslated"
                    v-model="product.nameTranslated"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="Short Description"
                  label-for="shortDescription"
                  class="mb-2"
                >
                  <b-form-input
                    name="shortDescription"
                    id="shortDescription"
                    v-model="product.shortDescription"
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col cols="4">
          <b-form-group label="Tax Info" label-for="taxInfo" class="mb-2">
            <b-form-input
              name="taxInfo"
              id="taxInfo"
              v-model="product.taxInfo"
            />
          </b-form-group>
        </b-col> -->
            </b-row>
            <b-row style="margin-bottom: 50px">
              <b-col cols="12">
                <quill-editor
                  style="height: 400px"
                  v-model="product.description"
                  @change="onEditorChange($event)"
                />
                <!-- <b-form-textarea
            id="textarea"
            v-model="product.description"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
          >
          </b-form-textarea> -->
              </b-col>
            </b-row>
            <b-row>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-row
                  style="display: flex; flex-direction: column"
                  class="ml-1 mt-2"
                >
                  <b-col>
                    <div style="display: flex; flex-direction: column">
                      <b-form-text>Featured Image </b-form-text>
                      <b-img
                        v-if="product.featuredImage"
                        ref="refPreviewEl"
                        :src="product.featuredImage"
                        height="130"
                        width="170"
                        class="rounded mr-2 mb-1 mb-md-0"
                      />
                    </div>
                  </b-col>
                  <b-col>
                    <b-media-body>
                      <small class="text-muted"
                        >Required image resolution 800x400, image size
                        2mb.</small
                      >
                      <b-card-text class="my-50"
                        >Selected File:
                        {{
                          product.featuredImage
                            ? product.featuredImage.name
                            : ''
                        }}
                      </b-card-text>
                      <div class="d-inline-block">
                        <b-form-file
                          v-model="product.featuredImage"
                          accept=".jpg, .png, .gif"
                          placeholder="Choose file"
                          @change="addFeaturedImage"
                        />
                      </div>
                    </b-media-body>
                  </b-col>
                </b-row>
              </b-media>
            </b-row>
          </b-card>
        </b-tab>
        <b-tab :disabled="product.title ? false : true">
          <!-- title -->
          <template #title>
            <feather-icon icon="UserIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Product {{ $t('Details') }}</span>
          </template>
          <b-card>
            <h3 class="text-black mb-2">Product Details</h3>

            <b-row>
              <b-col cols="3">
                <b-form-group label="Stock" label-for="stock" class="mb-2">
                  <b-form-input
                    type="number"
                    name="stock"
                    id="stock"
                    v-model="product.stock"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group label="SKU * " label-for="sku" class="mb-2">
                  <b-form-input
                    type="text"
                    name="sku"
                    id="sku"
                    v-model="product.sku"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3">
              <b-form-group
              label="Alarm Stock"
              label-for="alarmStock"
              class="mb-2"
              >
              <b-form-input
              name="alarmStock"
              id="alarmStock"
              v-model="product.alarmStock"
              />
              </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group label="Unit" label-for="unit" class="mb-2">
                  <b-form-input name="unit" id="unit" v-model="product.unit" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3">
                <b-form-group
                  label="List Price * "
                  label-for="listPrice"
                  class="mb-2"
                >
                  <b-form-input
                    name="listPrice"
                    id="listPrice"
                    v-model="product.listPrice"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group label="In Stock" label-for="inStock" class="mb-2">
                  <b-form-checkbox
                    id="inStock"
                    :checked="product.inStock"
                    name="in-stock"
                    v-model="product.inStock"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group
                  label="Cost Price * "
                  label-for="costPrice"
                  class="mb-2"
                >
                  <b-form-input
                    name="costPrice"
                    id="costPrice"
                    v-model="product.costPrice"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group label="Weight" label-for="weight" class="mb-2">
                  <b-form-input
                    name="weight"
                    id="weight"
                    v-model="product.weight"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"
                ><b-form-group
                  label="Unlimited"
                  label-for="unlimited"
                  class="mb-2"
                >
                  <!-- <b-form-input
              name="unlimited"
              id="unlimited"
              v-model="product.unlimited"
            /> -->
                  <b-form-checkbox
                    id="unlimited"
                    :checked="product.unlimited"
                    name="unlimited"
                    v-model="product.unlimited"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="3"
                ><b-form-group
                  label="Is Shipping Required"
                  label-for="isShippingRequired"
                  class="mb-2"
                >
                  <b-form-checkbox
                    id="isShippingRequired"
                    :checked="product.isShippingRequired"
                    name="is-shipping-required"
                    v-model="product.isShippingRequired"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group
                  label="Fixed Shipping Rate Only"
                  label-for="fixedShippingRateOnly"
                  class="mb-2"
                >
                  <b-form-checkbox
                    id="fixedShippingRateOnly"
                    :checked="product.fixedShippingRateOnly"
                    name="fixed-shipping-rate-only"
                    v-model="product.fixedShippingRateOnly"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group
                  label="Fixed Shipping Rate"
                  label-for="fixedShippingRate"
                  class="mb-2"
                >
                  <b-form-input
                    name="fixedShippingRate"
                    id="fixedShippingRate"
                    v-model="product.fixedShippingRate"
                  /> </b-form-group
              >
              </b-col>
              <b-col cols="3">
                <b-form-group
                  label="Slug"
                  label-for="slug"
                  class="mb-2"
                >
                <b-form-input
                  disabled
                  name="slug"
                  id="slug"
                  v-model="product.slug"
                /> </b-form-group
              >
              </b-col>
            </b-row>
          </b-card>
        </b-tab>
        <b-tab :disabled="product.title && product.sku ? false : true">
          <!-- title -->
          <template #title>
            <feather-icon icon="UserIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Additional Info</span>
          </template>
          <b-card>
            <h3 class="text-black mb-2">Additional Info</h3>
            <b-row>
              <b-col cols="4">
                <b-form-group
                  label="Show On Front Page"
                  label-for="showOnFrontPage"
                  class="mb-2"
                >
                  <!-- <b-form-select
              v-model="product.showOnFrontPage"
              :options="showOnFrontpage"
            ></b-form-select> -->
                  <b-form-checkbox
                    id="showOnFrontPage"
                    :checked="product.showOnFrontPage"
                    name="show-on-front-page"
                    v-model="product.showOnFrontPage"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="4"
                ><b-form-group
                  label="Is Sample Product"
                  label-for="isSampleProduct"
                  class="mb-2"
                >
                  <b-form-checkbox
                    id="isSampleProduct"
                    :checked="product.isSampleProduct"
                    name="is-sample-product"
                    v-model="product.isSampleProduct"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="Discount Allowed"
                  label-for="discounts-allowed"
                  class="mb-2"
                >
                  <b-form-checkbox
                    id="discountAllowed"
                    :checked="product.discountsAllowed"
                    name="discount-allowed"
                    v-model="product.discountsAllowed"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4"
                ><b-form-group
                  label="Product Status *"
                  label-for="productStatus"
                  class="mb-2"
                >
                  <b-form-select
                    id="input-3"
                    v-model="product.productStatus"
                    :options="options"
                  ></b-form-select> </b-form-group
              ></b-col>
              <b-col cols="4">
                <b-form-group
                  label="Product Type *"
                  label-for="productType *"
                  class="mb-2"
                >
                  <b-form-select
                    id="input-3"
                    v-model="product.productType"
                    :options="productTypes"
                  ></b-form-select> </b-form-group
              ></b-col>
              <b-col cols="4">
                <b-form-group
                  label="Product Categories"
                  label-for="product-category"
                >
                  <product-category-selector
                    @selectionChanged="handleSelectionCategory"
                    :selected="product.defaultCategoryId"
                    :categories="categories"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="Product Brands"
                  label-for="product-brands"
                >
                  <brand-selector
                  @selectionChanged="handleBrandSelection"
                  :selected="product.brandId"
                  />

                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="Product Models"
                  label-for="product-models"
                >
                  
                  <model-selector
                  @selectionChanged="handleModelSelection"
                  :selected="product.modelId"
                  :models="models"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="Product Tags"
                  label-for="product-tags"
                >
                  
                  <tag-selector
                  @selectionChanged="handleProductTagSelection"
                  :selected="product.defaultTagId"
                  :models="tags"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>

        <b-tab :disabled="product.title && product.sku ? false : true">
          <template #title>
            <feather-icon icon="UserIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Gallery</span>
          </template>
          <b-card>
            <h3 class="text-black mb-2">Gallery</h3>
            <b-row>
              <div
                v-for="(item, index) in product.images"
                :key="index"
                :v-if="product.images && product.images.length"
                style="position: relative; margin: 1rem 0.5rem"
              >
                <feather-icon
                  @click="() => removeGalleryImage(index)"
                  style="position: absolute; top: -1.6rem;"
                  icon="TrashIcon"
                  size="22"
                  class="mr-50 text-danger text-bold"
                />
                <b-img
                  ref="refPreviewEl"
                  :src="item"
                  height="130"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </div>
            </b-row>
            <b-row>
              <b-col cols="3">
                <b-media-body class="d-flex flex-column">
                  <small class="text-muted"
                    >Required image resolution 800x400, image size 2mb.</small
                  >
                  <div class="d-inline-block">
                    <b-form-file
                      ref="file-input"
                      :disabled="disableUpload"
                      accept=".jpg, .png, .gif"
                      placeholder="Choose file"
                      @change="uploadGalleryImages"
                    />
                  </div>
                </b-media-body>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>
        <!-- <b-tab>
          
          <template #title>
            <feather-icon icon="UserIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Media Info</span>
          </template>
          <b-card>
            <h3 class="text-black mb-2">Media Info</h3>

           
          </b-card>
        </b-tab> -->
      </b-tabs>
      <!-- <div
      style="border-radius: 10px 10px 0 0; padding: 1rem 0.5rem"
      class="bg-primary"
    >
      <h3 class="text-white">General Info</h3>
    </div> -->

      <!-- Product Pricing -->
      <!-- <div
      style="border-radius: 10px 10px 0 0; padding: 1rem 0.5rem"
      class="bg-primary"
    >
      <h3 class="text-white">Product Details</h3>
    </div> -->

      <b-col
        style="align-self: flex-end; display: flex; justify-content: flex-end"
        class="mt-50"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :variant="!disabled ? 'primary' : 'secondary'"
          class="mr-1"
          type="submit"
        >
          {{
            this.$route.params.id || (this.tabIndex == 2 || this.tabIndex==3)
              ? 'Save Changes'
              : 'Next'
          }}
        </b-button>
        
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          type="reset"
          to="/product-list"
        >
          Cancel
        </b-button>
      </b-col>
    </b-form>
  </div>
</template>

<script>
import { getCall, postCall, putCall } from '../Service';
import axios from 'axios';
import ProductCategorySelector from './components/ProductCategorySelector.vue';
import BrandSelector from './components/BrandSelector.vue';
import ModelSelector from './components/ModelSelector.vue';
import TagSelector from './components/TagSelector.vue';
import 'vue-select/dist/vue-select.css';
import { BTabs, BTab } from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BCard,
  BCol,
  BRow,
  
  BLink,
  BImg,
  BMedia,
  BButton,
  BFormFile,
  BCardText,
  BMediaAside,
  BMediaBody,
  BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue';
import Swal from 'sweetalert2';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    quillEditor,
    BCard,
    BrandSelector,
    BCol,
    BRow,
    BImg,
    BMedia,
    BLink,
    BButton,
    BFormFile,
    BTabs,
    BTab,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormSelect,
    BFormCheckbox,
    ProductCategorySelector,
    TagSelector,
    ModelSelector
  },
  data() {
    return {
      options: [],
      disableUpload: false,
      errors: [],
      productId:null,
      tabIndex: 0,
      disabled: false,
      categories: [],
      models:[],
      tags:[],
      // showOnFrontpage: [
      //   { value: 1, text: 'Yes' },
      //   { value: 0, text: 'No' },
      // ],
      productTypes: [],
      product: {
        title: null,
        description: '',
        featuredImage: '',
        shortDescription: '',
        stock: 1,
        alarmStock: 0,
        unit: '',
        listPrice: 0,
        sku: '',
        additionalInfo: '',
        unlimited: false,
        slug:'',
        inStock: false,
        nameTranslated: '',
        costPrice: 0,
        taxInfo: '',
        isShippingRequired: false,
        weight: 0,
        fixedShippingRateOnly: false,
        fixedShippingRate: false,
        showOnFrontPage: false,
        isSampleProduct: false,
        discountsAllowed: false,
        subtitle: '',
        productStatus: null,
        productType: null,
        defaultCategoryId: [],
        images: [],
        modelId:null,
        brandId:null,
        defaultTagId:[],
      },
    };
  },
  updated() {
    if (this.product.title && this.tabIndex == 0) {
      this.disabled = false;
    } else if (
      this.tabIndex == 1 &&
      this.product.sku &&
      this.product.listPrice >= 0 &&
      this.product.costPrice >= 0 &&
      this.product.title
    ) {
      this.disabled = false;
    } else if (
      this.tabIndex == 2 &&
      this.product.sku &&
      this.product.listPrice >= 0 &&
      this.product.costPrice >= 0 &&
      this.product.title &&
      (this.product.productStatus == 1 || this.product.productStatus == 0) &&
      this.product.productType
    ) {
      this.disabled = false;
    } else if (
      this.tabIndex == 3 &&
      (this.product.productStatus == 1 || this.product.productStatus == 0) &&
      this.product.productType &&
      this.product.title &&
      this.product.sku &&
      this.product.listPrice >= 0 &&
      this.product.costPrice >= 0
    ) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }

    //console.log('product', this.product.images);
  },
  methods: {
    async onSubmit(event) {
      //console.log('clicjed',this?.product);
      if ( (this.$route.params.id || this.productId )|| ((this.tabIndex == 2 || this.tabIndex==3) && !this.disabled)) {
        //   }
        if (this.productId || this.$route.params.id || this.tabIndex==3) {
          try {
            if (
              this.product.sku &&
              // this.product.productStatus && this.product.productTypes &&
              this.product.title &&
              this.product.listPrice >= 0 &&
              this.product.costPrice >= 0
            ) {
              
              await putCall(`admin/store/product/${this.$route.params.id ?this.$route.params.id  : this.productId }`, {
                ...this.product,
                defaultCategoryId: this.product.defaultCategoryId.map(
                  (i) => i.id
                ),
                defaultTagId:this.product.defaultTagId.map(i=>i.id),
                brandId:this.product.brandId!=null && this.product.brandId.id ? this.product.brandId.id : null ,
                modelId: this.product.modelId!=null && this.product.modelId.id ? this.product.modelId.id : null,
                // showOnFrontPage: this.product.showOnFrontPage == true ? 1 : 0,
                // isSampleProduct: this.product.isSampleProduct ? 1 : 0,
                // discountsAllowed: this.product.discountsAllowed ? 1 : 0,
                // isShippingRequired:  this.product.isShippingRequired  ? 1 : 0
              });
              await this.getSpecificId(this.$route.params.id);

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: ` ${this.tabIndex==3 ? 'Image added in gallery' : 'Product Updated Successfully'}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              });
            }
          } catch (error) {
            if (error.response) {
              Swal.fire('Error:', error.response.data.message, 'error');
            } else {
              Swal.fire('Error:', error, 'error');
            }
          }
        } else {
          try {
            const res = await postCall('admin/store/product/new', {
              ...this.product,
              defaultCategoryId: this.product.defaultCategoryId.map(
                (i) => i.id
              ),
              defaultTagId:this.product.defaultTagId.map(i=>i.id),
              brandId:this.product.brandId!=null && this.product.brandId.id ? this.product.brandId.id : null ,
                modelId:this.product.modelId!=null && this.product.modelId.id ? this.product.modelId.id : null,
              // brandId:this.product.brandId.id ? this.product.brandId.id : null ,
              // modelId:this.product.modelId.id ? this.product.modelId.id : null,
            });
            this.productId = res.data.data.id
            await this.getSpecificId(this.productId);
            this.$router.push({
              name: 'ProductEdit',
              params: {
                id: res.data.data.id,
              },
            })
             
            // if(this.tabIndex==3){
            // this.$router.push('/product-list');
            // }
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Product Added Successfully`,
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            });
          } catch (error) {
            if (error.response) {
              Swal.fire('Error:', error.response.data.message, 'error');
            } else {
              Swal.fire('Error:', error, 'error');
            }
          }
        }
      } else {
        
        if (this.tabIndex == 0 && this.product.title) {
          if (this.tabIndex < 3) {
            this.tabIndex++;
            //console.log('first');
          }
        } else if (
          this.tabIndex == 1 &&
          this.product.sku &&
          this.product.listPrice >= 0 &&
          this.product.costPrice >= 0
        ) {
          // if(this.tabIndex<3){
          this.tabIndex++;
          //console.log('second');
          // }
        } else if (
          this.tabIndex == 2 &&
          (this.product.productStatus == 0 ||
            this.product.productStatus == 1) &&
          this.product.productType
        ) {
          this.tabIndex++;
          //console.log('innss');
        } else {
          //console.log('confirm here');
        }
      }
    },
    async fetchTags() {
      const { data } = await getCall('admin/store/tag/all');
      const temp = data.data;
      temp.forEach((i) => {
        i.name = i.title;
      });
      this.tags = temp;
      if (this.$route.params.id) {
        const res = temp.filter((i) =>
          this.product.defaultTagId.find((x) => x == i.id)
        );
        //console.log('ssss', res);
        this.product.defaultTagId = res;
      }
    },
    async fetchCategories() {
      const { data } = await getCall('admin/store/category/all');
      const temp = data.data;
      temp.forEach((i) => {
        i.name = i.title;
      });
      this.categories = temp;
      if (this.$route.params.id) {
        const res = temp.filter((i) =>
          this.product.defaultCategoryId.find((x) => x == i.id)
        );
        //console.log('ssss', res);
        this.product.defaultCategoryId = res;
      }
    },
    async getModelByBrand(id) {
      try {
        const { data } = await getCall(`admin/store/models-by-brandid/${id}`);
        // this.selected=null
        this.models=[]
        this.models = data.models.map((i) => {
          return {
            id: i.id,
            name: i.title,
          };
        });

        return this.models
        // this.selected = this.models.filter((i) => i.id == this.selected.id);   
      } catch (error) {
      
      }
    },
    async getProductStatus() {
      try {
        const { data } = await getCall('admin/store/products/status');
        this.options = data.productStatus.map((el) => {
          return {
            value: el.id,
            text: el.title,
          };
        });
        if(!this.$route.params.id){
          this.product.productStatus = this.options[1].value
        }
        
      } catch (error) {
        if (error.response) {
          Swal.fire('Error', error.response.data.message, 'error');
        } else {
          Swal.fire('Error', error, 'error');
        }
      }
    },
    async uploadGalleryImages(item) {
      this.disableUpload = true;
      let formData = new FormData();
      formData.append('file', item.target.files[0]);
      try {
        var host=process.env.VUE_APP_API_URL;

        const response = await axios.post(
          host+'/upload/admin',
          formData,
          {
            headers: {
              'client-auth-token': localStorage.getItem('clientToken'),
              'content-type': 'multipart/form-data',
              'x-access-token': localStorage.getItem('accessToken'),
            },
          }
        );
        this.product.images.push(response.data.url);
        this.disableUpload = false;
        this.onSubmit()
      } catch (error) {
        if (error.response) {
          Swal.fire('Error', error.response.data.message, 'error');
          this.disableUpload = false;
        } else {
          Swal.fire('Error', error, 'error');
          this.disableUpload = false;
        }
      }
    },
    handleSelectionCategory(items) {
      this.product.defaultCategoryId = items;
    },
    handleProductTagSelection(items) {
      this.product.defaultTagId = items;
    },
    async handleBrandSelection(items) {
      //console.log('calue',items)
      if(items==null){
        this.product.modelId = null
      }
      this.product.brandId = items;
      const results = await this.getModelByBrand(items.id)
      //console.log('resss',results);
      if(this.$route.params.id && this.product.modelId && results){
      const temp = results;
      //console.log('--->',temp);
      const res = temp.filter(i=>i.id==this.product.modelId)
      this.product.modelId = res
      //console.log('000222',res);
      }
    },
    handleModelSelection(items) {
      this.product.modelId = items;
    },
    async getProductTypes() {
      try {
        const { data } = await getCall('admin/store/products/types');
        this.productTypes = data.productTypes.map((el) => {
          return {
            value: el.id,
            text: el.title,
          };
        });
        if(!this.$route.params.id){
        this.product.productType = this.productTypes[0].value
        }
      } catch (error) {
        if (error.response) {
          Swal.fire('Error', error.response.data.message, 'error');
        } else {
          Swal.fire('Error', error, 'error');
        }
      }
    },
    removeGalleryImage(index) {
      this.product.images.splice(index, 1);
      if(this.product.images.length==0){
        this.$refs['file-input'].reset()
      }
      this.onSubmit()
    },
    async getSpecificId(id) {
      try {
        const res = await getCall(`admin/store/product/${id}`);
        this.product = { ...res.data.data };
        this.product.discountsAllowed =
          res.data.data.discountsAllowed == 1 ? true : false;
        this.product.isSampleProduct =
          res.data.data.isSampleProduct == 1 ? true : false;
        this.product.isShippingRequired =
          res.data.data.isShippingRequired == 1 ? true : false;
        this.product.showOnFrontPage =
          res.data.data.showOnFrontPage == 1 ? true : false;
        this.product.unlimited = res.data.data.unlimited == 1 ? true : false;
        this.product.inStock = res.data.data.inStock == 1 ? true : false;
        this.product.fixedShippingRateOnly =
        res.data.data.fixedShippingRateOnly == 1 ? true : false;      
        this.fetchCategories();
      } catch (error) {
        if (error.response) {
          Swal.fire('Error', error.response.data.message, 'error');
        } else {
          Swal.fire('Error', error, 'error');
        }
      }
    },
    async addFeaturedImage(item) {
      let formData = new FormData();
      formData.append('file', item.target.files[0]);
      try {
        var host=process.env.VUE_APP_API_URL;

        const response = await axios.post(
          host+'/upload/admin',
          formData,
          {
            headers: {
              'client-auth-token': localStorage.getItem('clientToken'),
              'content-type': 'multipart/form-data',
              'x-access-token': localStorage.getItem('accessToken'),
            },
          }
        );
        this.product.featuredImage = response.data.url;
      } catch (error) {
        if (error.response) {
          Swal.fire('Error', error.response.data.message, 'error');
        } else {
          Swal.fire('Error', error, 'error');
        }
      }
    },
  },
 created(){
  if (this.$route.params.id) {
      this.getSpecificId(this.$route.params.id);
    } else {
      this.fetchCategories();
    }
 },
  mounted() {
    this.getProductStatus();
    this.getProductTypes();
    this.fetchTags()
    // if (this.$route.params.id) {
    //   this.getSpecificId();
    // } else {
    //   this.fetchCategories();
    // }
  },
};
</script>

<style>
.swal2-confirm{
  margin-right: 10px !important;
}
</style>