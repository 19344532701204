<template>
  <div class="mb-5">
    <b-row>

      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">{{ title }}</h5>
        <small class="text-muted">
          {{ subtitle }}
        </small>
      </b-col>

    </b-row>

    <b-row>



      <b-col md="9">
        <b-form-group label="Endpoint that returns company branches" label-for="v-first-name">
          <b-form-input id="v-country-api-url" v-model="localLoginInfo.getBranchesUrl"
            placeholder="https://api.example.com/countries" />
        </b-form-group>
      </b-col>


    </b-row>


    <b-row v-if="2 > 1">


      <b-col cols="3" md="3">






      </b-col>
      <b-col cols="4" md="4">

      </b-col>
      <b-col cols="2" md="2">
        <b-form-group label="Click & Fetch " label-for="v-first-name">
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="fecthCountries">
            Fetch
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>



    <!--- Headers Area -->

    <!-- HEaders End -->
    <b-row v-if="2 > 1">


      <b-col md="6" v-if="importedCountries">

        <b-card-code :code_visible="true" title="Response">

          <b-card-text>
            <span>This token will be used for getting data</span>
            <span>
              {{ localLoginInfo.activeToken }}
            </span>
          </b-card-text>

          <template #code>
            {{ demoRequestResponse }}
          </template>
        </b-card-code>

      </b-col>
      <b-col md="3">

        <b-card-code v-if="importedCountries" :code_visible="false" title="Result">

          <b-card-text>
            <b-col md="12">
              <b-alert v-if="!!importedCountries" variant="success" show>
                <div class="alert-body">
                  <span>
                    End point returns
                    <strong> {{ importedCountries.length }}</strong> Department information</span>
                </div>
              </b-alert>
            </b-col>
            <b-col md="6">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="importCountries">
                Apply
              </b-button>
            </b-col>
          </b-card-text>


        </b-card-code>

      </b-col>

    </b-row>


    <b-row>
      <b-col cols="12">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1"
          @click.prevent="sendInformation">
          {{ $t("Save Changes") }}
        </b-button>

      </b-col>
    </b-row>
  </div>
</template>


<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BAlert,
  BForm,
  BCardText,
  BBadge, BListGroup, BFormRadio, BListGroupItem
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CountryPicker from "../component/CountryPicker.vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "@axios";
import { heightTransition } from "@core/mixins/ui/transition";
import BCardCode from '@core/components/b-card-code'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BAlert,
    BForm,
    BCardText,
    BCardCode,
    vSelect,
    BFormRadio,
    CountryPicker, BBadge, BListGroup, BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mixins: [heightTransition],
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedLanguage: "nothing_selected",
      selectedContry: "Turkey",
      requestMethod: "POST",
      useTokeninHeader: true,
      demoUser: "",
      demoPass: "",
      demoToken: null,
      localLoginInfo: {},

      demoRequestResponse: "",
      requestMethods: ["GET", "POST", "PUT"],
      headers: {},
      importedCountries: null,
      customCountryEndpoint: null,
      customCountryBase: "",
      selectedCountries: [],
      items:
      {

        "Content-Type": "application/json",


      },

      nextTodoId: 2,

    };
  },
  props: {
    title: "",
    subtitle: "",
    description: "",
    sampleresponse: "",
    initialLoginInfo: {
      type: Object,
      default: () => { },
    },
  },
  methods: {

    sendInformation() {
      //console.log(this.localLoginInfo);
      //console.log("Importing");

      this.isLoading = true;
      axios({
        method: "put",
        url: `/admin/f/migrationsettings`,
        data: {
          info: "getbranches",
          file: null,
          content: this.localLoginInfo,
        },
      })
        .then((r) => {
          //console.log("Import Done");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          //console.log("Import Failed");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        //console.log("validationForm");
        //console.log(this.selectedCountries.length);

        if (this.selectedCountries.length > 0) {
          resolve(true);
        } else {
          reject();
        }
      });
    },
    importCountries() {
      //console.log(this.localLoginInfo);
      //console.log("Importing");

      this.isLoading = true;
      axios({
        method: "post",
        url: `/admin/f/createmigraterequest`,
        data: {
          info: "getbranches",
          file: null,
          content: this.localLoginInfo,
        },
      })
        .then((r) => {
          //console.log("Import Done");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          //console.log("Import Failed");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },

    fecthCountries() {

      //console.log(this.localLoginInfo);

      var items =
      {

        "Content-Type": "application/json",
        "Authorization": this.localLoginInfo.activeToken

      };


      return new Promise((resolve, reject) => {
        var uri = this.localLoginInfo.getBranchesUrl;

        var options = {
          method: 'GET',//this.localLoginInfo.requestMethod,

          headers: items,
        };

        const response = fetch(uri, options)
          .then((response) => {
            var data = response.json();

            //console.log(data );

            return data;
          })
          .then((data) => {
            //console.log(data);
            this.importedCountries = data;
            this.demoRequestResponse = JSON.stringify(data);
            var respObject = JSON.parse(this.demoRequestResponse);


            ;
            //  this.$emit("datafetched", respObject);
            resolve(data);
          })
          .catch((error) => {
            //console.log(error);
            reject(error);
          });
      });
    },

    countrySelected(value) {
      this.selectedCountries = value;
    },

    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Form Submitted",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
  mounted() {
    //console.log("mounted");

    //  this.initTrHeight();
  },
  watch:
  {
    initialLoginInfo(newvalue, oldvalue) {
      //console.log("updated");
      //console.log( this.initialLoginInfo);
      //console.log(newvalue);

      this.localLoginInfo = this.initialLoginInfo == null ? {} : JSON.parse(JSON.stringify(this.initialLoginInfo.data));//this.generalData;


    }
  },
  created() {
    //console.log("Created");
    //console.log(this.initialLoginInfo);

    //  this.localLoginInfo = JSON.parse(JSON.stringify(this.initialLoginInfo));//this.generalData;
    this.localLoginInfo = this.initialLoginInfo == null ? {} : JSON.parse(JSON.stringify(this.initialLoginInfo));//this.generalData;

    window.addEventListener("resize", this.initTrHeight);

  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
};
</script>
