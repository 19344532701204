<template>
  <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left">

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t('General') }}</span>
      </template>

      <course-setting-general v-if="courseData" :courseId="courseData.data.id" :field-information="'general'"
        :general-data="courseData" />
    </b-tab>


    <b-tab>


      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t('Certificate Designer') }}</span>
      </template>

      <certificate-designer v-if="courseData" title="Certification Design" :id="courseData.data.id"
        :courseId="courseData.data.id" :field-information="'certificateHtml'"
        :information-data="courseData.data.certificateHtml" />
    </b-tab>
    <b-tab>



      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t('Certification Preview') }}</span>
      </template>

      <course-info-content-info v-if="courseData" :title="$t('Certification Preview')" :id="courseData.data.id"
        :courseId="courseData.data.id" :field-information="'certificateHtml'"
        :information-data="courseData.data.certificateHtml" />
    </b-tab>






    <!-- 
    

    -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import courseStoreModule from '../certificateStoreModule'
import CourseSettingGeneral from './CertificateInfoGeneral.vue'
import CourseSettingInformation from './CertificateInfoInformation.vue'
import CourseSettingNotification from './CertificateInfoNotification.vue'
import CourseInfoContentInfo from './CertificateInfoContentInfo.vue'
import CourseInfoCoursePreference from './CertificateInfoCoursePreference.vue'
import CourseInfoPricing from './CertificateInfoPricing.vue'
import CourseInfoCertification from './CertificateInfoCertification.vue'
import CertificateDesigner from './CertificateDesign.vue'

export default {
  components: {
    BTabs,
    BTab,
    CourseSettingGeneral,
    CertificateDesigner,
    CourseSettingInformation,

    CourseSettingNotification,
    CourseInfoCoursePreference,
    CourseInfoPricing,
    CourseInfoCertification,
    CourseInfoContentInfo
  },

  setup() {
    const courseData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'apps-certificate-edit'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, courseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('apps-certificate-edit/fetchCertificate', { id: router.currentRoute.query.id })
      .then(response => {
        //    var course=JSON.parse(JSON.stringify(response.data));
        //  const { course  } = response.data
        courseData.value = response.data;
        //console.log("courses assigned to course data");
        // //console.log(response.data);


      })
      .catch(error => {
        if (error.response.status === 404) {
          courseData.value = undefined
        }
      })

    return {
      courseData,
    }
  },
  data() {
    return {
      courseInfo: JSON.parse(JSON.stringify(this.courseData)),
    }
  },

  beforeCreate() {

    // this.courseInfo=this.courseData;





    return;
  },
}
</script>
