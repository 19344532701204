<template>
  <b-row>
    <b-col cols="12">
      <b-form-group>
        <v-select v-model="selected1" v-if="authors != null" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="authors" label="name">
          <template #option="{ name, photo }">

            <b-avatar size="40" :src="photo" variant="light-primary" badge-variant="success">
            </b-avatar>
            <span> {{ name }}</span>
          </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup, BAvatar } from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BAvatar
  },
  created() {
    this.fetchInstructers()

  },
  methods: {
    updateSelections(a) {

      //console.log(a)
    },

    fetchInstructers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lms/admin/course/getallinstructors', { roleid: 7 })
          .then((response) => {
            var data = response.data;
            var responseData = JSON.parse(JSON.stringify(data));
            //console.log(responseData.data);
            this.authors = responseData.data;
            
          }
          )
          .catch(error => reject(error))
      })
    },
  },
  watch: {
    selected1: function (newVal, coldVal) {
      this.selected1 = newVal;
      this.$emit("authorChanged", newVal);
      //console.log("uploaded data : "+ newVal)
    }
  },
  props: {
    initialAuthor: []
  },
  data() {
    return {
      authors: [],
      selected1: this.initialAuthor ?? [],
      avatar: 'http://scetconsultancy.com/img/profile.png',
      option: [{ title: 'Square' }, { title: 'Rectangle' }, { title: 'Rombo' }, { title: 'Romboid' }],
    }
  },
}
</script>