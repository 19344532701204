<template>
  <section>
    <b-row>

      <!-- Col: Left (Invoice Container) -->
      <b-col
          cols="12"
          xl="12"
          md="12"
      >
        <b-form @submit.prevent>
          <b-card
              no-body
              class="invoice-preview-card"
          >


            <!-- Invoice Client & Payment Details -->
            <b-card-body
                class="invoice-padding pt-0"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                    cols="12"
                    xl="6"
                    class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Select Employee:
                  </h6>

                  <!-- Select Client -->
                  <v-select
                      v-model="selectedMember"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="members.users"
                      label="fullname"
                      input-id="invoice-data-client"
                      :clearable="false"
                  >

                  </v-select>

                  <!-- Selected Client -->
                  <div
                      v-if="selectedMember"
                      class="mt-1"
                  >
                    <h6 class="mb-25">
                      {{ selectedMember.fullname }}
                    </h6>
                    <b-card-text class="mb-25">
                      Registry No: {{ selectedMember.membercode }}
                    </b-card-text>
                    <b-avatar class="mb-25"
                              :src="selectedMember.photo"
                              :text="avatarText(selectedMember.fullname)"

                              size="104px"
                              rounded
                    />
                    <b-row>
                      <b-button class="mb-25" @click="addToList(selectedMember)">Add to Training</b-button>
                    </b-row>

                  </div>
                </b-col>

              </b-row>
            </b-card-body>


          </b-card>
        </b-form>
      </b-col>


    </b-row>
    <hr>
    <b-row class="invoice-add">
      <b-col cols="12"
             xl="12"
             md="12"
      >

        <!-- Table Container Card -->
        <b-card
            no-body
        >
          <!-- table -->
          <b-card title="Assigned Employees">
            <!-- input search -->
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center justify-content-end">
                  <label class="mr-1">Search</label>
                  <b-form-input
                      v-model="searchTerm"
                      placeholder="Search"
                      type="text"
                      class="d-inline-block mr-1"
                  />

                </div>
              </b-form-group>
            </div>
            <vue-good-table
                :columns="columns"
                :rows="rows"
                :sort-options="{
                enabled: true,
              initialSortBy: {field: 'id', type: 'desc'}
                    }"
                :line-numbers="true"
                :search-options="{
               enabled: true,
               externalQuery: searchTerm }"
                :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
            >
              <template
                  slot="table-row"
                  slot-scope="props"
              >

                <!-- Column: Name -->
                <div
                    v-if="props.column.field === 'firstName'"
                    class="text-nowrap"
                >
                  <b-avatar
                      :src="props.row.photo"
                      class="mx-1"
                  />
                  <span class="text-nowrap">{{ props.row.firstName }}</span>
                </div>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
          <span>
            <div class="align-content-center">
              <feather-icon
                  @click="removeMember(props.row.id)"
                  :id="`invoice-row-${props.row.id}-send-icon`"
                  icon="TrashIcon"
                  class="cursor-pointer"
                  size="16"

              />
                <b-tooltip
                    title="Remove Employee"
                    class="cursor-pointer"
                    :target="`invoice-row-${props.row.id}-send-icon`"
                />
            </div>

            <!--            <b-dropdown-->
            <!--                variant="link"-->
            <!--                toggle-class="text-decoration-none"-->
            <!--                no-caret-->
            <!--            >-->
            <!--              <template v-slot:button-content>-->
            <!--                <feather-icon-->
            <!--                    icon="MoreVerticalIcon"-->
            <!--                    size="16"-->
            <!--                    class="text-body align-middle mr-25"-->
            <!--                />-->
            <!--              </template>-->
            <!--              <b-dropdown-item :to="{name: 'training-edit', params:{ id: props.row.id }  }">-->
            <!--                <feather-icon-->
            <!--                    icon="Edit2Icon"-->
            <!--                    class="mr-50"-->
            <!--                />-->
            <!--                <span>{{ $t("Edit") }}</span>-->
            <!--              </b-dropdown-item>-->
            <!--              <b-dropdown-item @click="deleteData(props.row.id)">-->
            <!--                <feather-icon-->
            <!--                    icon="TrashIcon"-->
            <!--                    class="mr-50"-->
            <!--                />-->
            <!--                <span>Delete</span>-->
            <!--              </b-dropdown-item>-->
            <!--            </b-dropdown>-->
          </span>
        </span>

                <!-- Column: Common -->
                <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
              </template>

              <!-- Column: Actions -->


              <!-- pagination -->
              <template
                  slot="pagination-bottom"
                  slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
                    <b-form-select
                        v-model="pageLength"
                        :options="['3','5','10']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap "> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>

          </b-card>


        </b-card>

      </b-col>

    </b-row>

  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { VueGoodTable } from 'vue-good-table'
import { ref, onUnmounted, onMounted, watch, inject } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
 
import {
  BCardBody, BFormInvalidFeedback,  BTab,  BTabs,BForm,  VBToggle, VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import orientationStoreModule from '@/views/hr/orientation/orientationStoreModule'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { useToast } from 'vue-toastification/composition'
import Swal from 'sweetalert2'

export default {
  components: {
    flatPickr,
    BCardBody, BFormInvalidFeedback,  BTab,  BTabs,BForm,  VBToggle, VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar,
    vSelect,
    Logo,
    VueGoodTable
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Id',
          field: 'id',
          type: 'number',
          hidden:true,
          filterOptions: {
            enabled: true,
            placeholder: 'Search Id',
          },
        },
        {
          label: 'Name',
          field: 'firstName',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Surname',
          field: 'lastName',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Surname',
          },
        },
        {
          label: 'Registry No',
          field: 'memberCode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Registry',
          },
        },
        {
          label: 'Mobile',
          field: 'mobile',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Mobile',
          },
        },
        {
          label: 'Action',
          field: 'action',
          tdClass: 'text-center'
        },
      ],
      searchTerm: '',
    }
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  props: {
    trainingId: {
      type: Number,
      required: true,

    }
  },
  setup(props) {

    const toast = useToast()
    const TRAINING_APP_STORE_MODULE = 'hr-training'

    // Register module
    if (!store.hasModule(TRAINING_APP_STORE_MODULE)) store.registerModule(TRAINING_APP_STORE_MODULE, orientationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRAINING_APP_STORE_MODULE)) store.unregisterModule(TRAINING_APP_STORE_MODULE)
    })

    const members = ref([])
    const rows = ref([])

    const fetchMembers = () => {
      store.dispatch('fetchMembers')
          .then(response => {
            members.value = response.data
          })
          .catch(error => {

            if (error.response.status === 404) {
              members.value = undefined
            }
          })
    }
    const fetchMembersByTrainingId = () => {
      store.dispatch('fetchAssignedMembersToTraining', { id: props.trainingId })
          .then(response => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Success fetched employees.',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            rows.value = response.data

          })
          .catch(error => {
            rows.value = []
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error could not fetch employees for this training.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            if (error.response.status === 404) {
              rows.value = []
            }
          })
    }
    const removeMember = (id) => {

      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              store.dispatch('deleteAssignedMembersFromTraining', { id })
                  .then(response => {
                    Swal.fire({
                      icon: 'success',
                      title: 'Deleted!',
                      timer: 2000,
                      text: 'Employee has been removed.',
                      // customClass: {
                      //   confirmButton: 'btn btn-success',
                      // },
                    })
                    fetchMembersByTrainingId()
                  })
                  .catch(error => {
                    Swal.fire({
                      icon: 'error',
                      title: 'Failed!',
                      text: 'Employee could not been removed from training.',
                      customClass: {
                        confirmButton: 'btn btn-danger',
                      },
                    })
                  })

            }
          })
    }
    onMounted(() => {
      fetchMembers()
      fetchMembersByTrainingId()

    })

    const selectedMember = ref(null)

    const addToList = (client) => {

      let clientDto = {
        trainingId: props.trainingId,
        firstName: client.firstname,
        lastName: client.lastname,
        photo: client.photo,
        memberCode: client.membercode,
        userGuid: client.userguid,
        additionalInfo: client.additionalinfo,
        mobile: client.mobile
      }
      store.dispatch('assignMembersToTraining', clientDto)
          .then(response => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Success added employee to training.',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            fetchMembersByTrainingId()

          })
          .catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error could not assigned employee to training.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })

          })

    }

    return {
      rows,
      members,
      addToList,
      selectedMember,
      avatarText,
      fetchMembersByTrainingId,
      fetchMembers,
      removeMember,

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
