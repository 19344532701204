<template>
  <div>
    <contact-list-add-new
        :is-add-new-contact-sidebar-active.sync="isAddNewContactSidebarActive"
        @refetch-data="refetchData"
    />


    <b-card-code title="">
      <!-- input search -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center justify-content-end">
            <label class="mr-1">Search</label>
            <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block mr-1"
            />
            <b-button
                variant="primary"
                @click="isAddNewContactSidebarActive = true"
            >
              <span class="text-nowrap">Add Contact</span>
            </b-button>
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
          :columns="columns"
          :rows="rows"
          :line-numbers="true"
          :search-options="{
               enabled: true,
        externalQuery: searchTerm }"
          :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      >
        <template
            slot="table-row"
            slot-scope="props"
        >

          <!-- Column: Name -->
          <div
              v-if="props.column.field === 'fullName'"
              class="text-nowrap"
          >
            <b-avatar
                :src="props.row.avatar"
                class="mx-1"
            />
            <span class="text-nowrap">{{ props.row.fullName }}</span>
          </div>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'isActive'">
          <b-badge pill  :variant="`light-${resolveUserStatusVariant(props.row.isActive)}`">
            {{ props.row.isActive }}
          </b-badge>
        </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item :to="{name: 'contact-edit', params:{ id: props.row.id }  }">
                <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                />
                <span>{{ $t("Edit") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteData(props.row.id)">
                <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

          <!-- Column: Common -->
          <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
              <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>


    </b-card-code>
  </div>
</template>
<script>
  import {
    VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BSidebar ,BFormSelect
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
import ContactListAddNew from '@/views/hr/orientation/contact/ContactListAddNew'
import store from '@/store'
import orientationStoreModule from '@/views/hr/orientation/orientationStoreModule'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { useToast } from 'vue-toastification/composition'
export default {
  name: 'contacts-page',
  components: {
    BCardCode,
    VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BSidebar,BFormSelect,
    VueGoodTable,
    ContactListAddNew
  },
  data(){
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Surname',
          field: 'surname',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Surname',
          },
        },
        {
          label: 'Phone Number',
          field: 'phoneNumber',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Phone',
          },
        },
        {
          label: 'Department',
          field: 'department',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Department',
          },
        },
        {
          label: 'Title',
          field: 'title',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Title',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },

      ],
      searchTerm: '',
    }
  },

  setup(){
    const VIDEO_APP_STORE_MODULE = 'hr-contact'
    const toast = useToast()
    const rows = ref([])
    // Register module
    if (!store.hasModule(VIDEO_APP_STORE_MODULE)) store.registerModule(VIDEO_APP_STORE_MODULE, orientationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VIDEO_APP_STORE_MODULE)) store.unregisterModule(VIDEO_APP_STORE_MODULE)
    })
    onMounted(() =>{
      refetchData()
    })

    const isAddNewContactSidebarActive = ref(false)



    const refetchData = function (){
      store.dispatch('fetchContacts')
          .then(response => {

            rows.value = response.data
          })
          .catch(reject=> {

            rows.value = []
          })
    };

    const deleteData = function(id){

      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('deleteContact', {id})
              .then(response => {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  timer: 2000,
                  text: 'Your file has been deleted.',
                  // customClass: {
                  //   confirmButton: 'btn btn-success',
                  // },
                })
                refetchData()
              })
              .catch(error => {
                this.$swal({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'Contact could not been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
              })

        }
      })


    }

    const resolveUserStatusVariant = status => {
      if (status === 'pending') return 'warning'
      if (status === true) return 'success'
      if (status === false) return 'danger'
      return 'primary'
    }

    return {
      isAddNewContactSidebarActive,
      refetchData,
      deleteData,
      resolveUserStatusVariant,
      rows
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.flatpickr-calendar{
  overflow: hidden;
  display: none;
}
</style>
