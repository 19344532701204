<template>
  <b-sidebar id="add-new-training-sidebar" :visible="isAddNewTrainingSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-training-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Training
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <!-- Name -->
          <validation-provider #default="validationContext" name="Name" rules="required">
            <b-form-group label="Name" label-for="name">
              <b-form-input id="name" v-model="trainingData.name" autofocus
                :state="getValidationState(validationContext)" trim placeholder="" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider #default="validationContext" name="Description" rules="required">
            <b-form-group label="Description" label-for="description">
              <b-form-input id="description" v-model="trainingData.description"
                :state="getValidationState(validationContext)" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Trainer -->
          <validation-provider #default="validationContext" name="Trainer" rules="required">
            <b-form-group label="Trainer" label-for="trainer">
              <b-form-input id="trainer" v-model="trainingData.trainer" :state="getValidationState(validationContext)"
                trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Room -->
          <validation-provider #default="validationContext" name="Room" rules="required">
            <b-form-group label="Room" label-for="room">
              <b-form-input id="name" v-model="trainingData.room" autofocus
                :state="getValidationState(validationContext)" trim placeholder="" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Start DateNTime -->
          <validation-provider #default="validationContext" name="StartDate" rules="required">
            <b-form-group label="Start Date" label-for="startDate">

              <flat-pickr id="startDate" v-model="trainingData.startDate" class="form-control"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', minuteIncrement: 1, minDate: 'today' }" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- End DateNTime -->
          <validation-provider #default="validationContext" name="EndDate" rules="required">
            <b-form-group label="End Date" label-for="endDate">

              <flat-pickr id="endDate" v-model="trainingData.endDate" class="form-control"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', minuteIncrement: 1, minDate: trainingData.startDate }" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>



          <!-- Training Type -->
          <validation-provider #default="validationContext" name="TrainingType" rules="">
            <b-form-group label="Training Type" label-for="trainingType" :state="getValidationState(validationContext)">
              <v-select v-model="trainingData.selectedType" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="trainingData.trainingTypes" :clearable="false" label="name" input-id="trainingType" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              {{ $t("Add") }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              {{ $t("Cancel") }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import trainingTypes from '../trainingTypes'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {
  VBModal, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BFormSelect, BSidebar, BForm, BFormInvalidFeedback,
} from 'bootstrap-vue'
export default {

  components: {
    vSelect,
    flatPickr,
    VBModal, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BFormSelect, BSidebar, BForm, BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewTrainingSidebarActive',
    event: 'update:is-add-new-training-sidebar-active',
  },
  props: {
    isAddNewTrainingSidebarActive: {
      type: Boolean,
      required: true,
    }

  },
  data() {
    return {
      required,
      alphaNum,
      trainingTypes,


    }
  },
  setup(props, { emit }) {

    const toast = useToast()
    const blankTrainingData = {
      name: '',
      description: '',
      trainer: '',
      room: '',
      startDate: new Date(),
      endDate: new Date(),
      trainingTypes: [{
        name: 'General',
        id: 1
      }],
      selectedType: {
        name: 'General',
        id: 1
      }

    }

    const trainingData = ref(JSON.parse(JSON.stringify(blankTrainingData)))
    const resettrainingData = () => {
      trainingData.value = JSON.parse(JSON.stringify(blankTrainingData))
    }



    const onSubmit = () => {

      store.dispatch('createTraining', trainingData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-training-sidebar-active', false)
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding trainings list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })

    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resettrainingData)

    return {
      trainingData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-training-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
