<template>
  <b-nav-item-dropdown id="dropdown-grouped" variant="link" class="dropdown-language" right>
    <template #button-content>
      <b-img :src="currentLocale.img" height="14px" width="22px" :alt="currentLocale.locale" />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item v-for="localeObj in locales" :key="localeObj.locale" @click="handleLangChange(localeObj.locale)">
      <b-img :src="localeObj.img" height="14px" width="22px" :alt="localeObj.locale" />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import store from '@/store'
import useAppConfig from '@core/app-config/useAppConfig'
import router from "@/router";
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  methods: {
    handleLangChanged(lang) {

      //  //console.log("changing rtl");
      //  //console.log( this.$store.state.appConfig.layout.isRTL);
      //  //console.log((lang === 'ar'));


      var currentLang = this.$i18n.locale;

      if (currentLang != lang && lang === 'ar') {
        // //console.log(" setting is rtl true ");
        this.$store.state.appConfig.layout.isRTL = true;

        this.$store.commit('appConfig/TOGGLE_RTL', true);
      }
      this.$i18n.locale = lang;


    }
  },
  setup(props) {
    /* eslint-disable global-
    require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      {
        locale: 'pt',
        img: require('@/assets/images/flags/pt.png'),
        name: 'Portuguese',
      },
    ];

    // //console.log(props);


    const {
      isRTL,
      skin,
      contentWidth,
      routerTransition,
      layoutType,
      isNavMenuHidden,
      isVerticalMenuCollapsed,
      navbarBackgroundColor,
      navbarType,
      footerType,
    } = useAppConfig()


    const handleLangChange = lang => {

      // //console.log("changing rtl");
      // //console.log((lang === 'ar'));

      var isCurrentRTL = store.state.appConfig.layout.isRTL;


      if ((isCurrentRTL != true && lang === 'ar') || (isCurrentRTL == true && lang != 'ar')) {
        //store.state.appConfig.layout.isRTL = true;

        store.commit('appConfig/TOGGLE_RTL', true);
      }
      router.app.$options.i18n.locale = lang;

    }
    //console.log(router.app.$options.i18n.locale);



    /* eslint-disable global-require */

    return {
      locales,
      isRTL,
      handleLangChange,

    }
  },
}
</script>

<style></style>
