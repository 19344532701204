<template>

  <div>

    <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
      :plan-options="planOptions" @new-record-created="newRecordCreated" @refetch-data="refetchData" :key="currentId" />



    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Show') }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search...')" />
              <b-button variant="primary" @click="isAddNewUserSidebarActive = true">
                <span class="text-nowrap">Create Survey</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refUserListTable" class="position-relative" :items="fetchCourses" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc">

        <template #head()="data">
          <span class="text">{{ $t(data.label.toUpperCase()) }}</span>
        </template>
        <template #cell(user)="data">
          <b-media vertical-align="center">

            <b-link :to="{ name: 'SurveyEdit', params: { id: data.item.id }, query: { s: data.item.id } }"
              class="font-weight-bold d-block text-nowrap">
              {{ data.item.title }}
            </b-link>
            <small class="text-muted">{{ data.item.surveyCode }}</small>
          </b-media>
        </template>

        <template #cell(userinfo)="data">




          {{ data.item.title }}

        </template>
        <template #cell(surveyStatus)="data">





          <b-badge pill :variant="`${resolveSurveyStatusVariant(data.item.surveyStatus)}`" class="text-capitalize">
            {{ resolveSurveyStatus(data.item.surveyStatus) }}

          </b-badge>
        </template>

        <template #cell(surveysubject)="data">


          <b-link
            :to="{ name: 'SurveySubjects', params: { id: data.item.id }, query: { s: data.item.id, t: data.item.title } }"
            class="font-weight-bold d-block text-nowrap">
            <feather-icon :icon="resolveUserRoleIcon(data.item.role)" size="18" class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`" /> Survey Subjects
          </b-link>

        </template>


        <template #cell(id)="data">

          <b-link
            :to="{ name: 'SurveyQuestions', params: { id: data.item.id }, query: { s: data.item.id, t: data.item.title } }"
            class="font-weight-bold d-block text-nowrap">

            Questions
          </b-link>

        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">


          <b-badge pill v-if="Boolean(data.item.deleted)" variant="light-danger" class="text-capitalize">
            Deleted

          </b-badge>

          <b-dropdown v-if="Boolean(data.item.status == 100)" variant="outline-primary"
            :right="$store.state.appConfig.isRTL">
            <template #button-content>
              Completed
            </template>
            <b-dropdown-item v-if="data.item.status == 100">
              <b-link v-if="Boolean(data.item.status == 100)"
                :to="{ name: 'ResultViews', params: { id: data.item.id }, query: { s: data.item.id, t: data.item.title } }"
                class="font-weight-bold d-block text-nowrap">
                <feather-icon icon="TrendingUpIcon" />
                Results
              </b-link>
            </b-dropdown-item>

            <b-dropdown-item>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-info" @click="cloneSurvey(data.item)">

                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Clone Survey</span>
              </b-button>
            </b-dropdown-item>

          </b-dropdown>
          <b-dropdown v-if="!Boolean(data.item.deleted) && data.item.status != 100"
            :variant="`${data.item.status != 10 ? getStatus(data.item.courseStatus) : 'outline-success'}`"
            :right="$store.state.appConfig.isRTL">


            <template #button-content>
              <div class="md-1" v-if="data.item.status != 10">

                {{ $t(getStatusText(data.item.courseStatus)) }}
              </div>

              <div v-else>
                <feather-icon icon="check" size="18" class="mr-50"
                  :class="`text-${resolveUserRoleVariant(data.item.role)}`" />
                <span>Active</span>
              </div>
            </template>




            <b-dropdown-item v-if="data.item.status == 0">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-success"
                @click="publishSurvey(data.item)">

                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Start Survey</span>
              </b-button>
            </b-dropdown-item>


            <b-dropdown-item v-if="data.item.status == 10">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-secondary"
                @click="completeSurvey(data.item)">

                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">End Survey</span>
              </b-button>

            </b-dropdown-item>

            <b-dropdown-item>
              <b-button v-if="data.item.status == 0" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger"
                @click="deleteSurvey(data.item)">

                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t("Delete") }}</span>
              </b-button>

            </b-dropdown-item>
            <b-dropdown-item>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-info" @click="cloneSurvey(data.item)">

                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Clone Survey</span>
              </b-button>
            </b-dropdown-item>

          </b-dropdown>
        </template>




      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{
              $t('of') }}
              {{ dataMeta.of }}
              {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import axios from '@axios'

import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './CoursesListFilters.vue'
import useUsersList from './useSurveysList'
import userStoreModule from '../surveyStoreModule'
import UserListAddNew from './SurveyListAddNew.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {

  computed: {

  },
  data() {
    return {
      currentId: 0,
    }
  },
  methods: {
    getRoute(coursemode) {

      return "page-coursemode";
    },
    newRecordCreated(coursemode) {

      this.currentId++;
    }
    ,

    publishSurvey(coursedata) {
      //console.log(coursedata);
      store
        .dispatch('app-surveys/publishSurvey', {
          id: coursedata.id,

        })
        .then(response => {

          var result = response.data.success;
          if (result != null && result != undefined && result == 100) {
            coursedata.courseStatus = 100;


            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
            coursedata.status = 10;

          } else {
            //console.log(response);

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                text: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          }


        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
    },
    cloneSurvey(coursedata) {
      //console.log(coursedata);
      store
        .dispatch('app-surveys/cloneSurvey', {
          id: coursedata.id,

        })
        .then(response => {

          var result = response.data.success;
          if (result != null && result != undefined && result == 100) {


            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;


          } else {
            //console.log(response);

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                text: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          }


        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
    },
    completeSurvey(coursedata) {
      //console.log(coursedata);
      store
        .dispatch('app-surveys/completeSurvey', {
          id: coursedata.id,
        })
        .then(response => {

          var result = response.data.success;
          if (result != null && result != undefined && result == 100) {
            coursedata.status = 100;

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;

          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          }


        })
        .catch((err) => {
          //console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating course status',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    deleteSurvey(coursedata) {
      //console.log(this.localOptions);
      //console.log("update course  is launching with id : " + coursedata.id);

      this.isLoading = true;
      axios({
        method: "put",
        url: `/survey/admin/deleteSurvey/${coursedata.id}`,
        data: {
          info: "surveyDelete",
          file: null,
          content: this.localOptions,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          coursedata.deleted = true;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    }
  },

  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    ToastificationContent
  }, directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-surveys'
    //console.log("surveys");

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const getPageroute = (surveyid) => {



      var result = { name: 'survey-questions', params: { id: surveyid } };

      return result;


    }
    const getStatus = status => {



      // DRAFT 
      if (status == '0') return 'secondary'
      // published
      if (status == '100') return 'success'

      // ended by time limit
      if (status == '200') return 'warning'
      // stopped 
      if (status == '500') return 'warning'

      return 'secondary';


    }
    const getStatusText = status => {



      // DRAFT 
      if (status == '0') return 'Draft'
      // published
      if (status == '100') return 'Published'

      // ended by time limit
      if (status == '200') return 'Ended'
      // stopped 
      if (status == '500') return 'Stopped'

      return 'Draft';


    }


    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      resolveSurveyStatusVariant,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
      resolveSurveyStatus
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      resolveSurveyStatus,
      resolveSurveyStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
      getPageroute,
      getStatus,
      getStatusText
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
