<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show entries</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search...')" />
              <b-button @click="newCategory" variant="primary">
                <span class="text-nowrap">Add Model</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <Loader v-if="loading" />
    <b-card style="padding:0rem" v-else>
      <b-card-body style="padding:0rem">
        <b-table style="text-align: center;padding: 0rem;" :items="results" responsive :fields="modelColumns"
          primary-key="id" show-empty :empty-text="$t('No matching records found')">

          <template #cell(featuredImage)="data">
            <img style="height:80px;width:80px;align-self:center" :src=data.item.featuredImage />
          </template>
          <template #cell(models)>
            <b-link to="/">
              Model
            </b-link>
          </template>
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <template #aside>
                <!-- <b-avatar
                  size="32"
                  text="adeel"
                  :to="{ name: 'apps-users-view', params: { id: 1 } }"
                /> -->
              </template>
              <b-link @click="toggleShowCategory" class="font-weight-bold d-block text-nowrap">
                {{ data.item.title }}
              </b-link>
            </b-media>
          </template>
          <template #cell(action)="data">
            <b-button type="submit" variant=".btn-flat-dark" @click="changeCategory(data.item)">
              <edit-icon size="1.5x" class="custom-class text-primary"></edit-icon>
            </b-button>
            <!-- <b-button variant=".btn-flat-dark" @click="disable(data.item.id)"
              ><x-circle-icon
                size="1.5x"
                class="custom-class text-warning"
              ></x-circle-icon>
            </b-button> -->

            <b-button variant=".btn-flat-dark" @click="deleteCategory(data.item)">
              <trash-2-icon size="1.5x" class="custom-class text-danger"></trash-2-icon>
            </b-button>
          </template>
        </b-table>
        <add-new @handle="handle" :isVisible.sync="isVisible" :selectedCategory="selectedCategory"
          @addFeaturedImage="addFeaturedImage" :imageUrl="featuredImage" />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BCol,
  BRow,
  BFormInput,
  BButton,
  BCard,
  BCardBody,
  BMedia,
  BAvatar,
  BFormSelect,
  BLink,
  BFormFile,
  BCardText,
  BMediaBody,
  BForm,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import useBrandsList from '../useBrandsList';
import { ref } from '@vue/composition-api';
import AddNew from './AddNew.vue';
import { deleteCall, getCall, postCall, putCall } from '../../Service';
import Loader from '../../components/Loader.vue';
import Swal from 'sweetalert2';
import axios from 'axios';
import {
  Trash2Icon,
  EditIcon,
  EyeOffIcon,
  XCircleIcon,
  EyeIcon,
  ExternalLinkIcon,
} from 'vue-feather-icons';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BTable,
    BCol,
    BLink,
    XCircleIcon,
    BAvatar,
    BMedia,
    BRow,
    AddNew,
    vSelect,
    BFormInput,
    BButton,
    BFormSelect,
    BCardBody,
    BCard,
    BFormFile,
    BCardText,

    BMediaBody,
    BForm,
    Loader,
    Trash2Icon,
    EditIcon,
    EyeOffIcon,
    EyeIcon,
    ExternalLinkIcon,
  },
  data() {
    return {
      searchQuery: '',
      featuredImageFile: null,
      selectedCategory: {
        id: null,
        slug: '',
        title: null,
        //   description: null,
      },
      addedData: {
        title: null,
        brandid: '',
        //   description: null,
        slug: '',
      },
      featuredImage: '',
      items: [],
      showCategory: false,
      loading: false,
    };
  },
  created() {
    //console.log(this.$route.params.id);
  },
  async mounted() {
    await this.getAllCategories();
  },

  computed: {
    results() {
      if (this.searchQuery) {
        const temp = this.items.filter((i) =>
          i.title.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
        return temp;
      } else {
        return this.items;
      }
    },
  },
  methods: {
    //   async disable(id) {
    //     Swal.fire({
    //       title: 'Do you want to disable the product?',
    //       showCancelButton: true,
    //       confirmButtonText: 'Disable',
    //     }).then(async (result) => {
    //       if (result.isConfirmed) {
    //         try {
    //           await putCall(`category/disable/${id}`);
    //           await this.getAllCategories();
    //           this.$toast({
    //             component: ToastificationContent,
    //             position: 'top-right',
    //             props: {
    //               title: `Product Disabled Successfully`,
    //               icon: 'CoffeeIcon',
    //               variant: 'warning',
    //             },
    //           });
    //         } catch (error) {
    //           Swal.fire('Error', error, 'error');
    //         }
    //       } else if (result.isDenied) {
    //         Swal.fire('Changes are not saved', '', 'info');
    //       }
    //     });
    //   },
    toggleShowCategory() {
      this.showCategory = !this.showCategory;
      this.$emit('setShowCategoryVal', this.showCategory);
    },
    async addFeaturedImage(event) {
      this.featuredImageFile = event.item;
      let imageData = new FormData();
      imageData.append('file', event.item);
      try {
        var host = process.env.VUE_APP_API_URL;

        const response = await axios.post(
          host + '/upload/admin',
          imageData,
          {
            headers: {
              'client-auth-token': localStorage.getItem('clientToken'),
              'content-type': 'multipart/form-data',
              'x-access-token': localStorage.getItem('accessToken'),
            },
          }
        );
        this.featuredImage = response.data.url;
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Image Uploaded Successfully`,
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        });
      } catch (error) {
        if (error.response) {
          Swal.fire('Error', error.response.data.message, 'error');
        } else {
          Swal.fire('Error', 'Error uploading image', 'error');
        }
      }
    },
    async getAllCategories() {
      this.loading = true;
      try {
        //   const { data } = await getCall(`model/all`);
        const { data } = await getCall(`admin/store/models-by-brandid/${this.$route.params.id}`);
        this.items = data.models;
      } catch (error) {
        if (error.response) {
          Swal.fire('Error', error.response.data.message, 'error');
        } else {
          Swal.fire('Error', error, 'error');
        }
      } finally {
        this.loading = false;
      }
    },
    async handle(e) {
      this.addedData = {
        brandid: this.$route.params.id,
        title: e.item.title,
        slug: e.item.slug,
        featuredImage: this.featuredImage,
      };
      //console.log(' this.addedData', this.addedData);
      try {
        if (e.item.id) {
          Swal.fire({
            title: 'Do you want to update brand?',
            showCancelButton: true,
            confirmButtonText: 'Update',
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                await putCall(`admin/store/model/${e.item.id}`, this.addedData);
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Brand Updated Successfully`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                  },
                });
                this.isVisible = false;

                await this.getAllCategories();
              } catch (error) {
                if (error.response) {
                  Swal.fire('Error', error.response.data.message, 'error');
                } else {
                  Swal.fire('Error', error, 'error');
                }
              }
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info');
            }
          });
        } else if (this.addedData.title != null) {
          try {
            await postCall('admin/store/model/new', this.addedData);
            await this.getAllCategories();
            this.isVisible = false;
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Brand Created Successfully`,
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            });
          } catch (error) {
            if (error.response) {
              Swal.fire('Error', error.response.data.message, 'error');
            } else Swal.fire('Error', 'Error Creating Category.', 'error');
          }
        } else {
          this.isVisible = true;
        }
      } catch (error) {
        Swal.fire('Error', error, 'error');
      }
    },

    async changeCategory(item) {
      this.selectedCategory = { ...this.selectedCategory, ...item };
      this.featuredImage = item.featuredImage;
      this.selectedCategory.categoryKey = item.categorykey;
      this.selectedCategory.isSpecial = item.isSpecial ? true : false;
      this.isVisible = true;
    },
    newCategory() {
      this.selectedCategory = {
        slug: '',
        title: null,
        description: null,
      };
      this.featuredImage = '';
      this.isVisible = true;
    },
    async deleteCategory(e) {
      Swal.fire({
        title: 'Do you want to delete this model?',
        showCancelButton: true,
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteCall(`admin/store/model/${e.id}`);
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Brand Deleted Successfully`,
                icon: 'CoffeeIcon',
                variant: 'danger',
              },
            });
            this.getAllCategories();
          } catch (error) {
            Swal.fire('Error', error, 'error');
          }
          this.items = this.items.filter((i) => i.id != e.id);
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info');
        }
      });
    },
  },

  setup() {
    const { modelColumns } = useBrandsList();
    const isVisible = ref(false);

    return {
      modelColumns,
      isVisible,
    };
  },
};
</script>