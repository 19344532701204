<template>
  <b-card>
    <!-- form -->

    <b-form>
      <h6 class="section-label  mt-1">Survey Accessibility</h6>
      <b-row>
        <!--/ birth date -->
        <b-col md="12">
          <div class="demo-inline-spacing mb-1">
            <b-form-radio v-model="localOptions.isPublicSurvey" name="some-radios" v-bind:value="false">
              Only Attendees
            </b-form-radio>
            <b-form-radio v-model="localOptions.isPublicSurvey" name="some-radios" v-bind:value="true">
              Everyone can complete
            </b-form-radio>
          </div>
        </b-col>
      </b-row>


      <h6 class="section-label mt-2" v-if="localOptions.isPublicSurvey == true">Access Capacity</h6>
      <b-row>
        <!--/ birth date -->
        <b-col md="12" v-if="localOptions.isPublicSurvey == true">
          <div class="demo-inline-spacing mb-1">
            <b-form-radio v-model="localOptions.hasApplyLimit" name="some-radios-access" v-bind:value="false">
              Unlimited Application
            </b-form-radio>
            <b-form-radio v-model="localOptions.hasApplyLimit" name="some-radios-access" v-bind:value="true">
              The survey has application limit.
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="localOptions.isPublicSurvey == true && localOptions.hasApplyLimit == true">
        <!--/ birth date -->
        <b-col md="2">
          <b-form-group label-for="phone" label="Application Capacity">
            <cleave id="phone" v-model="localOptions.applyCapacity" class="form-control" :raw="false"
              :options="clevePhone" placeholder="Limit" />
          </b-form-group>
        </b-col>

      </b-row>


      <h6 class="section-label mt-1  mb-1">Accessibility</h6>
      <b-row class="mb-1">

        <b-col md="6">

          <b-form-checkbox v-model="localOptions.hasStartDate" name="check-button" switch inline> <b-card-text>
              Survey has certain start date. If you turn this on, attendees will be able to join after this date
            </b-card-text> </b-form-checkbox>
        </b-col>
        <b-col md="6">
          <b-form-checkbox v-model="localOptions.hasEndDate" name="check-button" switch inline>
            <b-card-text>
              Survey has certain start end date. If you turn this on, attendees will not be able to join after this date

            </b-card-text>
          </b-form-checkbox>
        </b-col>
      </b-row>


      <b-row>

        <b-col md="6">
          <b-form-group label-for="example-datepicker" label="Start date">
            <flat-pickr v-model="localOptions.startDate" class="form-control" name="actualOccurStartDate"
              placeholder="Start date" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label-for="example-datepicker" label="Expire date">
            <flat-pickr v-model="localOptions.expireDate" class="form-control" name="actualOccurEndDate"
              placeholder="Expire date" />
          </b-form-group>
        </b-col>
      </b-row>

      <!--/ website -->

      <b-row>
        <!-- phone -->

        <b-col class="mt-3" cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1"
            @click.prevent="sendInformation">
            {{ $t("Save Changes") }}
          </b-button>

        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BFormRadio,
  BCardText,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    BCardText,
    BFormRadio,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => { },
    },
    courseId: {
      type: Number,
      required: true,
    },
  },
  created() {

    //console.log("initial data");
    //console.log( JSON.parse(JSON.stringify(this.informationData.data)));


  },
  data() {
    return {
      currencyOption: ["TRY", "USD", "SAR"],
      countryOption: ["USA", "India", "Canada"],
      localOptions: JSON.parse(JSON.stringify(this.informationData.data)),
      isPaid: "0",
      hasCertainStartDate: "0",
      hasCertainEndDate: "0",
      allowPublicAccess: "0",
      clevePhone: {
        numeral: true

      },
    };
  },
  methods: {
    sendInformation() {
      //console.log(this.localOptions);
      //console.log("update course  is launching with id : " + this.courseId);

      this.isLoading = true;
      axios({
        method: "put",
        url: `/survey/admin/surveydetail/${this.courseId}`,
        data: {
          info: "surveyaccessibility",
          file: null,
          content: this.localOptions,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData.data));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
