<template>
  <b-card>
    <h2 class="text-primary">{{ $t('Lesson Information') }}</h2>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img ref="previewEl" rounded :src="optionsLocal.featuredImage" height="240" alt="" width="480" />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
          :state="Boolean(file)" v-model="file" @click="$refs.refInputEl.$el.click()">
          {{ $t("Upload") }}
        </b-button>
        <b-form-file ref="refInputEl" v-model="profileFile" accept=".jpg, .png, .gif" :hidden="true" plain
          @input="fileSelected" />
        <!--/ upload button -->
        <b-card-text>{{ $t("Allowed JPG, GIF or PNG") }}</b-card-text>
      </b-media-body>
    </b-media>

    <!-- form -->
    <b-form class="mt-2">
      <b-col>
        <b-col cols="6" class="mt-75">
          <b-form-group :label="$t('Lesson Type')" label-for="course-category">
            <b-badge variant="primary">
              {{ optionsLocal.lessonType }}
            </b-badge>
          </b-form-group>
        </b-col>

        <b-row>
          <b-col cols="6">
            <b-form-group :label="$t('Lesson Title')" label-for="course-title">
              <b-form-input v-model="optionsLocal.title" placeholder="Course Title" name="title" />
            </b-form-group>
          </b-col>
          <b-col v-if="optionsLocal.lessonType == 'VIDEO_LESSON'" cols="6">
            <b-form-group :label="$t('Progress Bar Active')" label-for="progress-bar">
              <b-form-checkbox v-model="optionsLocal.IsProgressBarActive">
                {{
                  $t('By enabling this checkbox, you will activate the progress bar for all videos, allowing users to
                track their viewing progress.')
                  }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>


        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1"
            @click.prevent="sendInformation">
            {{ $t("Save Changes") }}
          </b-button>
        </b-col>
      </b-col>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BBadge,
  BFormCheckbox
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from 'vue-select';
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import CategorySelector from "../../course-category/components/CategorySelector.vue";
import AuthorSelector from "../../instructor/AuthorSelector.vue";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    CategorySelector,
    AuthorSelector,
    BFormCheckbox,
    BBadge,
    vSelect
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => { },
    },
    courseId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localOptions: {},
      isLoading: false,
      file: null,
      isUpdated: false,
      optionsLocal: JSON.parse(JSON.stringify(this.generalData.data)),
      profileFile: null,
      isActiveProgressBar: true,
    }
  },
  methods: {
    resetFile() {
      this.file = null;
      this.profileFile = null;
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },

    fileSelected(payload) {
      const formData = new FormData();
      formData.append("file", payload);
      formData.append("courseId", this.courseId);
      formData.append("acc", 3);
      formData.append("platform", "webadmin");
      this.isLoading = true;

      axios
        .post("/upload/admin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response);
          this.optionsLocal.featuredImage = response.data.url;
          this.isUpdated = true;
          this.isLoading = false;
          this.$bvToast.toast(`${response.data.message}`, {
            title: "Success",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          //console.log(error);
          this.isLoading = false;
          this.$bvToast.toast(`${error.response.data.message}`, {
            title: "Error",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "danger",
            solid: true,
          });
        });
    },



    sendInformation() {
      this.isLoading = true;
      axios({
        method: "put",
        url: `/lms/admin/course/updatelessondetail/${this.courseId}`,
        data: {
          info: "general",
          file: null,
          content: this.optionsLocal,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
    },

    uploadFile(paylod) {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
    },
  },
  mounted() { },

  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
