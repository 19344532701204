<template>
  <b-card>
    <h2 class="text-primary">{{ $t('Certification Information') }}</h2>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img ref="previewEl" rounded :src="optionsLocal.featuredImage" height="240" alt="" width="480" />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
          :state="Boolean(file)" v-model="file" @click="$refs.refInputEl.$el.click()">
          {{ $t("Upload") }}
        </b-button>
        <b-form-file ref="refInputEl" v-model="profileFile" accept=".jpg, .png, .gif" :hidden="true" plain
          @input="fileSelected" />
        <!--/ upload button -->

        <!-- reset -->

        <!--/ reset -->
        <b-card-text>{{ $t("Allowed JPG, GIF or PNG") }}</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="12">
          <b-form-group :label="$t('Certificate Title')" label-for="course-title">
            <b-form-input v-model="optionsLocal.title" :placeholder="$t('Certificate Title')" name="title" />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('Code')" label-for="account-name">
            <b-form-input v-model="optionsLocal.certificateCode" name="name" :placeholder="$t('Certificate Code')" />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('Serial')" label-for="account-e-mail">
            <b-form-input v-model="optionsLocal.certificateSerial" name="name"
              :placeholder="$t('Certificate Serial')" />
          </b-form-group>
        </b-col>

      </b-row>


      <b-row>







        <!--/ alert -->

        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1"
            @click.prevent="sendInformation">
            {{ $t("Save Changes") }}
          </b-button>

          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" class="mt-1"
            :disabled="isLoading ? true : false" variant="outline-secondary" @click.prevent="resetFile">
            {{ $t("Reset") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BBadge,
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";



export default {
  components: {
    BBadge,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,



  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => { },
    },
    courseId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      place: null, // optional,
      localOptions: {},
      isLoading: false,
      file: null,
      isUpdated: false,
      optionsLocal: JSON.parse(JSON.stringify(this.generalData.data)),
      profileFile: null,
      location: {
        lat: 41.0082376,
        lng: 28.97835889999999
      },
      options: { // is not required
        map: {/** other map options **/ },
        marker: { /** marker options **/ },
        autocomplete: { /** autocomplete options **/ }
      },
    };
  },
  methods: {
    resetFile() {
      this.file = null;
      this.profileFile = null;
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },

    fileSelected(payload) {
      const formData = new FormData();
      formData.append("file", payload);
      formData.append("courseId", this.courseId);
      formData.append("acc", 3);
      formData.append("platform", "webadmin");
      this.isLoading = true;

      axios
        .post("/upload/admin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response);
          this.optionsLocal.featuredImage = response.data.url;
          this.isUpdated = true;
          this.isLoading = false;
          this.$bvToast.toast(`${response.data.message}`, {
            title: "Success",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          //console.log(error);
          this.isLoading = false;
          this.$bvToast.toast(`${error.response.data.message}`, {
            title: "Error",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "danger",
            solid: true,
          });
        });
    },

    authorChanged(payload) {
      //console.log(payload);
      this.optionsLocal.ClientInstructors = Array.isArray(payload)
        ? payload
        : [payload];

      //console.log(this.optionsLocal);
    },

    courseModeUpdated(payload) {
      //console.log("Catefy result in info ");
      //console.log(payload);
      this.optionsLocal.coursemode = payload.name;
    },

    courseCategoryUpdated(payload) {
      //console.log("Catefy result in info ");
      //console.log(payload);
      this.optionsLocal.CourseCategories = Array.isArray(payload)
        ? payload
        : [payload];
    },

    sendInformation() {
      //console.log(this.optionsLocal);
      //console.log("update course  is launching with id : " + this.courseId);


      this.isLoading = true;
      axios({
        method: "put",
        url: `/lms/admin/course/updatecertificatedetail/${this.courseId}`,
        data: {
          info: "general",
          file: null,
          content: this.optionsLocal,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
    },

    uploadFile(paylod) {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
    },
  },
  mounted() {

  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
