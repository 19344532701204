<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <label>entries</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search...')" />
              <b-button @click="newCategory" variant="primary">
                <span class="text-nowrap">{{ $t("Add Category") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card style="padding: 0rem">
      <b-card-body style="padding: 0rem">
        <b-table class="position-relative" style="text-align: center; padding: 0rem" :items="results" responsive
          :busy="loading" :fields="columns" primary-key="id" show-empty :empty-text="$t('No matching records found')">
          <template #table-busy>
            <div class="text-center text-danger">
              <loader />
            </div>
          </template>
          <template style="padding: 0rem" #cell(key)="data">
            <b-media vertical-align="center">
              <template #aside>
              </template>
              <span>
                {{ data.item.categorykey }}
              </span>
            </b-media>
          </template>
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <template #aside>
              </template>
              <b-link @click="toggleShowCategory" class="font-weight-bold d-block text-nowrap">
                {{ data.item.title }}
              </b-link>
            </b-media>
          </template>
          <template #cell(action)="data">
            <b-button style="padding:0.5rem" type="submit" variant=".btn-flat-dark" @click="changeCategory(data.item)">
              <edit-icon size="1.5x" class="custom-class text-primary"></edit-icon>
            </b-button>
            <b-button style="padding:0.5rem" variant=".btn-flat-dark" @click="disable(data.item.id)"><x-circle-icon
                size="1.5x" class="custom-class text-warning"></x-circle-icon>
            </b-button>

            <b-button style="padding:0.5rem" variant=".btn-flat-dark" @click="deleteCategory(data.item)">
              <trash-2-icon size="1.5x" class="custom-class text-danger"></trash-2-icon>
            </b-button>
          </template>
        </b-table>
        <add-new @handle="handle" :isVisible.sync="isVisible" :selectedCategory="selectedCategory"
          @addFeaturedImage="addFeaturedImage" :imageUrl="featuredImage" />
      </b-card-body>

      <b-pagination style="align-items: flex-end; flex: 1; justify-content: end" v-model="currentPage"
        :total-rows="rows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item"
        next-class="next-item">
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BCol,
  BRow,
  BFormInput,
  BButton,
  BCard,
  BCardBody,
  BMedia,
  BAvatar,
  BFormSelect,
  BLink,
  BFormFile,
  BPagination,
  BCardText,
  BMediaBody,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useCategoryList from "./useCategoryList";
import { ref } from "@vue/composition-api";
import AddNew from "./AddNew.vue";
import { deleteCall, getCall, postCall, putCall } from "../Service";
import Loader from "../components/Loader.vue";
import Swal from "sweetalert2";
import axios from "axios";

import {
  Trash2Icon,
  EditIcon,
  EyeOffIcon,
  XCircleIcon,
  EyeIcon,
  ExternalLinkIcon,
} from "vue-feather-icons";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTable,
    BCol,
    BLink,
    XCircleIcon,
    BAvatar,
    BPagination,
    BMedia,
    BRow,
    AddNew,
    vSelect,
    BFormInput,
    BButton,
    BFormSelect,
    BCardBody,
    BCard,
    BFormFile,
    BCardText,
    BMediaBody,
    BForm,
    Loader,
    Trash2Icon,
    EditIcon,
    EyeOffIcon,
    EyeIcon,
    ExternalLinkIcon,
  },
  data() {
    return {
      skip: 0,
      rows: 0,
      searchQuery: "",
      pageSeries: [],

      perPage: 10,
      currentPage: 1,
      featuredImageFile: null,
      selectedCategory: {
        id: null,
        upperCategory: "",
        slug: "",
        title: null,
        description: null,
        categoryKey: null,
        isSpecial: false,
      },
      addedData: {
        title: null,
        description: null,
        upperCategory: "",
      },
      upperCategories: [],
      featuredImage: "",
      items: [],
      showCategory: false,
      loading: false,
    };
  },
  created() { },
  async mounted() {
    await this.getAllCategories(this.skip, this.perPage, "");
  },

  computed: {
    results() {
      if (this.searchQuery) {
        const temp = this.items.filter((i) =>
          i.title.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
        return temp;
      } else {
        return this.items;
      }
    },
  },
  watch: {
    currentPage: function (newVal, old) {
      const series = this.pageSeries.find((i) => i.p == newVal);
      this.selectedRecords = series.s;
      this.getAllCategories(series.s, this.perPage, "");
    },
    rows: function () {
      let pages = this.rows / 10;
      if (pages != 0) {
        if (this.rows > 10) {
          pages = pages + 1;
        }
      }
      this.pageSeries = [];
      this.skip = 0;
      for (let i = 1; i <= pages; i++) {
        this.pageSeries.push({ s: this.skip, p: i });
        this.skip = this.skip + 10;
      }
    },
    searchQuery: function (newVal) {
      this.getAllCategories(0, this.perPage, newVal ? newVal : "");
    },
  },
  methods: {
    async disable(id) {
      Swal.fire({
        title: "Do you want to disable the product?",
        showCancelButton: true,
        confirmButtonText: "Disable",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await putCall(`admin/store/category/disable/${id}`);
            await this.getAllCategories(0, 10, '');
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Product Disabled Successfully`,
                icon: "CoffeeIcon",
                variant: "warning",
              },
            });
          } catch (error) {
            Swal.fire("Error", error, "error");
          }
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    },
    toggleShowCategory() {
      this.showCategory = !this.showCategory;
      this.$emit("setShowCategoryVal", this.showCategory);
    },
    async addFeaturedImage(event) {
      this.featuredImageFile = event.item;
      let imageData = new FormData();
      imageData.append("file", event.item);
      try {
        var host = process.env.VUE_APP_API_URL;

        const response = await axios.post(
          host + "/upload/admin",
          imageData,
          {
            headers: {
              "client-auth-token": localStorage.getItem("clientToken"),
              "content-type": "multipart/form-data",
              "x-access-token": localStorage.getItem("accessToken"),
            },
          }
        );
        this.featuredImage = response.data.url;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Image Uploaded Successfully`,
            icon: "CoffeeIcon",
            variant: "success",
          },
        });
      } catch (error) {
        if (error.response) {
          Swal.fire("Error", error.response.data.message, "error");
        } else {
          Swal.fire("Error", "Error uploading image", "error");
        }
      }
    },
    async getAllCategories(skip, take, search) {
      this.loading = true;
      try {
        const { data } = await getCall(
          `admin/store/category/all?skip=${skip}&take=${take}&keyword=${search}`
        );
        this.items = data.data;
        this.rows = data.total;
      } catch (error) {
        if (error.response) {
          Swal.fire("Error", error.response.data.message, "error");
        } else {
          Swal.fire("Error", error, "error");
        }
      } finally {
        this.loading = false;
      }
    },
    async handle(e) {
      this.addedData = {
        ...this.addedData,
        upperCategory: e.item.upperCategory ? e.item.upperCategory : "",
        status: 1,
        title: e.item.title,
        slug: e.item.slug,
        description: e.item.description ? e.item.description : "",
        categorykey: e.item.categoryKey ? e.item.categoryKey : null,
        isSpecial: e.item.isSpecial,
        featuredImage: this.featuredImage,
      };
      try {
        if (e.item.id) {
          Swal.fire({
            title: "Do you want to update category?",
            showCancelButton: true,
            confirmButtonText: "Update",
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                await putCall(`admin/store/category/${e.item.id}`, this.addedData);
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Category Updated Successfully`,
                    icon: "CoffeeIcon",
                    variant: "success",
                  },
                });
                this.isVisible = false;

                await this.getAllCategories(0, 10, '');
              } catch (error) {
                if (error.response) {
                  Swal.fire("Error", error.response.data.message, "error");
                } else {
                  Swal.fire("Error", error, "error");
                }
              }
            } else if (result.isDenied) {
              Swal.fire("Changes are not saved", "", "info");
            }
          });
        } else if (this.addedData.title != null) {
          try {
            await postCall("admin/store/category/new", this.addedData);
            await this.getAllCategories(0, 10, '');
            this.isVisible = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Category Created Successfully`,
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
          } catch (error) {
            if (error.response) {
              Swal.fire("Error", error.response.data.message, "error");
            } else Swal.fire("Error", "Error Creating Category.", "error");
          }
        } else {
          this.isVisible = true;
        }
      } catch (error) {
        Swal.fire("Error", error, "error");
      }
    },

    async changeCategory(item) {
      this.selectedCategory = { ...this.selectedCategory, ...item };
      this.featuredImage = item.featuredImage;
      this.selectedCategory.categoryKey = item.categorykey;
      this.selectedCategory.isSpecial = item.isSpecial ? true : false;
      this.isVisible = true;
    },
    newCategory() {
      this.selectedCategory = {
        id: null,
        upperCategory: null,
        status: null,
        slug: "",
        title: null,
        description: null,
        categoryKey: null,
        isSpecial: 0,
      };
      this.featuredImage = "";
      this.isVisible = true;
    },
    async deleteCategory(e) {
      Swal.fire({
        title: "Do you want to delete this category?",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteCall(`admin/store/category/${e.id}`);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Product Deleted Successfully`,
                icon: "CoffeeIcon",
                variant: "danger",
              },
            });
            this.getAllCategories(0, 10, '');
          } catch (error) {
            Swal.fire("Error", error, "error");
          }
          this.items = this.items.filter((i) => i.id != e.id);
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    },
  },

  setup() {
    const { columns } = useCategoryList();
    const isVisible = ref(false);

    return {
      columns,
      isVisible,
    };
  },
};
</script>
<style lang="scss">
.swal2-confirm {
  margin-right: 10px !important;
}
</style>
