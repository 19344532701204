<template>
  <div class="main-menu menu-fixed menu-accordion menu-shadow" :class="[
    { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
    skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
  ]" @mouseenter="updateMouseHovered(true)" @mouseleave="updateMouseHovered(false)">
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo">
                <b-img :src="appLogoImage" alt="logo" />
              </span>
              <!-- <h2 class="brand-text">
                {{ appName }}
              </h2> -->
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon icon="XIcon" size="20" class="d-block d-xl-none" @click="toggleVerticalMenuActive" />
              <feather-icon :icon="collapseTogglerIconFeather" size="20" class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed" />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="main-menu-content scroll-area" tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }">
      <vertical-nav-menu-items :items="navMenuItems" class="navigation navigation-main" />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import basenavMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName } = $themeConfig.app

    const appLogoImage = localStorage.getItem('clientLogo')
    const appnavMenuItems = JSON.parse(localStorage.getItem("projectSettings"));


    // //console.log(navMenuItems);
    // //console.log("*********************");

    // //console.log("Navloaded");
    const arr = [
      {
        icon: 'FileTextIcon',
        route: 'store',
        title: 'Store Options',
      },
      {
        icon: 'FileTextIcon',
        route: 'invoice-list',
        title: 'Invoice List',
      },
      {
        header: 'Invoice',
      },
      {
        icon: 'BoxIcon',
        route: 'customer-list',
        title: 'Customers',
      },
      {
        icon: '',
        route: 'tag-list',
        title: 'Tags List'
      },
      {
        icon: '',
        route: 'brand-list',
        title: 'Brands List'
      },
      {
        icon: 'BoxIcon',
        route: 'category-list',
        title: 'Product Categories',
      },
      {
        icon: 'ShoppingBagIcon',
        route: 'product-list',
        title: 'Products',
      },
      {
        icon: '',
        route: 'cart-list',
        title: 'Carts',
      },
      {
        icon: '',
        route: 'orders-list',
        title: 'Orders',
      },
      {
        header: 'Store',
      },
    ];

    var home = {
      icon: 'HomeIcon',
      route: 'home',
      title: 'Home',
    };


    const navMenuItems = [home, ...appnavMenuItems, ...basenavMenuItems]
    arr.forEach((i) => {
      //  navMenuItems.splice(1, 0, i);
    });

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 100%;
  max-height: 37px !important;
}
</style>
