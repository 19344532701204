<template>

  <div>
    <lesson-list-add-new v-if="lectureList" :key="lectureList.length"  :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :orderIndex="lectureList.length" />

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <h5> {{ lecturetitle }} </h5>
          </b-col>

          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" @click="newLesson">
                <span class="text-nowrap">{{ $t("Create Lesson") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <draggable v-model="lectureList" class="list-group list-group-flush cursor-move" tag="ul">
          <transition-group type="transition" name="flip-list">
            <b-list-group-item v-for="(listItem, index) in lectureList" :key="listItem.id" tag="li">
              <div class="d-flex">
                <div md="1" class="ml-25 col-1">
                  <h4>{{ index + 1 }} </h4>
                </div>
                <div md="5" class="col-5">
                  <b-avatar :text="(listItem.lessonorder).toString()" />
                  <span style="margin-left: 10px;">{{ capitalizeFirstLetter(listItem.title) }}</span>

                </div>

                <div md="3" class="ml-25 col-3">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50"> {{ listItem.lessonType }} </span>
                </div>

                <div md="4" class="ml-25 col-4">
                  <b-link
                    :to="{ name: 'course-lecturelessons-edit', params: { id: listItem.id, type: listItem.lessonType } }"
                    class="font-weight-bold text-nowrap">
                    <b-button variant="flat-info">
                      <span class="align-middle ml-50">{{ $t("Edit") }}</span>
                    </b-button>
                  </b-link>
                  <b-badge variant="light-danger" v-if="Boolean(listItem.deleted)">
                    {{ $t("Deleted") }}
                  </b-badge>

                  <b-button variant="flat-danger" @click="deleteLesson(listItem.id)">
                    <span class="align-middle ml-50">{{ $t("Delete") }} </span>
                  </b-button>
                </div>
              </div>
            </b-list-group-item>
          </transition-group>
        </draggable>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BListGroupItem, BAvatar
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted, computed, WritableComputedRef } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './LectureLessonsListFilters.vue'
import useUsersList from './useLectureLessonsList'
import userStoreModule from '../lecturelessonsStoreModule'
import LessonListAddNew from './LectureLessonsListAddNew.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  data() {
    return {
      selectedLesson: null,
    }
  },
  watch: {
    isAddNewUserSidebarActive: function (newVal, coldVal) {
      this.refetchData()
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    newLesson() {
      this.selectedLesson = {
        title: null,
        prefix: null,
        estimatedDurationMinutes: 5,
        order: 1,
        lessonType: null,
      }
      this.isAddNewUserSidebarActive = true;
    },
    editLesson(item) {
      this.localOptions = item;
    },
    deleteLesson(data) {
      store.dispatch('course-lecture-lessons/deleteLesson', { id: data }).then((response) => {
        this.$emit('refetch-data')
      })
    }
  },

  components: {
    UsersListFilters,
    LessonListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    draggable,
    BListGroupItem,
  },
  setup() {
    const toast = useToast()
    const USER_APP_STORE_MODULE_NAME = 'course-lecture-lessons'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const sessionsData = ref(null);

    const lecturetitle = ref(router.currentRoute.params.lecturetitle);

    const lectureid = ref(router.currentRoute.params.lectureid);
    const routecourseguid = ref(router.currentRoute.params.id);


    var activeId = ref(null);
    if (router.currentRoute.params.lectureid) {
      //console.log("using from route");
      activeId.value = router.currentRoute.params.lectureid;
    }
    else {
      //console.log("using from getter");
      var unit = store.getters['app/currentLecture'];
      activeId.value = unit;

    }

    store.commit('app/SETACTIVELECTURE', activeId.value)

    const courseguidval = ref(router.currentRoute.params.id);
    const isAddNewUserSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]



    const {
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
      courseguid,
      lectures
    } = useUsersList(activeId)

    const lectureList = computed({
      get() {
        return lectures.value;
      },

      set(oldValue) {
        // set something
        //console.log("updated");
        lectures.value = oldValue;
        var newORders = [];

        var index = 0;

        lectures.value.forEach(element => {
          newORders.push({ id: element.id, order: index });
          index++;

        });
        //console.log(newORders);
        reOrderLessons(newORders);
      },
    });
    const reOrderLessons = (data) => {

      var postData = {};
      postData.act = "REORDER";
      postData.orders = data;


      postData.lectureid = router.currentRoute.params.lectureid;
      postData.courseguid = router.currentRoute.params.id;


      store
        .dispatch('course-lecture-lessons/reOrderLessons',
          postData,
        )
        .then(response => {
          refetchData();
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching courses list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    }
    refetchData();

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      // Filter
      avatarText,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,

      statusOptions,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
      courseguid,
      lectures,
      lectureList,
      lectureid,
      lecturetitle,
      routecourseguid,
      reOrderLessons
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
