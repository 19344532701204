<template>
  <b-card>
    <!-- form -->

    <b-form>
      <h6 class="section-label  mt-1">Pricing</h6>
      <b-row>
        <!--/ birth date -->
        <b-col md="12">
          <div class="demo-inline-spacing mb-1">
            <b-form-radio v-model="localOptions.isPaid" name="some-radios" v-bind:value="false">
              Free
            </b-form-radio>
            <b-form-radio v-model="localOptions.isPaid" name="some-radios" v-bind:value="true">
              Paid
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="localOptions.isPaid == true">
        <!--/ birth date -->
        <b-col md="2">
          <b-form-group label-for="phone" label="Course Price">
            <cleave id="phone" v-model="localOptions.price" class="form-control" :raw="false" :options="clevePhone"
              placeholder="Price" />
          </b-form-group>
        </b-col>

      </b-row>

      <h6 class="section-label mt-2 ">Access Capacity</h6>
      <b-row>
        <!--/ birth date -->
        <b-col md="12">
          <div class="demo-inline-spacing mb-1">
            <b-form-radio v-model="localOptions.hasApplyLimit" name="some-radioslmt" v-bind:value="false">
              No. Unlimited Application
            </b-form-radio>
            <b-form-radio v-model="localOptions.hasApplyLimit" name="some-radioslmt" v-bind:value="true">
              Yes. The course has application limit.
            </b-form-radio>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="localOptions.hasApplyLimit == true">
        <!--/ birth date -->
        <b-col md="2">
          <b-form-group label-for="phone" label="Application Capacity">
            <cleave id="phone" v-model="localOptions.applyCapacity" class="form-control" :raw="false"
              :options="clevePhone" placeholder="Price" />
          </b-form-group>
        </b-col>

      </b-row>

      <h6 class="section-label mt-2   mb-2">Assignment</h6>
      <b-row>

        <b-col class="mb-1" md="12">
          <b-form-radio v-model="localOptions.allowPublicAccess" name="access-radios" v-bind:value="true">
            Public Access
          </b-form-radio>

          <b-card-text class="m-1" v-if="localOptions.allowPublicAccess == true">
            The course is allowed <code>Everyone</code> access and register.
          </b-card-text>
        </b-col>

        <b-col class="mb-1" md="12">
          <b-form-radio v-model="localOptions.allowPublicAccess" name="access-radios" v-bind:value="false">
            Only Assignees
          </b-form-radio>
          <b-card-text class="m-1" v-if="localOptions.allowPublicAccess == false">
            Only assigned <code>groups or users </code> can access the course.
          </b-card-text>
        </b-col>

        <!-- website -->
      </b-row>

      <h6 class="section-label mt-1  mb-1">Accesibility
        <feather-icon icon="AlertTriangleIcon" size="18" class="mr-50 ml-1 text-danger" />
      </h6>
      <small class="mb-1 text-danger"
        v-if="!Boolean(localOptions.hasExactRegistrationStartDate) || !Boolean(localOptions.hasExactRegistrationEndDate)">Accessibiity
        Dates are required</small>
      <b-row class="mb-1 mt-1">

        <b-col md="6">

          <b-form-checkbox v-model="localOptions.hasExactRegistrationStartDate" name="check-button" switch inline>
            <b-card-text>
              Course has exact registration start date. If you turn this on, course registration will start on this date
            </b-card-text> </b-form-checkbox>
        </b-col>
        <b-col md="6">
          <b-form-checkbox v-model="localOptions.hasExactRegistrationEndDate" name="check-button" switch inline>
            <b-card-text>
              Course has exact registration end date. If you turn this on, course registration will end on this date

            </b-card-text>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>

        <b-col md="6">
          <b-form-group label-for="example-datepicker" label="Start date">
            <flat-pickr v-model="localOptions.registrationStartDate" class="form-control" name="registrationStartDate"
              placeholder="Start date" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label-for="example-datepicker" label="Expire date">
            <flat-pickr v-model="localOptions.registrationEndDate" class="form-control" name="registrationEndDate"
              placeholder="Expire date" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-1">

        <b-col md="6">

          <b-form-checkbox v-model="localOptions.hasExactVisibilityStartDate" name="check-button" switch inline>
            <b-card-text>
              Course has exact visibility date. If you turn this on, course will be visible on this date

            </b-card-text> </b-form-checkbox>
        </b-col>
        <b-col md="6">
          <b-form-checkbox v-model="localOptions.hasExactVisibilityEndDate" name="check-button" switch inline>
            <b-card-text>
              Course has exact visibility end date. If you turn this on, course will be invisible on this date
            </b-card-text>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>

        <b-col md="6">
          <b-form-group label-for="example-datepicker" label="Start date">
            <flat-pickr v-model="localOptions.visibiltyStartDate" class="form-control" name="visibiltyStartDate"
              placeholder="Start date" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label-for="example-datepicker" label="Expire date">
            <flat-pickr v-model="localOptions.visibiltyEndDate" class="form-control" name="visibiltyEndDate"
              placeholder="Expire date" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>

        <b-col md="6">

          <b-form-checkbox v-model="localOptions.hasExactOccurStartDate" name="check-button" switch inline>
            <b-card-text>
              Course has exact occur date.This is for the course to occur on a specific date.Like live stream or exam.
            </b-card-text> </b-form-checkbox>
        </b-col>
        <b-col md="6">
          <b-form-checkbox v-model="localOptions.hasExactOccurEndDate" name="check-button" switch inline>
            <b-card-text>
              Course has exact end occuring end date .This is for the course to occur on a specific date.Like live
              stream or exam.
            </b-card-text>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>

        <b-col md="6">
          <b-form-group label-for="example-datepicker" label="Start date">
            <flat-pickr v-model="localOptions.actualOccurStartDate" class="form-control" name="actualOccurStartDate"
              placeholder="Start date" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label-for="example-datepicker" label="Expire date">
            <flat-pickr v-model="localOptions.actualOccurEndDate" class="form-control" name="actualOccurEndDate"
              placeholder="Expire date" />
          </b-form-group>
        </b-col>
      </b-row>

      <!--/ website -->

      <b-row>
        <!-- phone -->

        <b-col class="mt-3" cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1"
            @click.prevent="sendInformation">
            {{ $t("Save Changes") }}
          </b-button>

        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BFormRadio,
  BCardText,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    BCardText,
    BFormRadio,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => { },
    },
    courseId: {
      type: Number,
      required: true,
    },
  },
  created() {

    //console.log("initial data");
    //console.log( JSON.parse(JSON.stringify(this.informationData.data)));


  },
  data() {
    return {
      currencyOption: ["TRY", "USD", "SAR"],
      countryOption: ["USA", "India", "Canada"],
      localOptions: JSON.parse(JSON.stringify(this.informationData.data)),
      isPaid: "0",
      hasCertainStartDate: "0",
      hasCertainEndDate: "0",
      allowPublicAccess: "0",
      clevePhone: {
        numeral: true

      },
    };
  },
  methods: {
    sendInformation() {
      //console.log(this.localOptions);
      //console.log("update course  is launching with id : " + this.courseId);

      this.isLoading = true;
      axios({
        method: "put",
        url: `/lms/admin/course/updatecoursedetail/${this.courseId}`,
        data: {
          info: "pricingandavailability",
          file: null,
          content: this.localOptions,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData.data));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
