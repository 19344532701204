<template>
    <div class="upload-videos">
        <b-row>
            <b-col cols="3">
                <div class="video-dropzone" ref="videodropzone">
                    <div class="dropzone-display">
                        <div class="p-2">
                            <h5>{{ $t('Drop or click here to upload your video') }}</h5>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col style="text-align: center;">
                <uploading-video v-for="(file, index) in files" v-bind:key="file.file.uniqueIdentifier + index"
                    :file="file.file" :errorMessage="errorMessage" :status="file.status" :progress="file.progress"
                    @cancel="cancelFile" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import Resumable from 'resumablejs'
import { BRow, BCol } from "bootstrap-vue"
import UploadingVideo from './UploadingVideo.vue'


export default {
    components: {
        UploadingVideo,
        BRow, BCol,
    },
    data() {
        return {
            errorMessage: '',
            files: [], // our local files array, we will pack in extra data to force reactivity
            r: false,
        }
    },
    methods: {
        // finds the file in the local files array
        findFile(file) {
            return this.files.find(item => item.file.uniqueIdentifier === file.uniqueIdentifier && item.status !== 'canceled') ?? {}
        },
        // cancel an individual file
        cancelFile(file) {
            this.findFile(file).status = 'canceled'
            file.cancel()
        },
    },
    mounted() {
        // init resumablejs on mount
        this.r = new Resumable({
            target: 'https://apibase.niso.dev/upload/adminV2',//'http://localhost:8080/adminV2'
            query: { upload_token: 'my_token' },
            maxChunkRetries: 3,
            fileType: ['mp4', 'mov', 'avi'],
            testChunks: false,
            headers: {
                ...axiosIns.defaults.headers.common,
                'x-access-token': localStorage.getItem('accessToken'),
                'client-auth-token': localStorage.getItem('clientToken'),
            },
        });

        // Resumable.js isn't supported, fall back on a different method
        if (!this.r.support) return alert('Your browser doesn\'t support chunked uploads. Get a better browser.');

        this.r.assignBrowse(this.$refs.videodropzone);
        this.r.assignDrop(this.$refs.videodropzone);

        // set up event listeners to feed into vues reactivity
        this.r.on('fileAdded', (file, event) => {
            file.hasUploaded = false
            console.log('this.files', this.files)
            // keep a list of files with some extra data that we can use as props
            this.files.push({
                file,
                status: 'uploading',
                progress: 0
            })
            this.r.upload()
        })
        this.r.on('fileSuccess', (file, event) => {
            this.findFile(file).status = 'success'
            this.$emit("onFileUploadSuccess", JSON.parse(event))
        })
        this.r.on('fileError', (file, event) => {
            this.findFile(file).status = 'error'
            this.errorMessage = (JSON.parse(event)).message
        })
        this.r.on('fileRetry', (file, event) => {
            this.findFile(file).status = 'retrying'
        })
        this.r.on('fileProgress', (file) => {
            // console.log('fileProgress', progress)
            const localFile = this.findFile(file)
            // if we are doing multiple chunks we may get a lower progress number if one chunk response comes back early
            const progress = file.progress()
            if (progress > localFile.progress) {
                localFile.progress = progress
            }

        })
    }
}
</script>

<style lang="scss">
.p-5 {
    padding: 3rem;
}

.upload-videos {
    display: flex;
    flex-direction: column;
}

.video-dropzone {
    height: 150px;
    width: 200px;
    padding: 12px;
    display: flex;
    align-self: center;
    margin-bottom: 1.2rem;
    cursor: pointer;

    * {
        pointer-events: none;
    }

    .dropzone-display {
        height: 85%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 3px dashed #222;
        border-radius: 32px;

        img {
            width: 10px;
        }

        small {
            font-size: 0.35em;
            display: block;
        }
    }
}
</style>
