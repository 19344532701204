<template>
  <b-sidebar id="add-new-user-sidebar" :visible="isAddNewUserSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t("Add New Lesson") }}
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)">

          <!-- Full Name -->
          <validation-provider #default="validationContext" name="Lesson Title" rules="required">
            <b-form-group :label="$t('Lesson Title')" label-for="course-name">
              <b-form-input id="full-name" v-model="lessonData.title" autofocus
                :state="getValidationState(validationContext)" trim placeholder="Awesome lesson" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider #default="validationContext" name="Lesson Prefix" rules="required">
            <b-form-group :label="$t('Lesson Prefix')" label-for="course-name">
              <b-form-input id="full-name" v-model="lessonData.prefix" autofocus trim placeholder="Lesson"
                :state="getValidationState(validationContext)" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Lesson Duration" rules="required">
            <b-form-group :label="$t('Lesson Duration')" label-for="course-name">
              <b-form-input id="full-name" v-model="lessonData.estimatedDurationMinutes" autofocus trim type="number"
                placeholder="120" :state="getValidationState(validationContext)" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider #default="validationContext" name="Lesson Order" rules="required">
            <b-form-group :label="$t('Lesson Order')" label-for="course-name">
              <b-form-input id="full-name" v-model="lessonData.order" autofocus trim placeholder="2" type="number"
                :state="getValidationState(validationContext)" disabled />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- User Role -->

          <validation-provider #default="validationContext" name="Lesson Type" rules="required">
            <b-form-group :label="$t('Lesson Type')" label-for="user-role"
              :state="getValidationState(validationContext)">
              <course-type-picker ref="typePicker" @selectionChanged="lessonTypeUpdated" />
              <b-form-input id="lessonType-name" v-model="lessonData.lessonType" hidden />
              <b-form-invalid-feedback :state="validationContext.errors.length > 0 ? false : null">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
          <validation-provider v-if="lessonData.lessonType === 'VIDEO_LESSON'" #default="validationContext"
            rules="required" name="Progress Bar Active ">
            <b-form-group label-for="progress-bar">
              <b-form-checkbox v-model="lessonData.IsProgressBarActive">
                {{ $t('Progress Bar Active') }}
              </b-form-checkbox>
            </b-form-group>

          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              {{ $t("Add") }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              {{ $t("Cancel") }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'

import router from '@/router'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import CourseTypePicker from '../components/LectureLessonsTypePicker.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    vSelect,
    CourseTypePicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    orderIndex: { type: Number },
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  watch: {
  },
  methods: {
    lessonTypeUpdated(payload) {
      if (payload == null) {
        this.lessonData.lessonType = null;
      } else {
        this.lessonData.lessonType = payload.typekey;
      }
    },
  },
  setup(props, { emit }) {
    const typePicker = ref(null);
    const blankLessonData = {
      title: null,
      prefix: null,
      estimatedDurationMinutes: 5,
      order: props.orderIndex + 1,
      lessonType: null,
      IsProgressBarActive: true
    }

    const lessonData = ref(JSON.parse(JSON.stringify(blankLessonData)))
    const resetuserData = () => {
      lessonData.value = JSON.parse(JSON.stringify(blankLessonData))
    }

    const onSubmit = () => {
      const incomingcourse = router.currentRoute.params.id;
      lessonData.value.courseguid = incomingcourse;
      lessonData.value.lectureid = router.currentRoute.params.lectureid;
      store.dispatch('course-lecture-lessons/addCourse', lessonData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)   
        })
        // typePicker.value.resetSelected()
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      lessonData,
      onSubmit,
      typePicker,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
