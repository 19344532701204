<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        Course Settings
      </h6>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox id="accountSwitch1" :checked="localOptions.NEEDS_TO_COMPLETE_EACH_TOPIC" name="check-button"
          v-model="localOptions.NEEDS_TO_COMPLETE_EACH_TOPIC" switch inline>
          <span>User needs to complete each topic to pass next topic</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox id="accountSwitch2" :checked="localOptions.NEEDS_TO_COMPLETE_ALL_VIDEOS"
          v-model="localOptions.NEEDS_TO_COMPLETE_ALL_VIDEOS" name="check-button" switch inline>
          <span>All videos needed to be completed</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox id="accountSwitch3" :checked="localOptions.NEEDS_TO_COMPLETE_ALL_TOPICS"
          v-model="localOptions.NEEDS_TO_COMPLETE_ALL_TOPICS" name="check-button" switch inline>
          <span>All topics needed to be completed</span>
        </b-form-checkbox>
      </b-col>

      <!-- application switch -->

      <b-col cols="12" class="mt-1 mb-2">
        <b-form-checkbox id="accountSwitch4" :checked="localOptions.NEEDS_TO_COMPLETE_ALL_EXAMS"
          v-model="localOptions.NEEDS_TO_COMPLETE_ALL_EXAMS" name="check-button" switch inline>
          <span>All quizes are needed to be completed</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox id="accountSwitch5" :checked="localOptions.DISABLE_ACCESS_AFTER_EXPIRES_FOR_REGISTERED_COURSES"
          v-model="localOptions.DISABLE_ACCESS_AFTER_EXPIRES_FOR_REGISTERED_COURSES" name="check-button" switch inline>
          <span>Disable access after expire for registered users.</span>
        </b-form-checkbox>
      </b-col>

      <!--/ application switch -->

      <!-- buttons -->
      <b-col cols="12">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1 mt-1"
          :disabled="isLoading ? true : false" @click.prevent="sendInformation">
          {{ $t("Save Changes") }}
        </b-button>

      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    notificationData: {
      required: false,
    },
    courseId: {
      type: Number,
      required: true,
    },
  },
  methods: {

    sendInformation() {

      //console.log("update course  is launching with id : "+this.courseId );

      this.isLoading = true;
      axios({
        method: 'put',
        url: `/lms/admin/course/updatecoursedetail/${this.courseId}`,
        data: {
          info: "coursePreferences",
          content: this.localOptions
        }
      }).then((r) => {

        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
        this.isLoading = false;
        this.isUpdated = false;

      }

      )
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        }
        );
      return;


    },
  },
  data() {
    return {
      localOptions: {},
      isLoading: false,
      isUpdated: false,
    }
  },
  beforeDestroy() {

  },

  beforeMount() {
    //console.log(`At this point, vm.$el has not been created yet.`)
  },
  mounted() {

    if (this.notificationData == null || this.notificationData == "" || this.notificationData == undefined) {
      //console.log("notification data is null");

      this.localOptions = {
        "NEEDS_TO_COMPLETE_EACH_TOPIC": false,
        "NEEDS_TO_COMPLETE_ALL_VIDEOS": false,
        "NEEDS_TO_COMPLETE_ALL_TOPICS": false,
        "NEEDS_TO_COMPLETE_ALL_EXAMS": false,
        "DISABLE_ACCESS_AFTER_EXPIRES_FOR_REGISTERED_COURSES": false,
      }

    }
    else {
      //console.log("notification   ");
      //console.log(this.notificationData);
      this.localOptions = JSON.parse(this.notificationData);

    }

    //   //console.log('this is current quill instance object', this.editor)
  },
}
</script>
