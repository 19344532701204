<template>
  <div>
    <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
      :plan-options="planOptions" @refetch-data="refetchData" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0" v-if="surveyInfo != null && surveyInfo != undefined">
      <div class="m-2">
        <!-- Table Top -->
        <h2>
          {{ itemtitle }}
        </h2>
        <b-row>
          <!-- Per Page -->
          <b-col cols="4" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Show') }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('entries') }}</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="3">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search...')" />

            </div>
          </b-col>
          <!-- Search -->
          <b-col cols="8" md="5">
            <b-row :key="currentId">
              <b-col cols="8">
                <member-picker :searchOptions="searchOptions" @selectionChanged="selectionChanged">
                </member-picker>
              </b-col>
              <b-col cols="3">
                <b-button variant="primary" v-if="canAddnewAttender" @click="addSelectedMemberToSurvey">
                  <span class="text-nowrap">Add to Survey</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refUserListTable" class="position-relative" :items="fetchCourses" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc">
                    <template #head()="data">
              <span class="text">{{ $t(data.label.toUpperCase()) }}</span>
            </template>
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.avatar" :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }" />
            </template>
            <b-link :to="{ name: 'SurveyEdit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap">
              {{ data.item.title }}
            </b-link>
            <small class="text-muted">{{ data.item.courseCode }}</small>
          </b-media>
        </template>

        <template #cell(surveyStatus)="data">
          <b-badge pill :variant="`${resolveSurveyStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ resolveSurveyStatus(data.item.status) }}

            {{ data.item.status }}
          </b-badge>
        </template>




        <template #cell(attendees)="data">
          <b-link :to="{
            name: 'partipiciants-bycourse-page',
            params: { id: data.item.courseguid },
          }" class="font-weight-bold d-block text-nowrap">
            <feather-icon :icon="resolveUserRoleIcon(data.item.role)" size="18" class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`" />
            Attendees
          </b-link>
        </template>

        <template #cell(id)="data">
          <b-link :to="{ name: 'SurveyQuestions', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap">
            Questions
          </b-link>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data" v-if="surveyInfo != null && surveyInfo != undefined && surveyInfo.status === 0">
          <b-badge variant="light-secondary" v-if="Boolean(data.item.deleted)">
            Deleted
          </b-badge>
          <b-button v-if="!Boolean(data.item.deleted)" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger"
            @click="deleteExam(data.item)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">{{ $t("Delete") }}</span>
          </b-button>
        </template>

        <template #cell(surveyLink)="data">
          <b-badge variant="light-secondary" v-if="Boolean(data.item.deleted)">
            Deleted
          </b-badge>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-info" v-if="!Boolean(data.item.deleted)"
            @click="withHtml(data.item)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Show Survey URL</span>
          </b-button>
        </template>



      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "./SurveyattendeeListFilters.vue";
import useUsersList from "./useSurveyattendeeList";
import userStoreModule from "../surveyattendeeStoreModule";
import UserListAddNew from "./SurveyattendeeListAddNew.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MemberPicker from "@/views/members/component/MemberPicker.vue";

export default {
  created() {
    this.itemtitle = this.$route.query.t;
  },
  computed: {
    canAddnewAttender() {
      return this.rateallSubjects.length > 0;
    },
  },
  data() {
    return {
      itemtitle: '',
      required,
      alphaNum,
      loading: false,
      currentId: 0,
      rateHigherPositions: false,
      rateLowerPositions: true,
      rateSamePositions: true,
      searchOptions: null,
      surveyitemid: null,
      searchType: null,
      groupSubject: null,
      branchSubject: null,
      departmentSubject: null,
      positionSubject: null,
      rateallSubjects: [],
    };
  },
  methods: {
    withHtml(attendee) {

      //console.log(attendee);
      var url = "https://sp.niso.dev/?c=" + attendee.clientguid + '&a=' + attendee.invitecode;

      this.$swal({
        title: '<span class="font-weight-bolder">Survey URL </span>',
        icon: 'info',
        html:
          'You can access survey from url '
          + '<a href="' + url + '">Survey link</a> '
          + ' or ' + url,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Great!',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
    },
    newRecordCreated(coursemode) {

      this.currentId++;
    },
    selectionChanged(payload) {
      //console.log(payload);
      //   this.localOptions.rateSubject=payload;
      if (payload == null) {
        this.rateallSubjects = [];
      } else {
        this.rateallSubjects = [];
        this.rateallSubjects.push(payload);
      }
    },

    addSelectedMemberToSurvey() {
      //console.log("Attendee added" );
      this.loading = true;

      this.surveyitemid = router.currentRoute.query.s;

      store
        .dispatch("app-surveys/addAttendeeToSubject", this.rateallSubjects[0])
        .then((response) => {


          var result = response.data.success;
          if (result != null && result != undefined && result == 100) {



            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
            this.rateallSubjects = [];
            this.loading = false;
            this.newRecordCreated();

            this.refetchData();

          } else {
            //console.log(response);

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                text: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          }


        });
    },

    getRoute(coursemode) {
      return "page-coursemode";
    },
    deleteExam(coursedata) {
      //console.log(coursedata);

      store
        .dispatch("app-surveys/deleteAttende", coursedata)
        .then((response) => {
          var result = response.data.success;

          coursedata.deleted = true;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        });
    },

    publishCourse(coursedata) {
      return;
      //console.log(coursedata);
      store
        .dispatch("app-surveys/publishCourse", {
          id: coursedata.courseguid,
        })
        .then((response) => {
          var result = response.data.success;
          if (result != null && result != undefined && result == 100) {
            coursedata.courseStatus = 100;

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        });
    },
    draftCourse(coursedata) {
      return;
      store
        .dispatch("app-surveys/draftCourse", {
          id: coursedata.courseguid,
        })
        .then((response) => {
          var result = response.data.success;
          if (result != null && result != undefined && result == 100) {
            coursedata.courseStatus = 0;

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error updating course status",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
  },

  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    MemberPicker,
    vSelect,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-surveys";
    //console.log("surveys");

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const getPageroute = (surveyid) => {
      var result = { name: "survey-questions", params: { id: surveyid } };

      return result;
    };
    const getStatus = (status) => {
      // DRAFT
      if (status == "0") return "secondary";
      // published
      if (status == "100") return "success";

      // ended by time limit
      if (status == "200") return "warning";
      // stopped
      if (status == "500") return "warning";

      return "secondary";
    };
    const getStatusText = (status) => {
      // DRAFT
      if (status == "0") return "Draft";
      // published
      if (status == "100") return "Published";

      // ended by time limit
      if (status == "200") return "Ended";
      // stopped
      if (status == "500") return "Stopped";

      return "Draft";
    };

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    const {
      surveyInfo,
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      resolveSurveyStatusVariant,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
      resolveSurveyStatus,
    } = useUsersList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      surveyInfo,
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      resolveSurveyStatus,
      resolveSurveyStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
      getPageroute,
      getStatus,
      getStatusText,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
