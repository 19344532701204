<template>
  <b-row>

    <!-- Small -->


    <!-- Large -->
    <b-col cols="12">
      <b-form-group>
        <v-select v-if="categories != null" v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title" :options="categories" class="select-size-lg" single>
          <template #option="{ title, description }">


            <span> {{ title }}</span><br />
            <small class="text-muted"> {{ $t(description) }} </small>
          </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import axios from '@axios'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },

  props: {
    initialCategories: []
  },


  methods: {
    updateSelections(a) {

      //console.log(a)
    },
    fetchCategories() {
      //console.log("  course modes fetch");
      return new Promise((resolve, reject) => {
        axios
          .get('/lms/admin/course/examquestiontypes')
          .then((response) => {


            var data = response.data;
            var responseData = JSON.parse(JSON.stringify(data));
            //console.log(responseData.data);
            this.categories = responseData.data;
            if (this.initialCategories == null || this.initialCategories.length == 0) {
              this.selectedinitialCategories = this.categories[0];
            } else {
              if (typeof this.initialCategories === 'string' || this.initialCategories instanceof String) {
                var result = this.categories.find(obj => {
                  return obj.typekey === this.initialCategories
                });

                this.selected = result;

              }
            }




          }
          )
          .catch(error => reject(error))
      })
    },
  },
  watch: {
    selected: function (newVal, coldVal) {

      this.$emit("selectionChanged", newVal);

    },
    initialCategories: function (newVal, coldVal) {


      if (typeof this.initialCategories === 'string' || this.initialCategories instanceof String) {
        var result = this.categories.find(obj => {
          return obj.typekey === this.initialCategories
        });

        this.selected = result;

      } else {
        this.selected = newVal;
      }




    },


  },
  created() {
    this.fetchCategories();
    this.selectedinitialCategories = this.initialCategories

  },
  data() {
    return {
      categories: null,
      selectedinitialCategories: null,
      selected: this.initialCategories,
      option: [{ title: 'Square' }, { title: 'Rectangle' }, { title: 'Rombo' }, { title: 'Romboid' }],
    }
  }


}
</script>