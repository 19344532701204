<template>

  <div>

    <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
      :plan-options="planOptions" :surveyInfo="surveyData" :key="currentproccess" :questionCount="totalQuestions"
      :activeQuestion="selectedQuestion" @refetch-data="refetchData" />



    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row v-if="surveyData">

          <!-- Per Page -->

          <b-col cols="12" md="12" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <h2> Survey: {{ surveyData.title }}</h2>
          </b-col>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Show') }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search...')" />
              <b-button variant="primary" @click="newQuestion"
                v-if="surveyInfo != null && surveyInfo != undefined && surveyInfo.status == 0">
                <span class="text-nowrap">Create Question</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refUserListTable" class="position-relative" :items="fetchSurveyQuestions" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc">

                    <template #head()="data">
              <span class="text">{{ $t(data.label.toUpperCase()) }}</span>
            </template>
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.avatar" :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }" />
            </template>
            <b-link :to="{ name: 'SurveyEdit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap">
              {{ data.item.title }}
            </b-link>
            <small class="text-muted">{{ data.item.courseCode }}</small>
          </b-media>
        </template>
        <template #cell(description)="data">
          <p class="card-text" v-html="data.item.description" />
        </template>

        <template #cell(surveyStatus)="data">





          <b-badge pill :variant="`${resolveSurveyStatusVariant(data.item.surveyStatus)}`" class="text-capitalize">
            {{ resolveSurveyStatus(data.item.surveyStatus) }}

          </b-badge>
        </template>


        <template #cell(attendees)="data">


          <b-link :to="{ name: 'partipiciants-bycourse-page', params: { id: data.item.courseguid } }"
            class="font-weight-bold d-block text-nowrap">
            <feather-icon :icon="resolveUserRoleIcon(data.item.role)" size="18" class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`" /> Attendees
          </b-link>

        </template>

        <template #cell(id)="data">

          <b-link :to="getPageroute(data.item.coursemode, data.item.courseguid)"
            class="font-weight-bold d-block text-nowrap">

            Questions
          </b-link>

        </template>
        <template #cell(questionAnswers)="data">



          <div v-if="data.item.questionTypeKey === 'RANGE_SELECTION'">

            <div v-if="data.item.additionalInfo != null">


              {{ JSON.parse(data.item.additionalInfo).min1Value }} - {{ JSON.parse(data.item.additionalInfo).min1Text }}
              <br />
              {{ JSON.parse(data.item.additionalInfo).min2Value }} - {{ JSON.parse(data.item.additionalInfo).min2Text }}
              <br />
              {{ JSON.parse(data.item.additionalInfo).min3Value }} - {{ JSON.parse(data.item.additionalInfo).min3Text }}
              <br />
              {{ JSON.parse(data.item.additionalInfo).min4Value }} - {{ JSON.parse(data.item.additionalInfo).min4Text }}
              <br />
              {{ JSON.parse(data.item.additionalInfo).min5Value }} - {{ JSON.parse(data.item.additionalInfo).min5Text }}
              <br />
            </div>

          </div>


          <b-list-group v-if="data.item.questionTypeKey === 'SINGLE_SELECTION'">


            <b-list-group-item v-for="(item, index) in JSON.parse(data.item.questionAnswers)" :key="index"
              :variant="item.value == data.item.acceptedAnswer ? 'success' : 'light'">
              {{ item.answer }}


            </b-list-group-item>
          </b-list-group>

          <b-list-group v-if="data.item.questionTypeKey == 'MULTIPLE_SELECTION'">
            <b-list-group-item v-for="(item, index) in JSON.parse(data.item.questionAnswers)" :key="index"
              :variant="item.selected === 'true' || item.selected === true ? 'success' : 'light'">
              {{ item.answer }}


            </b-list-group-item>
          </b-list-group>

          <b-list-group v-if="data.item.questionTypeKey === 'YES_NO_QUESTION'">
            <b-list-group-item v-for="(item, index) in JSON.parse(data.item.questionAnswers)" :key="index"
              :variant="item.value === data.item.acceptedAnswer ? 'success' : 'light'">
              {{ item.answer }}


            </b-list-group-item>
          </b-list-group>

        </template>
        <!-- Column: Status -->
        <template #cell(status)="data" v-if="surveyInfo != null && surveyInfo != undefined && surveyInfo.status == 0">



          <b-button v-if="7 > 6" variant="primary" @click="editQuestion(data.item)">
            <span class="text-nowrap">Edit Question</span>
          </b-button>



          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger" v-if="!(data.item.deleted)"
            @click="deleteQuestion(data.item)">

            <feather-icon icon="Trash2Icon" />
            <span class="align-middle ml-50">{{ $t("Delete") }}</span>
          </b-button>
          <b-badge variant="danger" v-if="data.item.deleted">
            Deleted
          </b-badge>

        </template>




      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{
              $t('of') }}
              {{ dataMeta.of }}
              {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="currentPage" :total-rows="totalQuestions" :per-page="perPage" first-number
              last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BListGroup, BListGroupItem
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './SurveyQuestionListFilters.vue'
import useUsersList from './useSurveyQuestionList'
import userStoreModule from '../surveyquestionStoreModule'
import UserListAddNew from './SurveyQuestionListAddNew.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {

  computed: {

  },
  data() {
    return {
      selectedQuestion: null,

    }
  },
  watch: {
    // whenever question changes, this function will run
    surveyInfo(newQuestion, oldQuestion) {
      //console.log( "survey info re-fetched : " );
      currentproccess++;

    }
  },
  methods: {
    generateUUID() { // Public Domain/MIT
      var d = new Date().getTime();//Timestamp
      var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      })
    },
    newQuestion() {
      this.isAddNewUserSidebarActive = true;
      this.selectedQuestion = null;
      this.selectedQuestion = {
        title: '',
        required: true,
        examid: '',
        description: '',
        questionType: '',
        questionTypeKey: '',
        allowComment: false,
        acceptedAnswers: [],
        acceptedAnswer: null,
        questionAnswers: [{
          id: 1,
          selected: false,
          value: this.generateUUID(),
          answer: null

        }],
        additionalInfo: {

          min1Value: 1,
          min2Value: 2,
          min3Value: 3,
          min4Value: 4,
          min5Value: 5,

          min1Text: "Poor",
          min2Text: "Needs to Improve",
          min3Text: "Average ",
          min4Text: "Good",
          min5Text: "Excellent",

          maxValue: 5,
          minText: "Poor",
          maxText: "Excellent"
        }
      };


    },
    editQuestion(question) {
      //console.log(question);


      this.isAddNewUserSidebarActive = true;
      this.selectedQuestion = null;

      this.selectedQuestion = JSON.parse(JSON.stringify(question));
      this.selectedQuestion.questionAnswers = JSON.parse(question.questionAnswers);
      this.selectedQuestion.additionalInfo = JSON.parse(question.additionalInfo);
      //console.log("selected question",this.selectedQuestion);
      // this.currentproccess++;
    },
    getRoute(coursemode) {

      return "page-coursemode";
    }
    ,
    deleteQuestion(coursedata) {
      //console.log(coursedata);
      coursedata.deleted = true;
      store
        .dispatch('app-survey-questions/updatequestion', coursedata)
        .then(response => {

          var result = response.data.success;
          if (result != null && result != undefined && result == 100) {
            coursedata.deleted = true;

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;

            //  fetchSurveyQuestions();


          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          }


        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
    },
    getRoute(coursemode) {

      return "page-coursemode";
    }
    ,
    deleteQuestion(coursedata) {
      //console.log(coursedata);
      coursedata.deleted = true;
      store
        .dispatch('app-survey-questions/updatequestion', coursedata)
        .then(response => {

          var result = response.data.success;
          if (result != null && result != undefined && result == 100) {
            coursedata.deleted = true;

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;

            //  fetchSurveyQuestions();


          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          }


        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
    },

    publishCourse(coursedata) {
      //console.log(coursedata);
      store
        .dispatch('app-surveys/publishCourse', {
          id: coursedata.courseguid,

        })
        .then(response => {

          var result = response.data.success;
          if (result != null && result != undefined && result == 100) {
            coursedata.courseStatus = 100;

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;

          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          }


        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
    },
    draftCourse(coursedata) {

      store
        .dispatch('app-surveys/draftCourse', {
          id: coursedata.courseguid,
        })
        .then(response => {

          var result = response.data.success;
          if (result != null && result != undefined && result == 100) {
            coursedata.courseStatus = 0;

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;

          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          }


        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating course status',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },


  },

  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BListGroup, BListGroupItem,

    vSelect,
    ToastificationContent
  }, directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-survey-questions'
    //console.log("survey questions");
    const surveyData = ref(null)
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })




    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const getPageroute = (coursemode, courseguid) => {
      //console.log(coursemode);
      var routename = "";
      if (coursemode === 'IN-PERSON') routename = 'course-sessions'
      else if (coursemode === 'ONLINE') routename = 'course-lectures'
      else if (coursemode === 'HYBRID') routename = 'course-lectures'
      else routename = 'course-lectures';

      var result = { name: routename, params: { id: courseguid } };

      return result;


    }
    const getStatus = status => {



      // DRAFT 
      if (status == '0') return 'secondary'
      // published
      if (status == '100') return 'success'

      // ended by time limit
      if (status == '200') return 'warning'
      // stopped 
      if (status == '500') return 'warning'

      return 'secondary';


    }
    const getStatusText = status => {



      // DRAFT 
      if (status == '0') return 'Draft'
      // published
      if (status == '100') return 'Published'

      // ended by time limit
      if (status == '200') return 'Ended'
      // stopped 
      if (status == '500') return 'Stopped'

      return 'Draft';


    }


    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    var surveyId = router.currentRoute.query.s;

    if (surveyId) {
      store.commit('app/SETACTIVEUNIT', surveyId)
    } else {
      surveyId = store.getters['app/currentUnit'];


    }

    store.dispatch('app-survey-questions/fetchCourse', { id: surveyId })
      .then(response => {
        //    var course=JSON.parse(JSON.stringify(response.data));
        //  const { course  } = response.data
        surveyData.value = response.data.data;
        //console.log("received survey information");

        currentproccess.value = currentproccess.value + 1;



      })
      .catch(error => {
        if (error.response.status === 404) {
          surveyData.value = undefined
        }
      })





    const {
      currentproccess,
      surveyInfo,
      fetchSurveyQuestions,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      resolveSurveyStatusVariant,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
      resolveSurveyStatus, totalQuestions
    } = useUsersList(surveyId)

    return {
      currentproccess,
      // Sidebar
      isAddNewUserSidebarActive,
      surveyInfo,
      fetchSurveyQuestions,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      resolveSurveyStatus,
      resolveSurveyStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
      totalQuestions,
      getPageroute,
      getStatus,
      getStatusText,
      surveyData
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
