<template>
  <b-row>
    <b-col cols="12">
      <b-form-group>
        <v-select v-if="categories != null" v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title" :options="categories" class="select-size-lg" single>
          <template #option="{ title, description }">
            <span> {{ title }}</span><br />
            <small class="text-muted"> {{ description }} </small>
          </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import axios from '@axios'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },

  methods: {
    fetchCategories() {
      return new Promise((resolve, reject) => {
        axios
          .get('/lms/admin/course/getlessontypes')
          .then((response) => {
            var data = response.data;
            var responseData = JSON.parse(JSON.stringify(data));
            this.categories = responseData.data;
          }
          )
          .catch(error => reject(error))
      })
    },
    resetSelected(){
      this.selected = null;
    }
  },
  watch: {
    selected: function (newVal, coldVal) {
      this.$emit("selectionChanged", newVal);
    }
  },
  created() {
    this.fetchCategories();
  },
  data() {
    return {
      categories: null,
      selected: null,
    }
  }
}
</script>